// import '/resources/sass/darkly.scss'
import '/resources/sass/flatly.scss'

import '/resources/core/chosen.css'
import '/resources/core/component-chosen.min.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

import '/resources/core/chosen-sprite.png'
import '/resources/core/chosen-sprite@2x.png'

import 'datatables.net-bs5'
import 'datatables.net-buttons-bs5'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.colVis.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-fixedheader-bs5'
import 'datatables.net-responsive-bs5';
import 'jszip'
import 'pdfmake'

import confetti from 'canvas-confetti';

import Cookies from 'js-cookie'

import Highcharts from 'highcharts';

import 'bootstrap'
import bootstrap, { Tab, Tooltip } from 'bootstrap'
import '/resources/core/chosen.jquery.js'

/* Import TinyMCE */
import tinymce from 'tinymce'

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default'

/* Required TinyMCE components */
import 'tinymce/themes/silver'
import 'tinymce/models/dom'

/* Import a skin (can be a custom skin instead of the default) */
import 'tinymce/skins/ui/oxide/skin.css'

/* Import tinymce plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'

// sortable js
import Sortable from 'sortablejs'

import { Offcanvas } from 'bootstrap';

import { createElement, createFragment, displayNotification, fetchPost, setCollapse, post, print_this, htmlEsc, setTimeoutPromise, confirmDialog, getTableRowData, getTemplates, getForm, setForm, inputNum, scrollTop, scrollBottom, toTab, formatter, toPill, waitForElement, formatNumber, showLoadingScreen, hideLoadingScreen, toggleLoading, showConfetti, buttonIsLoading, toTabNew, getFormByAgeId, highlightInput, collapseClear, validateEmail } from './util'
import { MFA_METHOD_EMAIL, MFA_METHOD_SMS, MFA_METHOD_APP, UPLOAD_IMG, REQ_ADD_USER, REQ_DELETE_USER, DOC_VISIBLE, DOC_HIDDEN, TABLE_DOWNLOAD, FILING_LOCKED, ZERO_LIVES, PROG_CHARI, PROG_RIVAP, USER_INACTIVE, USER_ACTIVE, PAYER_ACTIVE, PAYER_INACTIVE, FILE_ACTIVE, FILE_INACTIVE, ADJUST_CREDIT, REQUEST_REPORTING, REQUEST_FILING, CAL_FILE_AGENDA, CAL_FILE_MINUTES, CAL_FILE_MATERIAL, NOTIF_MODE_PREVIEW, NOTIF_MODE_SEND, PROG_MVB, PROG_WAPAL, USERQ_ESCALATE, USERQ_ACTIVE, USERQ_INACTIVE, GROUP_KVUSERS, GROUP_NOTIFICATIONS, GROUP_PAYERS, MAIL_SEND, ZERO_ACTIVE, ZERO_INACTIVE, FILING_PENDING_UPLOAD, FILING_UPLOADED, QB_CONNECT_ACTIVE, TRIGGER_ERROR, SYNCT_AUTO, SYNCT_MANUAL, SYNC_COMPLETE, UPLOAD_SUCCESS, QB_AUTH_SUCCESS, QB_AUTH_INITIAL_SUCCESS, PayerPreview, SYNC_RATE, SYNC_PAYER, STAFF_INACTIVE, MEMBER_INACTIVE, QUEST_INACTIVE, QCONT_INACTIVE, CAL_INACTIVE, PROV_INACTIVE, HOME_INACTIVE, DOC_INACTIVE, MONTH_JAN, RATE_MULTIYEAR, ADJUST_DEBIT } from './constants'
import { Collapse } from 'bootstrap'
import { Formatter } from 'tinymce'

import LeaderLine from 'leader-line';
import { jsPlumb } from 'jsplumb';
import { createConcurrentSafePromise, resolveSearchParameters } from 'instantsearch.js/es/lib/utils'

const datatablesButtonDom = `
	<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-8 d-flex justify-content-end'f<'ms-2'B>>>
	<'row'<'col-sm-12'tr>>
	<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>
`
const tinyMceFonts = 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Poppins=Poppins, sans-serif;'

const tinyMcePlugins = 'link lists code'

const tinyMceDefaultFont = "@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light%7CPlayfair+Display:400&display=swap'); body { font-family: 'Poppins', sans-serif;}"

const tinyMceTools = 'undo redo | blocks | bold italic | alignleft aligncentre alignright alignjustify | indent outdent | bullist numlist | link | fontfamily | fontsize | code '

const slideSpeed = 350

declare var AnimEvent: any

declare type loadingParams = {
	loading: $,
	content: $,
}

const initReporting = () => {

	type request = {
		req_id: number,
		req_date: number,
		req_role: number,
		req_type: number,
		req_programid: number,
		req_payerid: number,
		req_datepretty: string,
		req_typepretty: string,
		req_rolepretty: string,
		req_email_sender: string,
		req_email_recipient: string,
		req_reason: string,
		payer_name: string,
		prog_name: string
		prog_name_long: string
	}

	const initRequestsDt = () => {
		const columns = [
			{
				data: 'req_id',
				visible: false,
			},
			{
				data: 'req_email_recipient',
				header: 'Requested User',
			},
			{
				data: 'req_rolepretty',
				header: 'Role',
			},
			{
				data: 'req_typepretty',
				header: 'Type',
			},
			{
				data: 'req_datepretty',
				header: 'Date Submitted',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-view btn btn-primary btn-sm">View</button>',
			},
		]

		const headers = columns.map((col) => col.header)
		const requestsTable = $('#requestsTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/requests',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
				}),
			},
			scrollX: true,
			columns: columns,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No invitations to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> invitations",
				infoEmpty: "Showing 0 to 0 of 0 invitation",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total invitations)",
				lengthMenu: "Show _MENU_ invitations",
			},
			initComplete: () => {
				const $scroll = $('#requestsTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#requestsTableContainer').find('th').addClass('custom-header')

		$('#requestsTableContainer').off('click').on('click', '.show-view', ({ target }) => {
			let row = requestsTable.row($(event.target).closest('tr')).data() as request
			requestView(row)
		})

		return requestsTable
	}

	const requestDt = initRequestsDt()

	const requestClose = () => {
		$('#request_show').collapse('hide')
	}

	const requestView = async (request: request) => {
		if (!request) {
			displayNotification('No Request', 'danger')
			return
		}

		const { payers, programs } = await fetchPost('/data/submit', { type: 'request_auth', data: request.req_id })

		$('#request_show').collapse('show')
		$('#request_show').show()
		$('#request_date').html(htmlEsc(request.req_datepretty))
		$('#request_program').html(programs)
		$('#request_payer').html(htmlEsc(payers))
		$('#request_sender_email').html(htmlEsc(request.req_email_sender))
		$('#request_requested_email').html(htmlEsc(request.req_email_recipient))
		$('#request_type').html(htmlEsc(request.req_typepretty))
		$('#request_role').html(htmlEsc(request.req_rolepretty))
		$('#request_reason').html(htmlEsc(request.req_reason))

		$('#requestAccept').off('click').on('click', async () => {
			try {
				const postData = {
					id: request.req_id,
					email: request.req_email_recipient,
					role: request.req_role,
					type: request.req_type
				}
				const res = await fetchPost('/form/submit', { type: 'request_accept', data: postData })
				if (res.rc != 'OK') throw res.rc

				displayNotification('User Request Successfully Submitted', 'success')
				requestClose()
				requestDt.ajax.reload()
			} catch (err) {
				switch (err) {
					case 'ROLE_TAKEN':
						displayNotification('This User Already Exists Within This Role', 'danger')
						console.error('mfa_form_resend', err)
						break
					default:
						displayNotification('There Was a Problem Accepting This Request', 'danger')
						console.error('mfa_form_resend', err)
				}
			}
		})

		$('#request_deny_send').off('click').on('click', async () => {
			const postData = {
				id: request.req_id,
				sender: request.req_email_sender,
				recipient: request.req_email_recipient,
				reason: $('#request_deny_reason').val()
			}
			try {
				const res = await fetchPost('/form/submit', { type: 'request_deny', data: postData })
				if (res.rc != 'OK') {
					displayNotification('Error sending request', 'danger')
					return
				}
				$('#request_deny_modal').modal('hide')
				displayNotification('Successfully denied request', 'success')
				requestClose()
				requestDt.ajax.reload()
			} catch (err) {
				console.error('request_deny_send', err)
			}
		})
	}

	$('#request_cancel').off('click').on('click', () => {
		requestClose()
	})

	const getPeopleFilters = () => ({
		program: $('#peopleFilterProg').val(),
		payer: $('#peopleFilterPayer').val(),
		status: $('#peopleFilterStatus').val(),
		zero: $('#peopleFilterZero').val()
	})

	setCollapse({ $btn: $('#peopleFiltersCollapse'), $target: $('#peopleFilters') })

	const initPeopleDt = () => {
		const columnsInfo = [
			{
				data: 'payer_fein',
				header: 'Fein',
			},
			{
				data: 'payer_name',
				header: 'Payer',
			},
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last Name',
			},
			{
				data: 'role_pretty',
				header: 'Role',
			},
			{
				data: 'user_position',
				header: 'Position',
			},
			{
				data: 'user_phone',
				header: 'Phone',
			},
			{
				data: 'user_statuspretty',
				header: 'Status',
			},
			{
				header: 'Action',
				data: '',
				fieldId: 'userFieldActions',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-envelope"></i> Mail</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const peopleDt = $('#peopleTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/people',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getPeopleFilters()
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			buttons: [
				{
					text: '<span class="fas fa-plus mr-1"></span> Add',
					className: 'add ms-2 btn btn-sm btn-success',
					action: () => editPeople(),
				},
				{
					text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
					className: ' ms-1 download btn btn-sm btn-primary',
					action: () => downloadPeople(),
				},
			],
			dom: datatablesButtonDom,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No people to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> people",
				infoEmpty: "Showing 0 to 0 of 0 people",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total people)",
				lengthMenu: "Show _MENU_ people",
			},
			initComplete: () => {
				const $scroll = $('#peopleTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#peopleTableContainer').find('th').addClass('custom-header')

		$('#peopleTable').off("click").on('click', '.show-edit', ({ target }) => {
			const user = getTableRowData(peopleDt, target) as any
			window.location.href = `mailto:${user.user_email}`;
		})

		const downloadPeople = async () => {
			const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
			post('/tables/people', { filters: JSON.stringify(getPeopleFilters()), mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields) })
		}

		return peopleDt
	}
	let peopleDt = initPeopleDt()

	const reloadPeopleDt = () => peopleDt.ajax.reload(undefined, false)

	$('#peopleTable').on('click', 'tbody tr', ({ target }) => {
		const people = getTableRowData(peopleDt, target)
		editPeople(people)
	})

	//Reload table when filters change
	$('#peopleFilters').on('change', () => reloadPeopleDt())

	const peopleForm = [
		{
			$: $('#peopleEditFirst'),
			source: 'user_first',
		},
		{
			$: $('#peopleEditLast'),
			source: 'user_last',
		},
		{
			$: $('#peopleEditEmail'),
			source: 'user_email',
		},
		{
			$: $('#peopleEditPhone'),
			source: 'user_phone',
		},
		{
			$: $('#peopleEditPosition'),
			source: 'user_position',
		},
		{
			$: $('#peopleEditAddress1'),
			source: 'user_address1',
		},
		{
			$: $('#peopleEditAddress2'),
			source: 'user_address2',
		},
		{
			$: $('#peopleEditCity'),
			source: 'user_city',
		},
		{
			$: $('#peopleEditState'),
			source: 'user_state',
		},
		{
			$: $('#peopleEditZip'),
			source: 'user_zip',
		},
		{
			$: $('#peopleEditNaic'),
			source: 'payer_naic',
		},
		{
			$: $('#peopleEditGroup'),
			source: 'payer_group',
		},
	]

	const cancelAuthChange = () => {
		$('#peopleEditAuthContainer').fadeIn(200)
		$('#peopleEditChangeAuth').show()
		$('#peopleEditAddAuth').hide()
		$('#peopleEditCancelChange').hide()
		$('#peopleEditRole').val('6')
		$('#peopleEditProgram').val('').trigger('chosen:updated')
		$('#peopleEditPayers').val('').trigger('chosen:updated')
	}

	const resetPeople = () => {
		$('#peopleEditForm').trigger('reset')
		$('#peopleEditSave').off('click')
		$('#peopleEditToggle').off('click')
		$('#peopleEditRole').val('6')
		$('#peopleEditProgram').val('').trigger('chosen:updated')
		$('#peopleEditPayers').val('').trigger('chosen:updated')
		$('#peopleEditState').val('').trigger('chosen:updated')
		$('#peopleEditCancelChange').hide()
		$('#peopleEditChangeAuth').show()
	}

	const closePeople = () => $('#peopleEdit').slideUp(slideSpeed, () => resetPeople())

	const editPeople = async (people: any = null) => {
		console.log('people', people)
		$('#peopleEdit').slideDown(slideSpeed)
		$('#peopleEditAuthContainer').hide()
		$('#peopleEditAddAuth').show()
		$('#peopleEditToggle').hide()

		resetPeople()
		scrollTop()

		if (!people) {
			$('#peopleEditRegisteredContainer').hide()
		}

		if (people) {
			$('#peopleEditToggle').show()
			$('#peopleEditRegisteredContainer').show()

			if (people.user_status == USER_ACTIVE) $('#peopleEditToggle').text('archive')
			if (people.user_status == USER_INACTIVE) $('#peopleEditToggle').text('reactivate')

			//check if user is registered
			if (people.user_password == null) {
				$('#peopleEditRegisteredContainer').html(`<div class="alert alert-danger alert-dismissible fade show" role="alert">
				<i class="fa-solid fa-ban"></i>
				<strong>Not Registered - Password needs to be reset to login.</strong> 
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>`)
			} else {
				$('#peopleEditRegisteredContainer').html(`<div class="alert alert-success alert-dismissible fade show" role="alert">
				<i class="fa-solid fa-circle-check"></i>
				<strong>Registered User!</strong> 
				<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>`)
			}

			const { role, programs, payers } = await fetchPost('/reporting/submit', { type: 'get_auth', data: people.user_id })
			setForm(peopleForm, people)

			$('#peopleEditAddAuth').hide()
			$('#peopleEditAuthContainer').show()
			$('#peopleAuthProgram').html(programs)
			$('#peopleAuthPayer').html(payers)
			$('#peopleAuthRole').html(role)
			$('#peopleAuthStatus').html(people.user_statuspretty)
		}

		$('#peopleEditClose').on('click', () => closePeople())

		$('#peopleEditToggle').on('click', async () => {
			const data = {
				user_id: people.user_id,
				status: people.user_status,
			}
			const { rc } = await fetchPost('/reporting/submit', { type: 'toggle_person', data })
			if (rc != 'OK') {
				displayNotification('could not toggle user status', 'danger')
				return;
			}
			displayNotification('success toggling status', 'success')
			peopleDt.ajax.reload(undefined, false)
			closePeople()
		})

		$('#peopleEditChangeAuth').off('click').on('click', async (e) => {
			e.preventDefault()
			$('#peopleEditCancelChange').show()
			$('#peopleEditAddAuth').fadeIn(200)
			$('#peopleEditAuthContainer').hide()
			$('#peopleEditChangeAuth').hide()

			try {
				const res = await fetchPost('/reporting/submit', { type: 'get_people_auth', data: people.user_id })
				if (res.rc != 'OK') throw res.rc

				$('#peopleEditRole').val(res.role)
				$('#peopleEditProgram').val(res.programs).trigger('chosen:updated')
				$('#peopleEditPayers').val(res.payers).trigger('chosen:updated')

			} catch (e) {
				displayNotification('error. could not get user auth', 'danger')
				console.error(e)
			}
		})

		$('#peopleEditCancelChange').off('click').on('click', (e) => {
			e.preventDefault()
			cancelAuthChange()
		})

		$('#peopleEditSave').on('click', async () => {
			if (($("#peopleEditForm")[0] as HTMLFormElement).checkValidity() === false) {
				($("#peopleEditForm")[0] as HTMLFormElement).reportValidity();
				return;
			}

			try {
				$('#peopleEditSave').prop('disabled', true)
				const data = {
					user_id: people ? people.user_id : null,
					people: getForm(peopleForm),
					auth: { payers: $('#peopleEditPayers').val(), programs: $('#peopleEditProgram').val(), role: $('#peopleEditRole').val() }
				}
				const { rc, new_user } = await fetchPost('/reporting/submit', { type: 'save_person', data })
				if (rc != 'OK') throw rc

				displayNotification('Saved User Information')
				peopleDt.ajax.reload(undefined, false)
				closePeople()

				if (new_user) setTimeout(() => inviteUser(new_user), 1000)
			}
			catch (error) {
				if (error == 'USER_EXISTS') return displayNotification('User already exists', 'danger')
				displayNotification('Could not save user information', 'danger')
			} finally {
				$('#peopleEditSave').prop('disabled', false)
			}
		})
	}

	const inviteUser = async (user) => {
		if (!user) return
		$('#peopleInviteModal').modal('show')

		$('#peopleEditInvite').off('click').on('click', async () => {
			try {
				$('#peopleEditInvite').prop('disabled', true)
				const { rc } = await fetchPost('/reporting/submit', { type: 'invite_person', data: user })
				if (rc != 'OK') {
					displayNotification('could not save user information', 'danger')
					return
				}
				$('#peopleInviteModal').modal('hide')
				setTimeout(() => displayNotification('Successfuly sent invite.', 'success'), 500);
				return
			} catch (error) {
				if (error == 'NO_USER') return displayNotification('User not found in system.', 'danger')
				displayNotification('Failed sending invite.', 'danger')
			} finally {
				$('#peopleEditInvite').prop('disabled', false)
			}
		})
	}

	const initpayers = () => {
		const getpayerFields = (): { [col: string]: 1 | 0 } => {
			const fields = {}
			columnsInfo.forEach(({ data, fieldId }) => {
				if (fieldId) fields[data] = $('#' + fieldId).is(':checked') ? 1 : 0
			})
			return fields
		}

		const getpayersFilters = () => ({
			program: $('#payerFilterProgram').val(),
			status: $('#payerFilterStatus').val(),
			state: $('#payerFilterState').val(),
		})

		const payerFieldsTypeToggle = ({ type, checked }) => {
			const columns = columnsInfo.filter((col) => col.tableType === type)
			const ids = columns.map((col) => '#' + col.fieldId).join(',')
			$(ids).prop('checked', checked)
		}

		$('#payerFieldpayer').on('change', () => {
			payerFieldsTypeToggle({ type: 'payer', checked: $('#payerFieldpayer').is(':checked') })
		})

		setCollapse({ $btn: $('#payersFiltersCollapse'), $target: $('#payersFilters') })
		setCollapse({ $btn: $('#payersFieldsCollapse'), $target: $('#payersFields') })
		setCollapse({ $btn: $('#payerPeopleCollapse'), $target: $('#payerPeopleContainer') })

		//Init table
		const columnsInfo = [
			{
				data: 'payer_fein',
				header: 'FEIN',
				fieldId: 'payerFieldFein',
				tableType: 'payer',
			},
			{
				data: 'payer_name',
				header: 'Name',
			},
			{
				data: 'status_pretty',
				header: 'Status',
				fieldId: 'payerFieldStatus',
				tableType: 'payer',
			},
			{
				data: 'payer_moddate_pretty',
				header: 'Modified',
				fieldId: 'payerFieldModified',
				tableType: 'payer',
			},
			{
				data: 'payer_address1',
				header: 'Address 1',
				fieldId: 'payerFieldAddress1',
				tableType: 'payer',
			},
			{
				data: 'payer_address2',
				header: 'Address 2',
				fieldId: 'payerFieldAddress2',
				tableType: 'payer',
			},
			{
				data: 'payer_city',
				header: 'City',
				fieldId: 'payerFieldCity',
				tableType: 'payer',
			},
			{
				data: 'payer_state',
				header: 'State',
				fieldId: 'payerFieldState',
				tableType: 'payer',
			},
			{
				data: 'payer_zip',
				header: 'Zip',
				fieldId: 'payerFieldZip',
				tableType: 'payer',
			},
			{
				data: 'payer_naic',
				header: 'NAIC',
				fieldId: 'payerFieldNaic',
				tableType: 'payer',
			},
			{
				data: 'payer_group',
				header: 'Group',
				fieldId: 'payerFieldGroup',
				tableType: 'payer',
			},
		]
		const columnIndexes: { [index: string]: number } = columnsInfo.reduce((obj, col, index) => ({ ...obj, [col.data]: index }), {})
		const headers = columnsInfo.map((col) => col.header)
		const payersDt = $('#payersTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/clients',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData: DataTables.AjaxDataRequest) => ({
					...postData,
					filters: getpayersFilters(),
					fields: getpayerFields(),
					orderCols: postData.order.map(({ column }) => columnsInfo[column].data),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[columnIndexes.payer_name, 'ASC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			buttons: [
				{
					text: '<span class="fas fa-plus mr-1"></span> Add',
					className: 'add ms-2 btn btn-sm btn-success',
					action: () => editpayer(),
				},
				{
					text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
					className: ' ms-1 download btn btn-sm btn-primary',
					action: () => downloadPayers(),
				},
			],
			dom: `
				<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-8 d-flex justify-content-end'fB>>
				<'row'<'col-sm-12'tr>>
				<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>
			`,
			language: {
				emptyTable: "No payers to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> payers",
				infoEmpty: "Showing 0 to 0 of 0 payers",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total payers)",
				lengthMenu: "Show _MENU_ payers",
			},
			initComplete: () => {
				const $scroll = $('#payersTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		const reloadpayersDt = () => payersDt.ajax.reload(undefined, false)

		//Show/hide fields every draw
		payersDt.on('draw', () => {
			const fields = (payersDt.ajax.params() as any).fields as ReturnType<typeof getpayerFields>
			const columns = payersDt.columns() as any
			let fieldsIndicies: number[] = []
			let hiddenIndicies: number[] = []
			columns.eq(0).each((index: number) => {
				const col = payersDt.column(index)
				const dataSrc = col.dataSrc()
				if (typeof dataSrc !== 'string') return
				if (fields.hasOwnProperty(dataSrc)) fieldsIndicies.push(index)
				if (fields[dataSrc] === 0) hiddenIndicies.push(index)
			})

			payersDt.columns(fieldsIndicies).visible(true)
			if (hiddenIndicies.length) payersDt.columns(hiddenIndicies).visible(false)

			payersDt.columns.adjust()

			$(window).trigger('resize.doubleScroll')
		})

		$('#payersTableContainer').find('th').addClass('custom-header')

		$('#payersTable').on('click', 'tbody tr', ({ target }) => {
			const payer = getTableRowData(payersDt, target)
			editpayer(payer)
		})

		//Reload table when filters change
		$('#payersFilters, #payersFields').on('change', () => reloadpayersDt())

		const payerForm = [
			{
				$: $('#payerEditSite'),
				source: 'payer_siteid',
			},
			{
				$: $('#payerEditName'),
				source: 'payer_name',
			},
			{
				$: $('#payerEditStatus'),
				source: 'payer_status',
			},
			{
				$: $('#payerEditFein'),
				source: 'payer_fein',
			},
			{
				$: $('#payerEditAddress1'),
				source: 'payer_address1',
			},
			{
				$: $('#payerEditAddress2'),
				source: 'payer_address1',
			},
			{
				$: $('#payerEditCity'),
				source: 'payer_city',
			},
			{
				$: $('#payerEditState'),
				source: 'payer_state',
			},
			{
				$: $('#payerEditZip'),
				source: 'payer_zip',
			},
			{
				$: $('#payerEditNaic'),
				source: 'payer_naic',
			},
			{
				$: $('#payerEditGroup'),
				source: 'payer_group',
			},
			{
				$: $('#payerEditFileDate'),
				source: 'payer_filedate',
			},
		]

		const resetpayer = () => {
			$('#payerEditProgramAccess').val('').trigger('chosen:updated')
			$('#payerEditState').val('').trigger('chosen:updated')
			$('#payerEditForm').trigger('reset')
			$('#payerEditSave').off('click')
			$('#payerEditToggle').off('click')
			$('.payerPeopleRequests').remove()
			$('.payerPeople').remove()
			$('#payerEditChangeAccessCancel').hide()
			$('#payerEditProgramAccessContainer').hide()
			$('#payerEditAuthContainer').show()
			$('#payerEditChangeAccess').show()
			$('#payerEditToggle').show()
			$('#payereditProgTitle').show()
		}

		const downloadPayers = async () => {
			const tableFields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
			post('/tables/clients', {
				filters: JSON.stringify(getpayersFilters()),
				fields: JSON.stringify(getpayerFields()),
				orderCols: JSON.stringify(tableFields),
				mode: TABLE_DOWNLOAD,
				table_fields: JSON.stringify(tableFields),
			})
		}

		const closepayer = () => $('#payerEdit').slideUp(slideSpeed, () => resetpayer())

		const $authContainer = $('#payerEditAuthContainer').empty()
		const editpayer = async (payer: any = null) => {
			scrollTop()
			resetpayer()
			$authContainer.empty()

			$('#payerEditProgramAccessContainer').show()
			$('#payerUserContainer').hide()
			$('#payerEditAuth').hide()
			$('#payerEditToggle').hide()
			$('#payerEdit').slideDown(slideSpeed)

			if (payer) {
				console.log(payer)
				setForm(payerForm, payer)

				$('#payerEditProgramAccessContainer').hide()
				$('#payerEditAuth').show()
				$('#payerEditToggle').show()
				$("#payerEditState").val(payer.payer_state).trigger("chosen:updated")

				if (payer.payer_status == PAYER_ACTIVE) $('#payerEditToggle').text('archive')
				if (payer.payer_status == PAYER_INACTIVE) $('#payerEditToggle').text('reactivate')

				$('#payerEditLaunchQb').off('click').on('click', async () => post('/ui/payer_compare', { payer_compare: payer.payer_id }, '_blank'))

				const renderPrograms = async () => {
					const { programs } = await fetchPost('/data/submit', { type: 'get_payer_permissions', data: payer.payer_id })
					if (programs) {
						let $programDisplay: any
						if (programs && programs.length > 0) {
							$programDisplay = $(
								<div>
									{programs.map((program) => (
										<div className='list-group'>
											<a className="list-group-item list-group-item-action d-flex justify-content-between">
												<div className='d-flex justify-content-around gap-1 align-items-center'>
													{program.prog_name} | {Number(program.payerp_permzero) == ZERO_ACTIVE ? <span>Permanent Zero</span> : <span>Non-Zero</span>} |
													{(program.prog_id !== PROG_WAPAL) ?
														<span>
															{program.payer_filedate != null ? program.payer_filedatepretty : program.general_date}
														</span>
														:
														<span>
															N/A
														</span>
													}
												</div>
												<div>
													<button
														className="btn btn-primary btn-sm text-center payerEditAuthProgram"
														data-progid={program.prog_id}
														data-permzero={program.payerp_permzero}
														data-payer={program.payerp_payerid}
													>
														<i className="fa-solid fa-arrow-right-arrow-left"></i>
														<span> Toggle Perm-Zero</span>
													</button>
												</div>
											</a>
										</div>
									))}
								</div>
							).hide().fadeIn(300)
						}
						$('#payerEditAuthContainer').html($programDisplay as any)
					}
				}
				renderPrograms()

				$('#payerEditAuthContainer').off('click').on('click', '.payerEditAuthProgram', async (event) => {
					const $button = $(event.target).closest('.payerEditAuthProgram')
					const $spinner = $button.find('.spinner-border')

					const data = {
						program: $button.data('progid'),
						permzero: $button.data('permzero'),
						payer: $button.data('payer')
					}

					try {
						$spinner.show()
						$('.payerEditAuthProgram').prop('disabled', true)

						const res = await fetchPost('/reporting/submit', { type: 'change_perm_zero', data: data })
						if (res.rc != 'OK') throw res.rc
						displayNotification('Successfully changed permanent zero status', 'success')
						renderPrograms()
					} catch (error) {
						displayNotification('Error, could not change permanent zero status', 'danger')
						console.log(error)
					} finally {
						$('.payerEditAuthProgram').prop('disabled', false)
						$spinner.hide()
					}

				})

				const { users, requests } = await fetchPost('/reporting/submit', { type: 'get_users', data: payer.payer_id })
				if (users.length > 0) {
					$('#payerUserContainer').show()
					users.forEach(user => {
						$('#payerPeople').append(`<a href='#' id="user${user.user_id}" class="list-group-item list-group-item-action payerPeople">${user.user_first} ${user.user_last}`)
						$(`#user${user.user_id}`).on('click', async () => {
							toTab('peopleTabContent')
							editPeople(user)
						})
					});
				} else {
					$('#payerUserContainer').hide()
				}

				if (requests != '') {
					$('#payerRequestsContainer').append(`<p class="mt-2">User Invitations</p>`)
					requests.forEach(req => {
						$('#payerRequestsContainer').append(`<a id="req${req.req_id}" class="list-group-item list-group-item-action list-group-item-danger payerPeopleRequests">${req.req_email_recipient}`)
						$(`#req${req.req_id}`).on('click', async () => {
							toTab('requestsTabContent')
							requestView(req)
						})
					})
				}
			}

			type splitPayerType = {
				first: string,
				middle: string,
				last: string,
			}

			const splitPayer = (fein, payerName: string) => {
				try {
					console.log('payer name', payerName);
					if (!payerName) return;

					const name = `${fein} ${payerName}`;
					let parts = name.match(/^(\d+-\d+)\s+(\S+)\s+(.*)$/);

					let payer: splitPayerType = {
						first: '',
						middle: '',
						last: ''
					};

					if (parts !== null) {
						payer.first = parts[1]
						payer.middle = parts[2]
						payer.last = parts[3]
					} else {
						// Handle the case where the regex fails - the payer name is only one word
						const [first, ...rest] = name.split(" ")
						payer.first = first
						payer.middle = rest.length > 0 ? rest[0] : ""
						payer.last = rest.length > 1 ? rest.slice(1).join(" ") : ""
					}

					return payer
				} catch (err) {
					console.log(err)
				}
			}

			let allPrograms: program = {};
			const updateValidPrograms = async (payer_id: number, programs: program = null) => {
				if (programs != null) {
					allPrograms = programs
					return displayPrograms(allPrograms);
				}
				try {
					const res = await fetchPost('/support/submit', { type: 'get_valid_qb_programs', data: { payer_id: payer_id, programs: programs } })
					if (res.rc != 'OK') throw res.rc

					displayPrograms(res.programs)
				} catch (error) {
					console.log(error)
					displayNotification('Error, could not retrieve valid programs', 'danger')
				}
			}

			type program = { [id: string]: string }
			const displayPrograms = (programs: program) => {
				$('#previewProgList').empty()
				if (programs == null) return $('#previewProgList').append(`<li class="list-group-item">No programs available</li>`)

				for (let [id, name] of Object.entries(programs)) {
					$('#previewProgList').append(`
						<li data-program="${id}" style='cursor:pointer;' class="list-group-item d-flex justify-content-between align-items-center">
							<span>${name}</span>		
							<i style="cursor: pointer !important;" class="fa-solid text-success fa-check payerPrevTrash"></i>
						</li>
					`);
				}
			}

			const showPayerPreview = async (type: PayerPreview, programs, prog_change = null, payer) => {
				console.log('showing payer preview', payer)
				if (!payer || programs == null) return

				//show container
				$('#payerPreviewContainer').show()
				$('#ratePreviewContainer').hide()
				$('#payerPreviewTitle').html(`Payer Preview`) // set title to payer preview

				//drop down canvas
				const preview = new Offcanvas($('#full-screen-top') as any)
				preview.show()

				//update the valid connections
				updateValidPrograms(payer.payer_id, programs)

				//split the payer name
				const parts = splitPayer(payer.payer_fein, payer.payer_name) as any

				$('#previewTitle').html(`Payer Preview`)
				$('#payerPreviewDisplayName').val(`${payer.payer_fein} - ${payer.payer_name}`)
				$('#payerPreviewFirstName').val(parts.first)
				$('#payerPreviewMiddleName').val(parts.middle)
				$('#payerPreviewLastName').val(parts.last)
				$('#payerPreviewBillAddress').val(payer.payer_address1)
				$('#payerPreviewShipAddress').val(payer.payer_address2)
				$('#payerPreviewCity').val(payer.payer_city)
				$('#payerPreviewState').val(payer.payer_state)
				$('#payerPreviewZip').val(payer.payer_zip)
				$('#payerPreviewEmail').val(payer.user_email)
				$('#payerPreviewPhone').val(payer.user_phone)

				if (type == PayerPreview.QB_PAYER_INSERT) {
					$('#previewLang').text('A new payer has been detected. Would you like to insert this payer into the Quickbooks connection(s) below?')
				} else {
					$('#previewLang').text('A change has been detected. Would you like to add this change to the available Quickbooks connection(s) below?')
				}

				$('body').off('click').on('click', '#previewProgList', '.payerPrevTrash', (e) => {
					console.log('clicked here')
					const programId = $(e.currentTarget).parent().data('program')
					$(e.currentTarget).closest('.list-group-item').remove()
				})

				type ResultType = {
					prog_id: number,
					prog_name: string,
					status: number,
				}

				const displayResults = async (results: ResultType[]) => {
					if (results == null || results.length === 0) return displayNotification('Error, could not display results', 'danger')
					console.log('displaying results', results)

					$('#payerPreviewResultsContainer').slideDown() // show container
					$('#resultProgList').empty() // empty previous results 

					results.forEach(result => {
						//change the color of the result
						let type = result.status == UPLOAD_SUCCESS ? 'list-group-item-success' : 'list-group-item-danger'
						$('#resultProgList').append(`
							<li data-program="${result.prog_id}" style='cursor:pointer;' data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Press the trash can to remove this program" class="list-group-item d-flex ${type} justify-content-between align-items-center">
								<span>${result.prog_name}</span>
							</li>
						`)
					})
					$('#payerPreviewSave').prop('disabled', true)
				}

				//save customer data to quickbooks
				$('#payerPreviewSave').off('click').on('click', async (e) => {
					const $btn = $(e.currentTarget)
					buttonIsLoading($btn, true)
					try {
						const postData = {
							payer_id: payer.payer_id ?? null,
							payer_name: $('#payerPreviewDisplayName').val(),
							payer_billaddress: $('#payerPreviewBillAddress').val(),
							payer_shipaddress: $('#payerPreviewShipAddress').val(),
							payer_city: $('#payerPreviewCity').val(),
							payer_state: $('#payerPreviewState').val(),
							payer_zip: $('#payerPreviewZip').val(),
							payer_firstname: $('#payerPreviewFirstName').val(),
							payer_middlename: $('#payerPreviewMiddleName').val(),
							payer_lastname: $('#payerPreviewLastName').val(),
							payer_phone: $('#payerPreviewPhone').val(),
							payer_email: $('#payerPreviewEmail').val(),
							programs: $('#previewProgList').find('li').map((index, element) => $(element).data('program')).get()
						}
						const res = await fetchPost('/support/submit', { type: 'save_payer_preview', data: postData })
						if (res.rc != 'OK' || res.results == null) throw res.rc
						console.log('results', res.results)

						displayNotification('Success Uploading To Quickbooks', 'success')
						preview.hide()

					} catch (error) {
						displayNotification('Failed Uploading to Quickbooks', 'danger')
						console.log('error in payer preview save - ', error)
					} finally {
						buttonIsLoading($btn, false)
					}
				})

				$('#payerPreviewClose').off('click').on('click', () => preview.hide())
			}

			//add program to preview
			$('#payerPreviewAddProgram').off('click').on('click', async (event) => {
				console.log('adding program')
				if ($(event.currentTarget).hasClass('rotate')) {
					$(event.currentTarget).toggleClass('rotate')
					return $('#previewProgramSelectContainer').slideUp(slideSpeed)
				}
				$(event.currentTarget).toggleClass('rotate')
				$('#previewProgramSelectContainer').slideDown(slideSpeed)
			})

			$('#payerEditSave').on('click', async () => {
				let payer_form = {
					payer_id: payer ? payer.payer_id : null,
					payer_name: $('#payerEditName').val(),
					payer_address1: $('#payerEditAddress1').val(),
					payer_address2: $('#payerEditAddress2').val(),
					payer_status: $('#payerEditStatus').val(),
					payer_fein: $('#payerEditFein').val(),
					payer_naic: $('#payerEditNaic').val(),
					payer_group: $('#payerEditGroup').val(),
					payer_city: $('#payerEditCity').val(),
					payer_state: $('#payerEditState').val(),
					payer_zip: $('#payerEditZip').val(),
					payer_filedate: $('#payerEditFileDate').val(),
				}

				try {
					$('#payerEditSave').prop('disabled', true)
					const postData = {
						payer_id: payer ? payer.payer_id : null,
						payer: payer_form,
						programs: $('#payerEditProgramAccess').val()
					}

					const res = await fetchPost('/reporting/submit', { type: 'update_payer', data: postData })
					if (res.rc != 'OK') throw res.rc

					//close edit form and display success 
					displayNotification('Saved Payer Information', 'success')
					reloadpayersDt()
					closepayer()

					//add user info to the payer form
					if (res.user_info) payer_form = { ...payer_form, ...res.user_info }

					//determine whether we want to show the preview
					if (res.qb_type !== undefined && res.qb_type !== null && res.valid_qb_programs && Object.keys(res.valid_qb_programs).length > 0) {
						setTimeout(() => showPayerPreview(res.qb_type, res.valid_qb_programs, res.prog_change, payer_form), 2000);
					}

				} catch (error) {
					if (error == 'FEIN_EXISTS') return displayNotification('Error, Payer already exists', 'danger')
					displayNotification('Error saving payer information', 'danger')
					console.log(error)
				} finally {
					$('#payerEditSave').prop('disabled', false)
				}
			})

			type action = 'show' | 'hide'
			const toggleProgramAccess = (action: action) => {
				if (action == 'show') {
					$('#payerEditProgramAccessContainer').fadeIn(300)
					$('#payerEditChangeAccessCancel').show()
					$('#payerEditAuthContainer').hide()
					$('#payerEditChangeAccess').hide()
					$('#payerEditToggle').hide()
					$('#payereditProgTitle').hide()
				} else {
					$('#payerEditChangeAccessCancel').hide()
					$('#payerEditProgramAccessContainer').hide()
					$('#payerEditAuthContainer').fadeIn(300)
					$('#payerEditChangeAccess').show()
					$('#payerEditToggle').show()
					$('#payereditProgTitle').show()
				}
			}

			$('#payerEditChangeAccess').on('click', async () => {
				const $spinner = $('#payerEditChangeAccess').find('.spinner-border').show()
				try {
					const res = await fetchPost('/reporting/submit', { type: 'get_payer_programs', data: { payer_id: payer.payer_id } })
					if (res.rc != 'OK') throw res.rc

					toggleProgramAccess('show')
					$('#payerEditProgramAccess').val(res.programs).trigger('chosen:updated')

				} catch (error) {
					if (error == 'NO_PAYER') return displayNotification('Payer Settings Not Found', 'danger')
					displayNotification('Error, could not get programs', 'danger')
					toggleProgramAccess('hide')
					console.log(error)
				} finally {
					$spinner.hide()
				}
			})

			$('#payerEditChangeAccessCancel').on('click', () => toggleProgramAccess('hide'))

			$('#payerEditToggle').on('click', async () => {
				try {
					const data = {
						payer_id: payer.payer_id,
						status: payer.payer_status,
					}
					const { rc } = await fetchPost('/reporting/submit', { type: 'toggle_payer', data })
					if (rc != 'OK') throw rc

					displayNotification('Success toggling payer status', 'success')
					reloadpayersDt()
					closepayer()
				} catch (e) {
					console.error(e)
					displayNotification('Error, failure toggling status', 'danger')
				}
			})
		}

		$('#payerEditClose').on('click', () => closepayer())

		return payersDt
	}

	let assessDt: DataTables.Api | null = null
	const $assessTableTemplate = $('#assessTable').remove()

	const initAssessments = async () => {
		let progId = $('#assessFilterPrograms').val()
		const { ages } = await fetchPost('/reporting/submit', { type: 'get_ages', data: progId })
		const ageCols = ages.map(({ age_id, age_name }) => ({
			data: `age${age_id}`,
			header: age_name,
		}))

		type period = {
			rate_id: number,
			rate_name: string,
			rate_period: string,
			rate_progid: number,
		}

		type program = {
			prog_id: number,
			prog_name: string,
		}

		try {
			$('#assessFilterPeriod').prop('disabled', true)
			let { programs, periods } = await fetchPost('/reporting/submit', { type: 'get_periods', data: progId })

			let $periodFilter: any
			if (periods && periods.length > 0) {
				$periodFilter = $(
					<div>
						<label>Period</label>
						<select id='assessFilterPeriod' className="form-control chosen">
							<option value="" selected>No filter</option>
							{programs.map((program: program) => (
								<optgroup key={program.prog_id} label={program.prog_name}>
									{periods
										.filter((period: period) => period.rate_progid === program.prog_id)
										.map((period: period) => (
											<option key={period.rate_id} value={period.rate_id}>
												{`${period.rate_period}-${period.rate_name}`}
											</option>
										))}
								</optgroup>
							))}
						</select>
					</div>
				)
			}
			$('#assessFilterPeriodContainer').html($periodFilter as any)

		} catch (error) {
			console.error(error)
		}

		const getassessFilters = () => ({
			status: $('#assessFilterStatus').val(),
			program: $('#assessFilterPrograms').val(),
			payer: $('#assessFilterPayers').val(),
			period: $('#assessFilterPeriod').val(),
			start: $('#assessFilterStart').val(),
			end: $('#assessFilterEnd').val(),
			fileDate: $('#assessFilterFileDate').val(),
			suspect: $('#assessFilterSuspect').val(),
		})

		setCollapse({ $btn: $('#assessFiltersCollapse'), $target: $('#assessFilters') })

		const initassessDt = () => {
			const columnsInfo = [
				{
					data: 'file_id',
					header: 'File ID',
					visible: false,
				},
				{
					data: 'payer_fein',
					header: 'Fein',
				},
				{
					data: 'payer_name',
					header: 'Payer',
				},
				{
					data: 'prog_name',
					header: 'Program',
				},
				{
					data: 'rate_name',
					header: 'Rate Name',
				},
				{
					data: 'rate_period',
					header: 'Period',
				},
				{
					data: 'file_datepretty',
					header: 'Date Filed',
				},
				{
					data: 'file_program',
					header: 'Reference #',
				},
				{
					data: 'file_overdue',
					header: 'Overdue',
				},
				...ageCols,
				{
					data: 'file_assesspretty',
					header: 'Assessment',
				},
				{
					data: 'file_intpretty',
					header: 'Interest',
				},
				{
					data: 'file_amt',
					header: 'Total Amount',
				},
				{
					data: 'file_reason',
					header: 'Reason',
				},
				{
					data: 'email_admin',
					header: 'Admin Email',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			if (assessDt != null) {
				assessDt.destroy()
				$('#assessTableContainer').empty()
			}
			const $assessTable = $assessTableTemplate.clone()
			$('#assessTableContainer').append($assessTable)

			assessDt = $assessTable.tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/assessments',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getassessFilters(),
						prog_id: progId,
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [
					{ targets: '_all', defaultContent: '' },
					{ className: "dt-nowrap", "targets": [0] }
				],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				pageLength: 10,
				lengthMenu: [
					[10, 25, 50, 100, 1000], // The number of rows per page options
					[10, 25, 50, 100, 1000] // The corresponding labels
				],
				buttons: [
					{
						text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
						className: ' ms-1 download btn btn-sm btn-primary',
						action: () => downloadAssessments(),
					},
				],
				dom: datatablesButtonDom,
				language: {
					emptyTable: "No assessments to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> assessments",
					infoEmpty: "Showing 0 to 0 of 0 assessments",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total assessments)",
					lengthMenu: "Show _MENU_ assessments",
				}
			})

			$('#assessTableContainer').find('th').addClass('custom-header')

			const downloadAssessments = async () => {
				const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
				post('/tables/assessments', { filters: JSON.stringify(getassessFilters()), mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields), prog_id: progId })
			}

			return assessDt
		}
		initassessDt()

		const reloadassessDt = () => assessDt.ajax.reload(undefined, false)

		$('#assessTable').on('click', 'tbody tr', ({ target }) => {
			const assess = getTableRowData(assessDt, target)
			editAssessment(assess)
		})

		const resetAssess = () => {
			scrollTop()
			$('#assessEditForm').trigger('reset')
			$('#assessEditSave').off('click')
		}

		$('#assessFiltersClear').on('click', (e) => {
			$("#assessFilters :input").val('')
			$('#assessFilterPrograms').val('').trigger("chosen:updated")
			$('#assessFilterPayers').val('').trigger("chosen:updated")
			$('#assessFilterPeriod').val('').trigger("chosen:updated")
			e.preventDefault()
			reloadassessDt()
		})

		const closeAssess = () => $('#assessEdit').slideUp(slideSpeed, () => resetAssess())

		//Reload table when filters change
		$('#assessFilters').on('change', () => reloadassessDt())

		const assessForm = [
			{
				$: $('#assessEditFein'),
				source: 'payer_fein',
			},
			{
				$: $('#assessEditPayer'),
				source: 'file_payerid',
			},
			{
				$: $('#assessEditDate'),
				source: 'file_createdate',
			},
			{
				$: $('#assessEditReference'),
				source: 'file_program',
			},
			{
				$: $('#assessEditOver'),
				source: 'file_overdue',
			},
			{
				$: $('#assessEditRatePeriod'),
				source: 'rate_period',
			},
			{
				$: $('#assessEditStart'),
				source: 'rate_startdate',
			},
			{
				$: $('#assessEditEnd'),
				source: 'rate_enddate',
			},
			{
				$: $('#assessEditDue'),
				source: 'rate_duedate',
			},
			{
				$: $('#assessEditGroup'),
				source: 'payer_group',
			},
			{

				$: $('#assessEditReason'),
				source: 'file_reason',
			},
		]

		$('#assessEditClose').on('click', () => closeAssess())

		const renderRateSelect = async (progId = null) => {

			type rate = {
				rate_id: number,
				rate_progid: number,
				rate_name: string,
			}

			try {
				$('#assessEditRate').prop('disabled', true)
				let { programs, rates } = await fetchPost('/reporting/submit', { type: 'get_rates', data: progId })

				let $ratesSelect: any
				if (rates && rates.length > 0) {
					$ratesSelect = $(
						<div>
							<label>Rate</label>
							<select id='assessEditRate' className="form-control chosen">
								{programs.map((program: program) => (
									<optgroup key={program.prog_id} label={program.prog_name}>
										{rates
											.filter((rates: rate) => rates.rate_progid === program.prog_id)
											.map((rates: rate) => (
												<option key={rates.rate_id} value={rates.rate_id}>
													{rates.rate_name}
												</option>
											))}
									</optgroup>
								))}
							</select>
						</div>
					);
				}
				$('#assessEditRateContainer').html($ratesSelect as any)

			} catch (error) {
				console.error(error)
			} finally {
				$('#assessEditRate').prop('disabled', false)
			}
		}

		renderRateSelect()

		tinymce.init({
			selector: '#remitSendMessage',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		const $ageTemplate = $('#fileEditAmountGroup')
		const editAssessment = async (assess: any = null) => {
			$('#adjustmentContainer').hide()
			$('#assessEdit').slideDown(slideSpeed)
			scrollTop()

			//filter rates by program
			renderRateSelect(assess.prog_id)

			if (assess.file_status == FILE_ACTIVE) $('#assessEditToggle').text('archive')
			if (assess.file_status == FILE_INACTIVE) $('#assessEditToggle').text('reactivate')

			if (assess) {
				$('#adjustmentContainer').show()
				$('#assessEditInterestContainer').show()
				$('#assessEditOverContainer').show()
				$('#assessEditAdjustContainer').hide() // hide adjustment container on default

				//hide interest and overdue if program is wapal
				if (assess.prog_id == PROG_WAPAL) {
					$('#assessEditInterestContainer').hide()
					$('#assessEditOverContainer').hide()
				}

				//check if the filing has been uploaded to quickbooks
				if (assess.file_qbid == null) {
					$('#assessEditUploaded').removeClass('alert-success').addClass('alert-danger alert-dismissible').text(' Out of Sync with Quickbooks.').prepend('<i class="fa-solid fa-x"></i>')
				} else {
					$('#assessEditUploaded').removeClass('alert-danger').addClass('alert-success alert-dismissible').text(' In Sync with Quickbooks.').prepend('<i class="fa-solid fa-check"></i>')
				}

				$('#fileEditFormRow').empty()
				const { ages, months } = await fetchPost('/reporting/submit', { type: 'get_rate_info', data: { prog_id: assess.prog_id, rate_id: assess.rate_id } })
				const countForm = ages.map(({ age_id, age_name }) => {

					return Object.entries(months).map(([monthName, monthNumber]) => {
						const $group = getTemplates().find('#fileEditAmountGroup').clone()
						const $label = $group.find('#fileEditAmountLabel')
						const $input = $group.find('#fileEditAmount')
						const htmlId = `fileEditAmount${age_id}_${monthNumber}`

						$group.prop('id', `fileEditAmountGroup${age_id}_${monthNumber}`)
						$label.removeProp('id').prop('for', htmlId).text(`${age_name} - ${monthName}`)
						$input.prop('id', htmlId);

						inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
						$('#fileEditFormRow').append($group)

						return {
							$: $input,
							age_id: age_id,
							month: monthNumber,
						}
					})
				}).flat()

				$('#fileEditFormRowTotals').empty()
				const countFormTotals = ages.map(({ age_id, age_name }) => {
					const $group = getTemplates().find('#fileEditAmountGroupTotals').clone()
					const $label = $group.find('#fileEditAmountTotalLabel')
					const $input = $group.find('#fileEditAmountTotal')
					const htmlId = `#fileEditAmountTotal${age_id}`
					$group.prop('id', `#fileEditAmountGroupTotal${age_id}`)
					$label.removeProp('id').prop('for', htmlId).text(`${age_name} - Total`)
					$input.prop('id', htmlId)
					inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
					$('#fileEditFormRowTotals').append($group)
					return {
						$: $input,
						source: age_id,
					}
				})

				$('#assessEditRateAgeRow').empty()
				const costsForm = ages.map(({ age_id, age_name }) => {
					const $group = getTemplates().find('#assessEditAmountGroup').clone()
					const $label = $group.find('#assessEditAmountLabel')
					const $input = $group.find('#assessEditAmount')
					const htmlId = `#assessEditAmount${age_id}`
					$group.prop('id', `#assessEditAmountGroup${age_id}`)
					$label.removeProp('id').prop('for', htmlId).text(`${age_name} cost`)
					$input.prop('id', htmlId)
					inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
					$('#assessEditRateAgeRow').append($group)

					return {
						$: $input,
						source: age_id,
					}
				})

				type CustomInput = {
					$: $,
					age_id: number,
					month: number,
				}
				type CustomForm = CustomInput[]
				const setCustomForm = (form: CustomForm, data: object) => {
					form.forEach(({ $, age_id, month }) => {
						const sourceKey = `${age_id}_${month}`
						$.val(data[sourceKey])
					})
				}

				const getCustomForm = (form: CustomForm) => {
					const data = {};
					form.forEach(({ $, age_id, month }) => {
						const sourceKey = `${age_id}_${month}`
						data[sourceKey] = $.val()
					})
					return data
				}

				type adjustment = {
					file_adjustment: number,
					file_adjustreason: string,
					file_adjust_type: string,
				}

				const postData = {
					file_id: assess.file_id,
					rate_id: assess.rate_id,
					prog_id: assess.prog_id,
					payer_id: assess.file_payerid
				}

				const { total_count, costs, count, adjustment: adjustment } = await fetchPost('/reporting/submit', { type: 'file_count', data: postData })
				const totalCountParsed = {}
				const costParsed = {}

				Object.entries(total_count).forEach(([id, amount]) => totalCountParsed[id] = amount as number)
				Object.entries(costs).forEach(([id, amount]) => costParsed[id] = amount as number / 100)

				setForm(assessForm, assess)
				setForm(costsForm, costParsed)
				setForm(countFormTotals, totalCountParsed)
				setCustomForm(countForm, count)

				$('#assessEditProgram').val(assess.prog_id).trigger('chosen:updated')
				$('#assessEditPayer').val(assess.file_payerid).trigger('chosen:updated')
				$('#assessEditRate').val(assess.rate_id).trigger('chosen:upda`ted')

				//update previous adjustment
				if (adjustment && adjustment.file_adjustment != null) {
					//FILING TAB
					$('#assessEditAdjustTotal').val(Number(adjustment.file_adjustment / 100)) //adjustment amount
					$('#assessEditAdjustContainer').show()
					if (adjustment.file_adjust_type && Number(adjustment.file_adjust_type) === ADJUST_CREDIT) { // CREDIT
						$('#assessPrevAdjustType').removeClass('text-danger').addClass('text-success').html('( Credit + )')
						$('#assessPrevAdjustType').find('i').removeClass('fa-minus').addClass('fa-plus') //icon
					} else {
						$('#assessPrevAdjustType').removeClass('text-success').addClass('text-danger').html('( Debit - )')
						$('#assessPrevAdjustType').find('i').removeClass('fa-plus').addClass('fa-minus')
					}

					//ADJUSTMENT TAB
					$('#assessEditPrevAdjustment').val(adjustment.file_adjustment / 100)
					$('#assessEditPrevAdjustType').val(adjustment.file_adjust_type).trigger('chosen:updated')
					$('#assessEditPrevAdjustmentReason').val(adjustment.file_adjustreason)
				}

				//FILING TAB
				$('#assessEditAssessment').val(assess.file_assessment / 100) //assessment value
				$('#assessEditInterest').val(assess.file_interest / 100) //interest
				$('#assessEditAmt').val(assess.file_amount / 100) //total

				//ADJUSTMENT TAB
				$('#assessEditAdjustment').val(assess.file_adjustment / 100) // adjustment on next filing
				$('#assessEditAdjustType').val(assess.file_adjust_type).trigger('chosen:updated') //adjust type for next filing
				$('#assessEditAdjustmentReason').val(assess.file_adjustreason) // adjust reason for next filing

				$('#assessEditSave').off('click').on('click', async () => {
					if (($("#assessEditForm")[0] as HTMLFormElement).checkValidity() === false) {
						($("#assessEditForm")[0] as HTMLFormElement).reportValidity()
						return
					}

					const prevAdjust = {
						adjustment: $('#assessEditPrevAdjustment').val(),
						adjust_type: $('#assessEditPrevAdjustType').val(),
						adjust_reason: $('#assessEditPrevAdjustmentReason').val(),
					}

					const form_data = {
						rate_id: $('#assessEditRate').val(),
						assessment: $('#assessEditAssessment').val(),
						interest: $('#assessEditInterest').val(),
						amount: $('#assessEditAmt').val(),
						adjustment: $('#assessEditAdjustment').val(),
						adjust_reason: $('#assessEditAdjustmentReason').val(),
						adjust_type: $('#assessEditAdjustType').val(),
						prev_adjust: prevAdjust,
					}

					try {
						$('#assessEditSave').prop('disabled', true)
						const count = getCustomForm(countForm)
						Object.entries(count).forEach(([id, amount]) => count[id] = amount as number)
						const total_count = getForm(countFormTotals)
						const postData = {
							file_id: assess ? assess.file_id : null,
							locked: $('#assessEditLocked').is(":checked") ? FILING_LOCKED : null,
							assess: getForm(assessForm),
							count: count,
							total_count: total_count,
							data: form_data,
						}
						const { rc } = await fetchPost('/reporting/submit', { type: 'update_assess', data: postData })
						if (rc != 'OK') throw rc

						displayNotification('Saved Assessment Information')
						closeAssess()
						reloadassessDt()
					}
					catch (error) {
						switch (error) {
							case 'INVALID_PAYER':
								displayNotification('Error, please follow the correct Fein format', 'danger')
								break
							case 'NO_PRIV':
								displayNotification('Error, you do not have the correct privledges', 'danger')
								break;
							default:
								displayNotification('Error, could not save assessment.', 'danger')
						}
					} finally {
						$('#assessEditSave').prop('disabled', false)
					}
				})

				$('.fileEditAmountTotals').off('change').on('change', async () => {
					const ageCount = getForm(countFormTotals)
					calulateTotals(ageCount)
				})

				$('#assessEditRate').off('change').on('change', async () => {
					type rate = {
						rate_id: number,
						rate_name: string,
						rate_period: number,
						rate_startdate: string,
						rate_enddate: string,
						rate_duedate: string,
					}
					const rate_id = $('#assessEditRate').val()
					try {
						const { rate }: { rate: rate } = await fetchPost('/reporting/submit', { type: 'get_rate', data: rate_id })
						if (!rate) throw new Error('No Rate Found')

						$('#assessEditRateName').val(rate.rate_name)
						$('#assessEditRatePeriod').val(rate.rate_period)
						$('#assessEditStart').val(rate.rate_startdate)
						$('#assessEditEnd').val(rate.rate_enddate)
						$('#assessEditDue').val(rate.rate_duedate)
					} catch (error) {
						console.error(error)
					}
				})

				$('#assessEditPayer').off('change').on('change', async () => {
					type payer = {
						payer_fein: string,
						payer_group: number,
					}
					const payer_id = $('#assessEditPayer').val()
					try {
						const { payer }: { payer: payer } = await fetchPost('/reporting/submit', { type: 'get_payer_info', data: payer_id })
						if (!payer) throw new Error('No Rate Found')

						$('#assessEditFein').val(payer.payer_fein)
						$('#assessEditGroup').val(payer.payer_group)
					} catch (error) {
						console.error(error)
					}
				})

				$('#assessEditProgram').off('change').on('change', () => renderRateSelect($('#assessEditProgram').val()))

				$('.fileEditAgeAmount').off('change').on('change', async () => {
					const countByMonth = getCustomForm(countForm)
					const countByAge = Object.entries(countByMonth).reduce((object, [key, value]) => {
						const [age, month] = key.split("_");
						if (!object[age]) {
							object[age] = 0;
						}
						object[age] += Number(value)
						return object;
					}, {});

					setForm(countFormTotals, countByAge)
					calulateTotals(countByAge)
				})

				const calulateTotals = async (ageCount) => {
					try {
						$('#assessEditSave').prop('disabled', true)
						const postData = {
							count: ageCount,
							rate_id: assess.rate_id,
							file_id: assess.file_id,
							program: $('#assessEditProgram').val()
						}
						const { rc, totals } = await fetchPost('/data/submit', { type: 'calc_reporting', postData })
						if (rc != 'OK') throw rc

						$('#assessEditAssessment').val(Number(totals.assessment / 100).toFixed(2))
						$('#assessEditInterest').val(Number(totals.interest / 100).toFixed(2))
						$('#assessEditAmt').val(Number(totals.cost / 100).toFixed(2))
						$('#assessEditOver').val(totals.overdue)

					} catch (e) {
						displayNotification('Error Caclulating Totals', 'danger')
						console.error('calc amounts', e)
						$('#assessEditSave').prop('disabled', false)
					} finally {
						$('#assessEditSave').prop('disabled', false)
					}
				}

				$('#assessEditToggle').off('click').on('click', async () => {
					const data = {
						file_id: assess.file_id,
						status: assess.file_status,
					}
					const { rc } = await fetchPost('/reporting/submit', { type: 'toggle_filing', data })
					if (rc != 'OK') {
						displayNotification('could not toggle status', 'danger')
						return;
					}
					displayNotification('success toggling status', 'success')
					reloadassessDt()
					closeAssess()
				})

				const clearMessage = () => {
					$('#remitSendEmail').val('')
					tinymce.get('remitSendMessage').setContent('')
				}

				$('#assessSendClose').on('click', () => clearMessage())

				$('#assessEditSend').off('click').on('click', async ({ target }) => {
					$('#remitSendModal').modal('show')

					$('#remitSend').off('click').on('click', async () => {
						const $spinner = $('#assessSendSpinner')
						$spinner.show()

						if ($('#remitSendEmail').val() == '') {
							displayNotification('Please enter an email', 'danger')
							return;
						}

						try {
							$('#remitSend').prop('disabled', true)
							const html = await generatePdf(true)
							const data = {
								html: html,
								email: $('#remitSendEmail').val(),
								message: tinymce.get('remitSendMessage').getContent(),
								file_name: assess.payer_name + '-' + assess.rate_name + '.pdf'
							}
							const { rc } = await fetchPost('/reporting/submit', { type: 'send_remit', data: data })
							if (rc != 'OK') throw rc

							displayNotification('success sending remitttance form', 'success')
							clearMessage()
							$('#remitSendModal').modal('hide')
						} catch (e) {
							console.error(e)
						} finally {
							$('#remitSend').prop('disabled', false)
							$spinner.hide()
						}
					})
				})

				const generatePdf = async (isReturning = false) => {
					try {
						$('#assessprint_spinner').show()
						$('#assessprint_spinner').prop('disabled', true)

						const $container = $('#print_container')
						const post_data = {
							file_id: assess.file_id,
							rate_id: assess.file_rateid,
							program_id: assess.prog_id,
							payer_id: assess.file_payerid
						}

						const { rc, data, ages, logo_guid } = await fetchPost('/data/submit', { type: 'print_reporting', data: post_data })
						if (rc != 'OK') return

						console.log('data', data)

						//get logo
						const response = await fetch(`/data/get_logo/${logo_guid}`, { method: 'GET' })
						const json = await response.json(); // Parse the JSON body

						const url = json.url
						const image = document.createElement('img')
						image.style.maxHeight = '150px'
						image.style.maxWidth = '350px'
						image.src = url

						let $tabClone = $container.clone()
						$tabClone.find('#assessEditPrint').remove()
						$tabClone.find('#assessprint_spinner').remove()
						$tabClone.find('#assessEditUploadContainer').remove()

						$tabClone.find('.dropdown').remove()
						$tabClone.find('#print_file_logo').show()
						$tabClone.find('#print_file_content').show()

						//header
						$tabClone.find('#assessPrintLogo').append(image)
						$tabClone.find('#assessPrintLogo').append(`
							<div style='text-align: center; margin-top: 2rem;'>
								<p className='print_text'>${data.print_heading}</p>
							</div>`
						)

						$tabClone.find('#assessPrintContent').prepend(`
							<div style='text-align: end;' class=' print_text'>
								<p>
									Filed: &nbsp ${data.filing.file_duepretty}</br>
									Reference: &nbsp ${data.filing.file_program}
								</p>
							</div>
							<div style='display: flex; justify-content: center; margin-top: .5rem;' class='print_text''>
								<div style='text-align: end; margin-top: 0;' class='text-center'> 
									<p style='font-size:16px !important; margin-top: 0;'><b>${data.program} Remittance Form</b></br>
								</div>
							</div>
							<div class='print_text'>
								<p>
									Report for: ${data.rate.rate_name}<br>
									Due: ${data.rate.rate_duepretty}<br>
									Federal EIN: ${data.payer.payer_fein}</br>
									Company Name: ${data.payer.payer_name}
								</p>
							</div>
						`)

						//calc table
						$tabClone.append(`
							<div class='row container-fluid'> 
								<table class="table table-borderless">
									<thead style='border-bottom: 2px solid black;'>
										<tr>
											<th style='border-bottom: 1px solid black;' scope="col"></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Lives</b></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Rate</b></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Assessment</b></th>
										</tr>
									</thead>
									<tbody style='border-top: 2px solid black;' id='print_body'>
									</tbody>
								</table>
							</div>
						`)

						ages.forEach(age => {
							$tabClone.find('#print_body').append(`
								<tr>
									<td style='margin:2rem;' scope="row">${data.program} Resident ${age.age_name} Covered Lives Reported:</td>
									<td style='margin:2rem;' >${Number(age.total_count_amount).toLocaleString('en-US') ?? 0}</td>
									<td style='margin:2rem;' >${formatter.format(age.cost_amount / 100)}</td>
									<td style='text-align: end;'>${formatter.format((age.total_count_amount * age.cost_amount) / 100)}</td>
								</tr>
							`)
						});

						if (data.rate.rate_progid != PROG_WAPAL) {

							if (data.filing.file_adjustment && (Number(data.filing.file_adjust_type) || Number(data.filing.file_adjust_type) === 0)) { //adjustment
								const type = data.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
								const operation = data.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
								let total_before_adjustment = 0;
								ages.forEach(age => {
									total_before_adjustment += ((age.total_count_amount * age.cost_amount) / 100)
								});

								$tabClone.find('#print_body').append(`
									<tr>
										<td class='print_text' scope="row">Asessment due</td>
										<td></td>
										<td></td>
										<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
									</tr>
									<tr>
										<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
										<td></td>
										<td></td>
										<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
									</tr>
									<tr>
										<td><p class='print_text'>Adjustment ${type}:</p></td>
										<td></td>
										<td></td>
										<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${operation} ${formatter.format(data.file_adjustment / 100)} </p></td>
									</tr>
									<tr>
										<td class='print_text'><b>Total Due</b></td>
										<td></td>
										<td></td>
										<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
									</tr>
								`)

							} else {

								$tabClone.find('#print_body').append(`
									<tr>
										<td class='print_text' scope="row">Asessment due</td>
										<td></td>
										<td></td>
										<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
									</tr>
									<tr>
										<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
										<td></td>
										<td></td>
										<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
									</tr>
									<tr>
										<td class='print_text'><b>Total Due</b></td>
										<td></td>
										<td></td>
										<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
									</tr>
	
								`)

							}

						} else { //wapal custom body
							if (data.file_adjustment && data.file_adjust_type) { //adjustment
								const type = data.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
								const operation = data.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
								let total_before_adjustment = 0;
								ages.forEach(age => {
									total_before_adjustment += (Number(age.total_count_amount) * Number(age.cost_amount))
								});

								$tabClone.find('#print_body').append(`
									<tr>
										<td class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
										<td></td>
										<td></td>
										<td class='print_text text-end'><p style= 'text-align: end; border-bottom: black 1px solid; border-top: black 1px solid;'><strong>${formatter.format(total_before_adjustment / 100)}</strong></p></td>
									</tr>
									<tr>
										<td class='print_text'><b>Adjustment ${type} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
										<td></td>
										<td></td>
										<td class='print_text text-end'><p style = 'text-align: end; margin-bottom: 0px;'><strong>${operation} ${formatter.format(data.file_adjustment / 100)}</strong></p></td>
									</tr>
									<tr>
										<td style='padding-right: 5rem; margin-top: 0px;' class='print_text'><b>Total Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
										<td></td>
										<td></td>
										<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
									</tr>
								`)
							} else {
								$tabClone.find('#print_body').append(`
								<tr>
									<td style='padding-right: 5rem;' class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
								</tr>
								`)
							}
						}

						//footer
						if (data.rate.rate_progid != PROG_MVB) {
							$tabClone.append(`
								<div><p> <strong>Please wire or ACH transaction payment in full as follows:</br></strong> ${data.print_wire}</p> </div>
								<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
									<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check} </a></b></p>
								</div>	
								<p style='margin-bottom: 0;' class='print_text mb-0'>mail all payments and correspondence to:</p>
								<div style='display:flex; justify-content: space-between;'>
									<div style='text-align: center'>
										<p><strong>USPS:</strong> </br>${data.print_usps}</p>
									</div>
									<div style='text-align: center;'>
										<p><strong>UPS/Fed Ex requiring a street address:</strong> </br>${data.print_ups}</p>
									</div>
								</div>
								<div style='text-align: center; margin-top: 2rem;'>
								<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>`
							)
						} else { //custom maine footer
							$tabClone.append(`
								<div style='display: flex; justify-content: space-between;'>
									<p><strong>ALL ACH GO TO TD BANK</strong></br>${data.print_ach}</p>
									<p style='text-align: left;'><strong>ALL WIRE GO TO TD BANK</strong></br>${data.print_wire}</p>
								</div>
								<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
									<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check}</a></b></p>
								</div>	
								<div style='display: flex; justify-content: space-between; text-align: center'>
									<div style='text-align: center'>
										<p style='text-align: center';><strong>ALL CHECKS AND REGULAR MAIL</strong></br>${data.print_usps}</p>
									</div>
									<div style='text-align: center'>
										<p style='text-align: center; '><strong>ALL, FEDEX, UPS, OVERNIGHTS</strong></br>${data.print_ups}</p>
									</div>
								</div>
								<div style='text-align: center; margin-top: 2rem;'>
								<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>
							`)
						}
						$tabClone.find('.print_modal').remove()

						if (isReturning) {
							const html = $tabClone.html()
							return html
						}
						//convert to html and generate pdf
						const html = $tabClone.html()
						post('/data/get_pdf', { html: html, program: data.print_progid })

					} catch (error) {
						displayNotification('Error Downloading Pdf', 'danger')
						console.error('File Print', error)
					} finally {
						$('#assessprint_spinner').hide()
						$('#assessprint_spinner').prop('disabled', false)
					}
				}

				$('#assessEditPrint').off('click').on('click', async () => generatePdf())
			}
		}
	}
	$('#assessFilterPrograms').off('change').on('change', async () => initAssessments())

	setCollapse({ $btn: $('#ratesFiltersCollapse'), $target: $('#ratesFilters') })

	let ratesDt: DataTables.Api | null = null
	const $ratesTableTemplate = $('#ratesTable').remove()

	const initRates = async () => {
		if ($('#rateEdit').is(':visible')) {
			$('#rateEdit').slideUp(slideSpeed)
		}

		const prog_id = $('#ratesPrograms').val()
		const { ages, isMultiple } = await fetchPost('/reporting/submit', { type: 'get_ages', data: prog_id })
		const ageCols = ages.map(({ age_id, age_name }) => ({
			data: `age${age_id}`,
			header: age_name,
		}))

		//Init table
		const columnsInfo = [
			{
				data: 'rate_name',
				header: 'Name',
			},
			{
				data: 'rate_period',
				header: 'Period',
			},
			{
				data: 'rate_startpretty',
				header: 'Start Date',
			},
			{
				data: 'rate_endpretty',
				header: 'End Date',
			},
			{
				data: 'rate_duepretty',
				header: 'Due Date',
			},
			{
				data: 'rate_filepretty',
				header: 'File Date',
			},
			{
				data: 'rate_startdate',
				header: 'Start Date',
				visible: false
			},
			{
				data: 'rate_enddate',
				header: 'End Date',
				visible: false
			},
			...ageCols,
			{
				data: 'rate_filedate',
				header: 'file Date',
				visible: false
			},
			{
				data: 'rate_duedate',
				header: 'Due Date',
				visible: false
			},
		]
		const columnIndexes = columnsInfo.reduce((obj, col, index) => ({ ...obj, [col.data]: index }), {})
		const headers = columnsInfo.map((col) => col.header)

		if (ratesDt != null) {
			ratesDt.destroy()
			$('#ratesTableContainer').empty()
		}

		const $ratesTable = $ratesTableTemplate.clone()
		$('#ratesTableContainer').append($ratesTable)

		ratesDt = $ratesTable.tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/rates',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					prog_id,
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[columnIndexes.rate_startdate, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			buttons: [
				{
					text: '<span class="fas fa-plus mr-1"></span> Generate Rate',
					className: 'add ms-2 btn btn-sm btn-success',
					action: () => addRate(),
				},
				{
					text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
					className: ' ms-1 download btn btn-sm btn-primary',
					action: () => downloadRate(),
				},
			],
			dom: `
				<'row'<'col-sm-12 col-md-4'l><'col-sm-12 col-md-8 d-flex justify-content-end'fB>>
				<'row'<'col-sm-12'tr>>
				<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>
			`,
			language: {
				emptyTable: "No rates to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> rates",
				infoEmpty: "Showing 0 to 0 of 0 rates",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total rates)",
				lengthMenu: "Show _MENU_ rates",
			},
			initComplete: () => {
				const $scroll = $('#ratesTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#ratesTableContainer').find('th').addClass('custom-header')

		const reloadRatesDt = () => ratesDt.ajax.reload(undefined, false)

		$ratesTable.on('click', 'tbody tr', ({ target }) => {
			const { rate_id } = getTableRowData(ratesDt, target) as any
			editRate({ rate_id })
		})

		$('#rateEditAgeRow').empty()
		const costsForm = ages.map(({ age_id, age_name }) => {
			const $group = getTemplates().find('#rateEditAmountGroup').clone()
			const $label = $group.find('#rateEditAmountLabel')
			const $input = $group.find('#rateEditAmount')
			const htmlId = `#rateEditAmount${age_id}`
			$group.prop('id', `#rateEditAmountGroup${age_id}`)
			$label.removeProp('id').prop('for', htmlId).text(age_name)
			$input.prop('id', htmlId)
			inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
			$('#rateEditAgeRow').append($group)

			return {
				$: $input,
				source: age_id,
			}
		})

		let codesForm = []
		if (isMultiple) {
			$('#rateEditServiceContainer').show()
			$('#rateEditAgeCodeRow').empty()
			codesForm = ages.map(({ age_id, age_name }) => {
				const $group = getTemplates().find('#rateEditCodeGroup').clone()
				const $label = $group.find('#rateEditCodeLabel')
				const $input = $group.find('#rateEditCode')
				const htmlId = `#rateEditCode${age_id}`
				$group.prop('id', `#rateEditCodeGroup${age_id}`)
				$label.removeProp('id').prop('for', htmlId).text(age_name)
				$input.prop('id', htmlId)
				inputNum({ $input, min: 0, step: 0, decimals: 0 })
				$('#rateEditAgeCodeRow').append($group)

				return {
					$: $input,
					source: age_id,
				}
			})
		} else {
			$('#rateEditServiceContainer').hide()
		}

		const rateForm = [
			{
				$: $('#rateEditName'),
				source: 'rate_name',
			},
			{
				$: $('#rateEditPeriod'),
				source: 'rate_period',
			},
			{
				$: $('#rateEditStartDate'),
				source: 'rate_startdate',
			},
			{
				$: $('#rateEditEndDate'),
				source: 'rate_enddate',
			},
			{
				$: $('#rateEditFileDate'),
				source: 'rate_filedate',
			},
			{
				$: $('#rateEditDueDate'),
				source: 'rate_duedate',
			},
		]

		$('body').off('click').on('click', '#ratesSpecialCollapse', () => $('#rateSpecial').slideToggle(slideSpeed));

		const resetRate = () => {
			$('#rateEditForm').trigger('reset')
			$('#rateEditSave').off('click')
			$('#rateEditDelete').hide().off('click')
			$('#rateSpecialDelete').hide().off('click')
			$('#rateSpecial').slideUp(slideSpeed)
		}

		const closeRate = () => $('#rateEdit').slideUp(slideSpeed, () => resetRate())

		const downloadRate = async () => {
			const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
			post('/tables/rates', { prog_id: prog_id, mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields) })
		}

		type age = {
			age_id: number,
			age_name: string,
		}

		//special rate age toggle
		try {
			let { ages } = await fetchPost('/reporting/submit', { type: 'get_normal_ages', data: prog_id })
			let $ageSelect: any
			if (ages && ages.length > 0) {
				$ageSelect = $(
					<div>
						<label>Age</label>
						<select id='rateEditAgeSelect' className="form-control chosen">
							<optgroup>
								{ages.map((age: age) => (
									<option key={age.age_id} value={age.age_id}>
										{`${age.age_name}`}
									</option>
								))}
							</optgroup>
						</select>
					</div>
				);
			}
			$('#rateEditSpecialAge').html($ageSelect as any)
		} catch (error) {
			console.error(error)
		} finally {
			$('#assessFilterPeriod').prop('disabled', false)
		}

		const editRate = async ({ rate_id = null, rateData = null }: { rate_id?: number | null, rateData?: any, specialData?: any }) => {
			resetRate()
			$('#rateEdit').slideDown(slideSpeed)

			if (rate_id) {
				const postData = {
					rate_id: rate_id,
					prog_id: prog_id
				}
				rateData = await fetchPost('/reporting/submit', { type: 'rate_edit', data: postData })
			}
			if (rateData) {
				const costsParsed = {}
				Object.entries(rateData.costs).forEach(([id, amount]) => costsParsed[id] = amount as number / 100)
				setForm(rateForm, rateData.rate)
				setForm(costsForm, costsParsed)

				if (rateData.special) {
					$('#rateSpecial').slideDown(slideSpeed)
					$('#ratesSpecialCollapse').prop('checked', true)

					const rateEditAgeSelect = await waitForElement('#rateEditAgeSelect') as $
					rateEditAgeSelect.val(rateData.special.age_id).trigger('chosen:updated')
					$('#rateSpecialName').val(rateData.special.rate_name)
					$('#rateSpecialStartDate').val(rateData.special.rate_startdate)
					$('#ratespecialEndDate').val(rateData.special.rate_enddate)
					$('#rateSpecialDueDate').val(rateData.special.rate_duedate)
					$('#rateSpecialFileDate').val(rateData.special.rate_filedate)
					$('#rateSpecialAmount').val(Number(rateData.special_cost.cost_amount) / 100)

					$('#rateSpecialDelete').show().off('click').on('click', async () => {
						try {
							const res = await fetchPost('/reporting/submit', { type: 'delete_rate', data: rateData.special.rate_id })
							if (res.rc != 'OK') throw res.rc

							closeRate()
							reloadRatesDt()
							displayNotification('Deleted rate', 'success')
						} catch (error) {
							console.error(error)
							if (error && error === 'IN_USE') displayNotification('Rate is used by a filing and cannot be deleted', 'danger')
							else displayNotification('Could not delete rate', 'danger')
						}
					})
				}
			}

			const showRatePreview = async (data, is_insert) => {
				const rate = data.rate
				const isMultiple = data.isMultiple

				const updateValidRatePrograms = async (program_id) => {
					try {
						const res = await fetchPost('/support/submit', { type: 'get_valid_rate_programs', data: { program_id: program_id } })
						if (res.rc != 'OK') throw res.rc
						console.log('res', res)
						displayPrograms(res.programs)
					} catch (error) {
						console.log(error)
						displayNotification('Error, could not retrieve valid programs', 'danger')
					}
				}

				type program = { [id: string]: string }
				const displayPrograms = (programs: program) => {
					$('#ratepreviewProgList').empty()
					if (programs == null) return $('#ratepreviewProgList').append(`<li class="list-group-item">No programs available</li>`)

					for (let [id, name] of Object.entries(programs)) {
						$('#ratepreviewProgList').append(`
							<li data-program="${id}" style='cursor:pointer;' class="list-group-item d-flex justify-content-between align-items-center">
								<span>${name}</span>		
								<i style="cursor: pointer !important;" class="fa-solid text-success fa-check payerPrevTrash"></i>
							</li>
						`);
					}
				}

				let previewForm = null
				const preview = new Offcanvas($('#full-screen-rate') as any)
				preview.show()
				if (!isMultiple) {
					$('#ratePreviewSingleContainer').show()
					$('#ratePreviewMultiContainer').hide()
					$('#ratePreviewName').val(`${rate.rate_period}`)
					$('#ratePreviewDescription').val(rate.rate_name)

					//single age
					const cost = Number(Object.values(data.costs)[0])
					$('#ratePreviewCost').val(cost / 100)
				} else {
					$('#ratePreviewSingleContainer').hide()
					$('#ratePreviewMultiContainer').show()

					$('#ratePreviewRow').empty()
					previewForm = data.qb_rate.map((rate) => {
						const $templateClone = getTemplates().find('#ratePreviewTemplate').clone()

						// Update the IDs and labels for the name field
						const $nameInput = $templateClone.find('#ratePreviewMultiName')
						const nameId = `ratePreviewMultiName${rate.age_id}`
						$nameInput.prop('id', nameId)
						$templateClone.find('label[for="ratePreviewMultiName"]').prop('for', nameId)

						// Update the IDs and labels for the description field
						const $descriptionInput = $templateClone.find('#ratePreviewMultiDescription')
						const descriptionId = `ratePreviewMultiDescription${rate.age_id}`
						$descriptionInput.prop('id', descriptionId)
						$templateClone.find('label[for="ratePreviewMultiDescription"]').prop('for', descriptionId)

						// Update the IDs and labels for the age amount field
						const $ageInput = $templateClone.find('#ratePreviewAgeAmount')
						const ageId = `ratePreviewAgeAmount${rate.age_id}`
						$ageInput.prop('id', ageId)
						$templateClone.find('label[for="ratePreviewAgeAmount"]').prop('for', ageId).text(`${rate.age_name} - Cost`)

						// Set the values for each input
						$nameInput.val(rate.qb_name)
						$descriptionInput.val(rate.qb_description)
						$ageInput.val(rate.cost_amount / 100)

						$('#ratePreviewRow').append($templateClone)
						return {
							age_id: rate.age_id,
							name: $nameInput,
							description: $descriptionInput,
							ageAmount: $ageInput,
						}
					})
				}

				//update valid programs 
				updateValidRatePrograms(rate.rate_progid)

				//save customer data to quickbooks
				$('#ratePreviewSave').off('click').on('click', async (e) => {
					const $btn = $(e.currentTarget)
					buttonIsLoading($btn, true)
					const postData = {
						rate_id: rate.rate_id,
						rate_name: $('#ratePreviewName').val(),
						rate_description: $('#ratePreviewDescription').val(),
						rate_cost: $('#ratePreviewCost').val(),
						program: rate.rate_progid,
						is_insert: is_insert ? true : false,
						costs: isMultiple ? getFormByAgeId(previewForm) : null,
					}
					try {
						const res = await fetchPost('/support/submit', { type: 'save_rate_preview', data: postData })
						if (res.rc != 'OK') throw res.rc

						displayNotification('Success uploading data', 'success')
						preview.hide()
					} catch (error) {
						if (error == 'COSTS_NOT_EQUAL') displayNotification('Error, costs must be equal for this program', 'danger')
						displayNotification('Failed to upload data', 'danger')
						console.log('error in rate preview save - ', error)
					} finally {
						buttonIsLoading($btn, false)
					}
				})

				$('#ratePreviewClose').off('click').on('click', () => preview.hide())
			}

			$('#genRateSettingOpen').off('click').on('click', async () => {
				const $modal = $('#genRateModal').modal('show')
				const program_id = $('#ratesPrograms').val()
				loadRateSettings($modal, program_id)

				const res = await fetchPost('/reporting/submit', { type: 'get_codes', data: { prog_id: program_id } })
				if (res.rc != 'OK') throw res.rc
				const codes = res.codes
				setForm(codesForm, codes)

				$('#genRateSubmit').off('click').on('click', async () => {
					const $spinner = $('#genRateSpinner').show()
					try {
						const postData = {
							rs_progid: prog_id,
							rs_startmonth: $('#genRateStartMonth').val(),
							rs_openmonth: $('#genRateMonthsOpen').val(),
							rs_opendays: $('#genRateDaysOpen').val(),
							rs_ismultiyear: $('#genRateMultiYear').is(':checked') ? 1 : 0,
							codes: getForm(codesForm),
						}
						const res = await fetchPost('/reporting/submit', { type: 'genrate_settings_save', data: postData })
						if (res.rc != 'OK') throw res.rc

						$modal.modal('hide')
						setTimeout(() => displayNotification('Saved Generation Rate Settings', 'success'), 500)
					} catch (error) {
						console.error(error)
						displayNotification('Failure Saving Generate Rate Settings', 'danger')
					} finally {
						$spinner.hide()
					}
				})
			})

			const loadRateSettings = async (modal, program) => {
				const $spinner = $('#genRateLoadingSpinner').show()
				const $content = $('#genRateForm').hide()
				const $title_placeholder = $('#title_placeholder').show()
				const $title_text = $('#title_text').hide()

				try {
					const postData = { prog_id: program }
					const res = await fetchPost('/reporting/submit', { type: 'genrate_settings_load', data: postData })
					if (res.rc != 'OK') throw res.rc
					const settings = res.settings

					if (settings.rs_startmonth == null) settings.rs_startmonth = MONTH_JAN

					const progName = settings.prog_name
					$title_placeholder.hide()
					$title_text.text(`${progName} Rate Settings`).show()

					$('#genRateStartMonth').val(settings.rs_startmonth).trigger('chosen:updated')
					$('#genRateMonthsOpen').val(settings.rs_openmonth)
					$('#genRateDaysOpen').val(settings.rs_opendays)
					$('#genRateMultiYear').prop('checked', settings.rs_ismultiyear == RATE_MULTIYEAR)

				} catch (error) {
					modal.modal('hide')
					setTimeout(() => displayNotification('Failure Loading Generate Rate Settings', 'danger'), 500)
				} finally {
					$spinner.hide()
					$content.show()
				}
			}

			// let serviceWarning = false
			// $('.rateEditServiceCode').off('change').on('change', () => {
			// 	if(!serviceWarning) {
			// 		displayNotification('Warning, changing service codes', 'warning')
			// 		serviceWarning = true
			// 	}
			// })

			$('#rateEditSave').off('click').on('click', async () => {
				try {
					let specialData;
					if ($('#ratesSpecialCollapse').is(":checked")) {
						specialData = {
							age: $('#rateEditAgeSelect').val(),
							rate_name: $('#rateSpecialName').val(),
							rate_start: $('#rateSpecialStartDate').val(),
							rate_end: $('#ratespecialEndDate').val(),
							rate_due: $('#rateSpecialDueDate').val(),
							rate_amt: Number($('#rateSpecialAmount').val()) * 100,
							rate_file: $('#rateSpecialFileDate').val(),
						}
					}
					$('#rateEditSave').prop('disabled', true)

					//get costs 
					const costs = getForm(costsForm)
					Object.entries(costs).forEach(([id, amount]) => costs[id] = amount as number * 100)
					const postData = {
						rate_id,
						prog_id,
						rate: getForm(rateForm),
						costs,
						special: specialData ? specialData : null,
					}

					const { rc, show_preview, is_insert, return_rateid, is_multiple, qb_data } = await fetchPost('/reporting/submit', { type: 'update_rate', data: postData })
					if (rc != "OK") throw rc

					displayNotification('Saved rate information')
					closeRate()
					reloadRatesDt()

					if (show_preview) {
						postData.rate['rate_progid'] = prog_id
						postData.rate['rate_id'] = return_rateid
						const previewData = {
							rate: postData.rate,
							costs: postData.costs,
							isMultiple: is_multiple,
							qb_rate: qb_data,
						}

						setTimeout(() => showRatePreview(previewData, is_insert), 1000)
					}

				} catch (error) {
					if (error == 'INVALID_DATE') return displayNotification('Invalid date', 'danger')
					displayNotification('Could not save rate information', 'danger')
				} finally {
					$('#rateEditSave').prop('disabled', false)
				}
			})

			if (rate_id) {
				$('#rateEditDelete').show().on('click', async () => {
					try {
						$('#rateEditDelete').prop('disabled', true)
						const res = await fetchPost('/reporting/submit', { type: 'delete_rate', data: rate_id })
						if (res.rc != 'OK') throw res.rc

						closeRate()
						reloadRatesDt()
						displayNotification('Deleted rate', 'success')
					}
					catch (error) {
						if (error && error === 'IN_USE') displayNotification('Rate is used by a filing and cannot be deleted', 'danger')
						else displayNotification('Could not delete rate', 'danger')
					} finally {
						$('#rateEditDelete').prop('disabled', false)
					}
				})
			}
		}

		const addRate = async () => {
			$('#rateEdit').slideDown(slideSpeed)
			$('#rateEditSave').show()
			const postData = {
				insert: false,
				prog_id: prog_id
			}
			try {
				const rateData = await fetchPost('/reporting/submit', { type: 'generate_rate', data: postData })
				editRate({ rateData })

			} catch (error) {
				displayNotification('Failure Generating Rate', 'danger')
			}
		}

		$('#rateEditClose').on('click', () => closeRate())

		return ratesDt
	}

	$('#ratesPrograms').off('change').on('change', async () => initRates())
	$('#periodPrograms').off('change').on('change', async () => initPeriods())

	let periodDt: DataTables.Api | null = null
	const $periodsTableTemplate = $('#periodsTable').remove()
	const initPeriods = async () => {

		let prog_id = $('#periodPrograms').val()
		const { ages } = await fetchPost('/reporting/submit', { type: 'get_ages', data: prog_id })
		const ageCols = ages.map(({ age_id, age_name }) => ({
			data: `age${age_id}`,
			header: age_name,
		}))

		const getperiodFilters = () => ({
			program: $('#periodFilterProg').val(),
			payer: $('#periodFilterPayer').val(),
			status: $('#periodFilterStatus').val(),
		})

		setCollapse({ $btn: $('#periodFiltersCollapse'), $target: $('#periodFilters') })

		const initperiodDt = () => {
			const columnsInfo = [
				{
					data: 'rate_id',
					header: 'Rate id',
					visible: false,
				},
				{
					data: 'prog_name',
					header: 'Program Name',
				},
				{
					data: 'rate_name',
					header: 'Rate Name',
				},
				{
					data: 'rate_period',
					header: 'Period',
				},
				...ageCols,
				{
					data: 'file_assesspretty',
					header: 'Assessment',
				},
				{
					data: 'file_int',
					header: 'Interest',
				},
				{
					data: 'file_amt',
					header: 'Total Amount',
				},
			]

			// Conditionally add age_total entry
			if (prog_id == PROG_WAPAL) {
				columnsInfo.splice(columnsInfo.length - 3, 0, {
					data: 'age_total',
					header: 'Total Age Count',
					visible: true
				});
			}

			const headers = columnsInfo.map((col) => col.header)
			if (periodDt != null) {
				periodDt.destroy()
				$('#periodsTableContainer').empty()
			}
			const $periodsTable = $periodsTableTemplate.clone()
			$('#periodsTableContainer').append($periodsTable)
			periodDt = $periodsTable.tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/periods',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getperiodFilters(),
						id: prog_id
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				buttons: [
					{
						text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
						className: ' ms-1 download btn btn-sm btn-primary',
						action: () => downloadPeriods(),
					},
				],
				dom: datatablesButtonDom,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No periods to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> periods",
					infoEmpty: "Showing 0 to 0 of 0 periods",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total contact)",
					lengthMenu: "Show _MENU_ periods",
				},
			})

			$('#periodsTableContainer').find('th').addClass('custom-header')

			const closePeriod = () => $('#periodEdit').slideUp(slideSpeed, () => resetPeople())

			const downloadPeriods = async () => {
				const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
				post('/tables/periods', { filters: getperiodFilters(), id: prog_id, mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields) })
			}

			$('#periodEditClose').on('click', () => closePeriod())

			return periodDt
		}

		initperiodDt()
	}

	if ($('#reportingContainer').length) {
		initpayers()
		initRates()
		initAssessments()
		initPeriods()
	}
}

async function initPayerAssessment() {

	type payer = {
		payer_id: number,
		payer_status: number,
		payer_siteid: number,
		payer_name: string,
		payer_address1: string,
		payer_address2: string,
		payer_city: string,
		payer_state: string,
		payer_zip: string,
		payer_naic: string,
		payer_group: string,
		payer_fein: number
		payer_special: number
	}

	const { payer, programs } = await fetchPost('/data/submit', { type: 'get_payer' })
	if (payer) {
		const ZERO_ACTIVE = 1;
		if (payer.payer_zero && payer.payer_zero == ZERO_ACTIVE) {
			$('#payer_zero').prop('checked', true)
		} else {
			$('#payer_zero').prop('checked', false)
		}

		$('#payer_name').val(payer.payer_name)
		$('#payer_address1').val(payer.payer_address1)
		$('#payer_address2').val(payer.payer_address2)
		$('#payer_city').val(payer.payer_city)
		$('#payer_state').val(payer.payer_state).trigger('chosen:updated')
		$('#payer_zip').val(payer.payer_zip)
		$('#payer_fein').val(payer.payer_fein)
		$('#payer_group').val(payer.payer_group)
		$('#payer_naic').val(payer.payer_naic)
		$('#payer_auth_program').append(programs)
	}

	const updateAssessLang = async () => {
		try {
			const { rc, multiple, program } = await fetchPost('/data/submit', { type: "get_file_info" })
			console.log('this is the program', program)
			if (rc != "OK") throw rc

			if (multiple) {

				if (program == PROG_WAPAL) { //custom wapal wording
					$('#file_assess_lang').html('To remain in compliance with reporting, please complete <strong style="text-decoration: underline !important;">ALL</strong> filings available, including the training period. Enter zero for any quarters where there were no covered lives.').addClass('text-danger mb-0').css('font-size', 'larger').removeClass('text-success')
				} else {
					$('#file_assess_lang').html('You currently have assessments due. Please complete these filings as soon as possible.').addClass('text-danger mb-0').css('font-size', 'larger').removeClass('text-success')
				}

			} else {
				console.log('this is the program', program)
				$('#file_assess_lang').html('You have no assessments due.').addClass('text-success mb-0').css('font-size', 'larger').removeClass('text-danger')
			}

		} catch (error) {
			console.error('updateAssessLang', error)
		}
	}

	$("#payer_save").on("click", async () => {
		const $spinner = $('#payer_save_spinner').show()

		const data = {
			payer_zero: $('#payer_zero').is(':checked') ? 1 : 0,
			payer_name: $('#payer_name').val(),
			payer_address1: $('#payer_address1').val(),
			payer_address2: $('#payer_address2').val(),
			payer_city: $('#payer_city').val(),
			payer_state: $('#payer_state').val(),
			payer_zip: $('#payer_zip').val(),
			payer_naic: $('#payer_naic').val(),
			payer_group: $('#payer_group').val(),
			payer_fein: $('#payer_fein').val(),
		}

		if (data.payer_name === "" ||
			data.payer_name == null ||
			data.payer_address1 === "" ||
			data.payer_address1 == null ||
			data.payer_city === "" ||
			data.payer_city == null ||
			data.payer_state === "" ||
			data.payer_state == null ||
			data.payer_zip === "" ||
			data.payer_zip == null ||
			data.payer_fein === "" ||
			data.payer_fein == null) {
			displayNotification("Please Fill out the required fields", "danger")
			return
		}

		try {
			const res = await fetchPost('/form/submit', { type: 'payer_settings_save', data: data })
			if (res.rc != 'OK') throw res.rc
			displayNotification("Payer Saved Successfully", "success")
			filePeriodDt.ajax.reload(undefined, false) // reload incase of perm zero
			updateAssessLang()
		} catch (e) {
			displayNotification("Error Saving this Payer", "danger")
			console.error('payersubmit', e)
		} finally {
			$spinner.hide()
		}
	})

	$("#admin_archive").hide()
	$("#nav_contact").show()
	$("#nav_filing").show()

	const getPrograms = async () => {
		try {
			let data = { type: "get_programs" }
			const res = await fetchPost('/data/submit', data)
			return res
		} catch (e) {
			console.log(e)
		}
	}

	const initContDt = () => {
		const columnsInfo = [
			{
				data: 'user_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'user_role',
				header: 'role',
				visible: false,
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last Name',
			},
			{
				data: 'user_rolepretty',
				header: 'Role',
			},
			{
				data: 'user_position',
				header: 'Position',
			},
			{
				data: 'user_phone',
				header: 'Phone',
			},
			{
				data: 'user_fax',
				header: 'Fax',
			},
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_status',
				header: 'Status',
				visible: false,
			},
			{
				header: 'Actions',
				data: '',
				fieldId: 'userFieldActions',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm">Show More</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const contDt = $('#contactTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/contact',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					id: payer.payer_id
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			responsive: {

				details: false,
			},
			language: {
				emptyTable: "No contacts to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> contacts",
				infoEmpty: "Showing 0 to 0 of 0 contacts",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total contacts)",
				lengthMenu: "Show _MENU_ contacts",
			},
			initComplete: () => {
				const $scroll = $('#contactTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#contactTableContainer').find('th').addClass('custom-header')

		$('#contactTableContainer').off("click").on('click', '.show-edit', ({ target }) => {
			let row = contDt.row($(target).closest('tr')).data()
			contactView(row)
		})

		return contDt
	}
	let contDt = initContDt()

	const initReqcontactDt = () => {
		const columnsInfo = [
			{
				data: 'req_id',
				visible: false,
			},
			{
				data: 'req_email_recipient',
				header: 'Requested User',
			},
			{
				data: 'req_rolepretty',
				header: 'Role',
			},
			{
				data: 'req_typepretty',
				header: 'Type',
			},
			{
				data: 'req_datepretty',
				header: 'Date Submitted',
			},
			{
				data: 'req_statuspretty',
				header: 'Status',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const reqContDt = $('#reqcontactTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/requests',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					id: payer.payer_id,
					mode: REQUEST_FILING
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No Requests to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> requests",
				infoEmpty: "Showing 0 to 0 of 0 requests",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total request)",
				lengthMenu: "Show _MENU_ request",
			},
			initComplete: () => {
				const $scroll = $('#reqcontactTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#reqcontactTableContainer').find('th').addClass('custom-header')

		$('#reqcontactTableContainer').off("click").on('click', '.show-edit', ({ target }) => {
			let row = reqContDt.row($(event.target).closest('tr')).data()
			contactView(row)
		})

		return reqContDt
	}
	let reqContactDt = initReqcontactDt()

	const clearForm = () => {
		$("#requser_form :input").val('')
		$('#requser_container').collapse('hide')
		$('#requser_send').hide()
		$('#requser_cancel').hide()
		$('#assessment_requser').show()
	}

	//find programs associated with current user
	let programAccess = await getPrograms()
	$("#requser_program_container").hide()
	$("#requser_add_programs").hide()
	if (programAccess.length > 1) {
		$("#requser_program_container").show()
		$("#requser_add_programs").show()
	}

	$('#assessment_requser').off('click').on('click', (event) => {
		event.preventDefault()
		$('#requser_container').collapse('show')
		$('#requser_send').show()
		$('#requser_cancel').show()
		$('#assessment_requser').hide()
	})

	$('#requser_send').off('click').on('click', async (event) => {
		event.preventDefault()
		try {
			const postData = {
				email: $('#requser_email').val(),
				reason: $('#requser_reason').val(),
				programs: $('#requser_program').val(),
				role: $('#requser_role').val(),
				type: REQ_ADD_USER,
			}
			const res = await fetchPost('/form/submit', { type: "request_send", data: postData })
			if (res.rc != 'OK') throw res.rc

			clearForm()
			displayNotification("Request Submitted Successfully", "success")
			reqContactDt.ajax.reload(undefined, false)
		} catch (e) {
			switch (e) {
				case 'ALREADY_REQUESTED':
					displayNotification('This User Needs to Register Before Adding Additional Authorizations.', 'danger')
					break
				default:
					displayNotification('Error Sending Request.', 'danger')
					console.error('request_send', e)
			}
		}
	})

	$('#requser_cancel').off('click').on('click', (event) => {
		clearForm()
	})

	const contactView = async (contact) => {
		if (!contact) {
			displayNotification('Error retrieving contact', 'danger')
			return
		}
		$('#request_show').show()
		$('#requser_contact_show').collapse('show')

		const { programs } = await fetchPost('/data/submit', { type: 'get_program_names', data: contact.user_id })
		$('#request_contact_programs').html(programs)
		$('#request_contact_name').html(htmlEsc(`${contact.user_first} ${contact.user_last}`))
		$('#request_contact_email').html(htmlEsc(contact.user_email))
		$('#request_contact_role').html(htmlEsc(contact.user_rolepretty))
		$('#request_contact_position').html(htmlEsc(contact.user_position))
		$('#request_contact_phone').html(htmlEsc(contact.user_phone))

		$('#requser_delete').off('click').on('click', async (event) => {
			event.preventDefault()
			try {
				const postData = {
					email: contact.user_email,
					programs: $('#requser_program').val(),
					role: $('#requser_role').val(),
					type: REQ_DELETE_USER,
				}
				const res = await fetchPost('/form/submit', { type: "request_send", data: postData })
				if (res.rc != "OK") throw res.rc

				clearForm()
				$('#requser_contact_show').collapse('hide')
				displayNotification("Request Submitted Successfully", "success")
			} catch (e) {
				switch (e) {
					case 'USER_EXISTS':
						displayNotification('This User Already Exists.', 'danger')
						break
					default:
						displayNotification('Error Sending Request.', 'danger')
						console.error('request_send', e)
				}
			}
		})
	}

	$('#requser_contact_close').off('click').on('click', (event) => {
		event.preventDefault()
		$('#requser_contact_show').collapse('hide')
	})

	const initFilePeriodDt = () => {
		const columnsInfo = [
			{
				data: 'rate_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'prog_name',
				header: 'Program',
			},
			{
				data: 'rate_name',
				header: 'Period',
			},
			{
				data: 'rate_startpretty',
				header: 'Period Start',
			},
			{
				data: 'rate_endpretty',
				header: 'Period End',
			},
			{
				data: 'rate_filepretty',
				header: 'File Date',
			},
			{
				data: 'rate_duepretty',
				header: 'Due Date',
			},
			{
				header: 'Actions',
				data: '',
				fieldId: 'userFieldActions',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm">Start Filing</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const filePeriodDt = $('#filePeriodTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/file_periods',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'desc']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No periods to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> period data",
				infoEmpty: "Showing 0 to 0 of 0 periods",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total period)",
				lengthMenu: "Show _MENU_ period",
			},
		})

		$('#filePeriodTableContainer').find('th').addClass('custom-header')

		$('#filePeriodTableContainer').off("click").on('click', '.show-edit', ({ target }) => {
			let row = filePeriodDt.row($(event.target).closest('tr')).data()
			editFiling(row)
		})

		return filePeriodDt
	}
	let filePeriodDt = initFilePeriodDt()

	const initFilingHistoryDt = () => {
		const columnsInfo = [
			{
				data: 'file_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'rate_name',
				header: 'Period',
			},
			{
				data: 'file_program',
				header: 'Reference #',
			},
			{
				data: 'file_assesspretty',
				header: 'Assessment Amount',
			},
			{
				data: 'file_int',
				header: 'Interest',
			},
			{
				data: 'file_overdue',
				header: 'Overdue',
			},
			{
				data: 'file_amt',
				header: 'Total Due',
			},
			{
				data: 'file_datepretty',
				header: 'Date Filed',
			},
			{
				data: 'file_reason',
				header: 'Reason',
			},
			{
				header: 'Actions',
				data: '',
				fieldId: 'userFieldActions',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm">Show More</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const filingDt = $('#filingTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/filing',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No filing data to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> filing data",
				infoEmpty: "Showing 0 to 0 of 0 filing data",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total filing)",
				lengthMenu: "Show _MENU_ filing",
			},
		})
		$('#filingTableContainer').find('th').addClass('custom-header')

		$('#filingTableContainer').off("click").on('click', '.show-edit', ({ target }) => {
			let row = filingDt.row($(event.target).closest('tr')).data()
			viewFiling(row)
		})

		return filingDt
	}
	let filingHistoryDt = initFilingHistoryDt()

	const $template = $('.file_hist_template').remove()
	const viewFiling = async (file) => {
		if (!file) {
			displayNotification('No File', 'danger')
			return
		}

		$('#file_hist_age_container').empty()
		const { count } = await fetchPost('/data/submit', { type: "display_filing", data: file.file_id })
		if (count) {
			count.forEach(c => {
				const $clone = $template.clone()
				$clone.find('.file_hist_count_label').html(`${c.age_name}`)
				$clone.find('.file_hist_count').html(`${c.total_count_amount} lives * ${formatter.format(c.cost_amount / 100)} = ${formatter.format((c.cost_amount * c.total_count_amount) / 100)}`)
				$('#file_hist_age_container').append($clone)
			})
		}

		$('#filing_modal').modal('show')
		$('#file_hist_payer').html(htmlEsc(file.payer_name))
		$('#file_hist_date').html(htmlEsc(file.file_datepretty))
		$('#file_hist_reference').html(htmlEsc(file.file_program))
		$('#file_hist_fein').html(file.payer_fein)
		$('#file_hist_overdue').html(htmlEsc(`${file.file_overdue} days`))
		$('#file_hist_interest').html(htmlEsc(formatter.format(file.file_interest / 100)))
		$('#file_hist_assessment').html(htmlEsc(formatter.format(file.file_assessment / 100)))
		$('#file_hist_amtdue').html(htmlEsc(formatter.format(file.file_amount / 100)))
		$('#file_hist_reason').html(htmlEsc(file.file_reason))

		$('#print_history_modal').off('click').on('click', async (event) => {
			const $btn = $(event.target)
			const $tab = $btn.closest('#file_container')

			try {
				$('#print_hist_spinner').show()
				$('#print_hist_spinner').prop('disabled', true)

				const postData = {
					file_id: file.file_id,
					rate_id: file.file_rateid,
					program_id: file.rate_progid
				}
				const { rc, data, ages, logo_guid } = await fetchPost('/data/submit', { type: 'print_reporting', data: postData })
				if (rc != 'OK') return

				//get logo
				const response = await fetch(`/data/get_logo/${logo_guid}`, { method: 'GET' })
				const json = await response.json(); // Parse the JSON body
				console.log(json)

				const url = json.url
				const image = document.createElement('img')
				image.style.maxHeight = '150px'
				image.style.maxWidth = '350px'
				image.src = url

				let $tabClone = $tab.clone()
				$tabClone.find('#print_filehistory_logo').show()
				$tabClone.find('#print_history_close').remove()
				$tabClone.find('#print_history_modal').remove()
				$tabClone.find('#file_hist_content').empty()
				$tabClone.find('#print_hist_spinner').hide()

				//header
				$tabClone.find('#file_hist_logo_container').append(image)
				$tabClone.find('#file_hist_logo_container').append(`
					<div style='text-align: center; margin-top: 2rem;'>
					<p class='print_text'>${data.print_heading}</p></div>`
				)
				$tabClone.find('#file_hist_content').prepend(`
					<div style='text-align: end;' class=' print_text'>
						<p>
							Filed: &nbsp ${data.filing.file_duepretty}</br>
							Reference: &nbsp ${data.filing.file_program}
						</p>
					</div>
					<div style='display: flex; justify-content: center; margin-top: .5rem;' class='print_text''>
						<div style='text-align: end; margin-top: 0;' class='text-center'>
							<p style='font-size:16px !important; margin-top: 0;'><b>${data.program} Remittance Form</b></br>
						</div>
					</div>
					<div class='print_text'>
						<p>
							Report for: ${data.rate.rate_name}<br>
							Due: ${data.rate.rate_duepretty}<br>
							Federal EIN: ${data.payer.payer_fein}</br>
							Company Name: ${data.payer.payer_name}
						</p>
					</div>
				`)

				//calc table
				$tabClone.append(`
					<div class='row container-fluid print_texts'> 
						<table class="table table-borderless">
							<thead style='border-bottom: 2px solid black;'>
								<tr>
									<th style='border-bottom: 1px solid black;' scope="col"></th>
									<th style='border-bottom: 1px solid black;' scope="col"><b>Lives</b></th>
									<th style='border-bottom: 1px solid black;' scope="col"><b>Rate</b></th>
									<th style='border-bottom: 1px solid black;' scope="col"><b>Assessment</b></th>
								</tr>
							</thead>
							<tbody class='table-group-divider' id='print_body'>
							</tbody>
						</table>
					</div>
				`)
				ages.forEach(age => {
					$tabClone.find('#print_body').append(`
						<tr>
							<td style='margin-bottom: 2rem;' scope="row">${data.program} Resident ${age.age_name} Covered Lives Reported:</td>
							<td style='margin-bottom: 2rem;'>${Number(age.total_count_amount).toLocaleString('en-US') ?? 0}</td>
							<td style='margin-bottom: 2rem;'>${formatter.format(age.cost_amount / 100)}</td>
							<td style='margin-bottom: 2rem; text-align: end;'>${formatter.format((age.total_count_amount * age.cost_amount) / 100)}</td>
						</tr>
					`)
				});

				if (data.rate.rate_progid != PROG_WAPAL) {
					if (data.filing.file_adjustment && (Number(data.filing.file_adjust_type) || Number(data.filing.file_adjust_type) === 0)) { //adjustment
						const type = data.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
						const operation = data.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
						let total_before_adjustment = 0;
						ages.forEach(age => {
							total_before_adjustment += ((age.total_count_amount * age.cost_amount) / 100)
						});

						$tabClone.find('#print_body').append(`
							<tr>
								<td class='print_text' scope="row">Asessment due</td>
								<td></td>
								<td></td>
								<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
							</tr>
							<tr>
								<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
								<td></td>
								<td></td>
								<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
							</tr>
							<tr>
								<td><p class='print_text'>Adjustment ${type}:</p></td>
								<td></td>
								<td></td>
								<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${operation} ${formatter.format(data.file_adjustment / 100)} </p></td>
							</tr>
							<tr>
								<td class='print_text'><b>Total Due</b></td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
							</tr>
						`)

					} else {

						$tabClone.find('#print_body').append(`
							<tr>
								<td class='print_text' scope="row">Asessment due</td>
								<td></td>
								<td></td>
								<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
							</tr>
							<tr>
								<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
								<td></td>
								<td></td>
								<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
							</tr>
							<tr>
								<td class='print_text'><b>Total Due</b></td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
							</tr>

						`)

					}
				} else { //wapal custom body
					if (data.file_adjustment && data.file_adjust_type) { //adjustment
						const type = data.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
						const operation = data.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
						let total_before_adjustment = 0;
						ages.forEach(age => {
							total_before_adjustment += (Number(age.total_count_amount) * Number(age.cost_amount))
						});

						$tabClone.find('#print_body').append(`
							<tr>
								<td class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style= 'text-align: end; border-bottom: black 1px solid; border-top: black 1px solid;'><strong>${formatter.format(total_before_adjustment / 100)}</strong></p></td>
							</tr>
							<tr>
								<td class='print_text'><b>Adjustment ${type} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style = 'text-align: end; margin-bottom: 0px;'><strong>${operation} ${formatter.format(data.file_adjustment / 100)}</strong></p></td>
							</tr>
							<tr>
								<td style='padding-right: 5rem; margin-top: 0px;' class='print_text'><b>Total Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
							</tr>
						`)
					} else {
						$tabClone.find('#print_body').append(`
						<tr>
							<td style='padding-right: 5rem;' class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
							<td></td>
							<td></td>
							<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
						</tr>
						`)
					}
				}


				//footer
				if (data.rate.rate_progid != PROG_MVB) {
					$tabClone.append(`
						<div><p> <strong>Please wire or ACH transaction payment in full as follows:</br></strong> ${data.print_wire}</p> </div>
						<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
							<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check} </a></b></p>
						</div>	
						<p style='margin-bottom: 0;' class='print_text mb-0'>mail all payments and correspondence to:</p>
						<div style='display:flex; justify-content: space-between;'>
							<div style='text-align: center'>
								<p><strong>USPS:</strong> </br>${data.print_usps}</p>
							</div>
							<div style='text-align: center;'>
								<p><strong>UPS/Fed Ex requiring a street address:</strong> </br>${data.print_ups}</p>
							</div>
						</div>
						<div style='text-align: center; margin-top: 2rem;'>
						<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>`
					)
				} else { //custom maine footer
					$tabClone.append(`
						<div style='display: flex; justify-content: space-between;'>
						<p><strong>ALL ACH GO TO TD BANK</strong></br>${data.print_ach}</p>
						<p style='text-align: left;'><strong>ALL WIRE GO TO TD BANK</strong></br>${data.print_wire}</p>
						</div>
						<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
							<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check}</a></b></p>
						</div>	
						<div style='display: flex; justify-content: space-between; text-align: center'>
							<div style='text-align: center'>
								<p style='text-align: center';><strong>ALL CHECKS AND REGULAR MAIL</strong></br>${data.print_usps}</p>
							</div>
							<div style='text-align: center'>
								<p style='text-align: center; '><strong>ALL, FEDEX, UPS, OVERNIGHTS</strong></br>${data.print_ups}</p>
							</div>
						</div>
						<div style='text-align: center; margin-top: 2rem;'>
						<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>
					`)
				}
				$tabClone.find('.print_modal').remove()

				//convert to html and generate pdf
				const html = $tabClone.html()
				post('/data/get_pdf', { html: html, program: data.print_progid })

			} catch (error) {
				displayNotification('Error Downloading Pdf', 'danger')
				console.error('File Print', error)
			} finally {
				$('#print_hist_spinner').hide()
				$('#print_hist_spinner').prop('disabled', false)
			}
		})
	}

	$('#file_exit_payer').on('click', () => post('/ui/payer_select'))

	$("#file_exit_payer").click(function () {
		if ($(this).is(":checked")) {
			$("#age_container").hide()
		} else {
			$("#age_container").show()
		}
	})

	$("#covered_lives").click(function () {
		if ($(this).is(":checked")) {
			$("#age_container").hide()
		} else {
			$("#age_container").show()
		}
	})

	$('#file_zeroLives').off("click").on('click', () => {
		if ($('#file_zeroLives').is(":checked")) {
			$('.age').prop('disabled', true)
			clearFiling(true)
		} else {
			$('.age').prop('disabled', false)
			$('#age_container').show()
			$('#file_chari_container').collapse('show')
		}
	})

	$('#file_exempt').off("click").on('click', () => {
		if ($('#file_exempt').is(":checked")) {
			$('#file_chari_container').collapse('hide')
		} else {
			$('#file_chari_container').collapse('show')
		}
	})

	const clearFiling = (chaRi: boolean = null) => {
		$("#file_form :input").val('')
		$(".file_questions").prop('checked', false)
		$('#file_total_lives').html(`0`)
		$('.cost_amount').html(`$0`)
		$('#file_total_interest').html(`$0`)
		$('#file_total_overdue').html(`0`)
		$('#file_total_amount').html(`$0`).addClass('text-warning')
		$('.adjustment').hide()

		if (chaRi) {
			$('#file_chari_container').collapse('hide')
			$("#file_chari_overdue").html('0')
			$("#file_chari_lives").html('0')
			$("#file_chari_interest").html('$0')
			$("#file_chari_amount").html('$0')
		}
	}

	//reset for adjustment testing
	$("#testing_reset").on("click", async ({ target }) => {
		try {
			$("#testing_reset_spinner").show()

			const { rc } = await fetchPost('/data/submit', { type: 'reset_test_periods' })
			if (rc != 'OK') throw rc

			displayNotification('Reset Testing', 'success')
			filePeriodDt.ajax.reload()
			filingHistoryDt.ajax.reload()
		} catch (err) {
			displayNotification('Failed to reset the testing period', 'danger')
			console.log(err)
		} finally {
			$("#testing_reset_spinner").hide()
		}
	})

	const $ageTemplate = $('.age_template').remove()
	const editFiling = async (file) => {
		$('#age_container').empty()
		$('#file_home_container').hide()
		$('#filing_container').fadeIn(200)

		//set headers
		const res = await fetchPost('/data/submit', { type: 'get_payer' })
		const payer = res.payer

		$('#file_heading_payer').html(`<strong>PAYER : ${payer.payer_name}</strong>`)
		$('#file_heading_fein').html(`<strong>FEIN : ${payer.payer_fein}</strong>`)
		$('#file_heading_period').html(`<strong>PERIOD : ${file.rate_name}</strong>`)
		$('#file_heading_ratedue').html(`<strong>DUE : ${file.rate_duepretty ?? 'none'}</strong>`)
		setCollapse({ $btn: $('#printmodal_external_switch'), $target: $('#print_modal_collapse') })
		interface Age {
			age_id: number | null
			age_name: string
			age_amount: number
			cost_amount: number
			age_month: number
		}
		const ageMap: Map<HTMLElement, Age> = new Map()
		const addAge = (age, months) => {
			const $age = $ageTemplate.clone()
			$age.find('.age_label').text(age.age_name)
			$age.find('.age_cost').text(`${formatter.format(age.cost_amount / 100)}`)

			const $inputCol = $age.find('.col-sm').eq(2);

			Object.entries(months).forEach(([monthName, monthValue]) => {
				let $newCol = $('<div class="col-sm"></div>');
				let $inputGroup = $('<div class="d-flex flex-column mx-1"></div>');
				let $input = $('<input type="number" class="form-control age" placeholder="# of lives">');
				let $label = $('<label></label>');

				$input.attr('id', `age_${monthValue}`);
				$label.text(`${monthName}`);

				$inputGroup.append($label);
				$inputGroup.append($input);

				$newCol.append($inputGroup);

				$inputCol.before($newCol);
			});
			$inputCol.remove();

			$age.attr('data-cost_name', age.age_name);
			ageMap.set($age[0], age);
			return $age;
		}

		if (file) {
			try {
				const { rc, ages, months } = await fetchPost('/data/submit', { type: 'get_ages', data: file.rate_id })
				if (rc != "OK") return
				ages.forEach((age) => $('#age_container').append(addAge(age, months)))
			} catch (e) {
				console.log(e)
			}
		}

		$('.age') //calculate table on every form change
			.off('change')
			.on('change', async (event) => {
				try {
					let $row = $(event.target).closest('.age_template')
					const ages: Age[] = []
					$('.age_template')
						.toArray()
						.forEach((element) => {
							const age = ageMap.get(element)
							if (!age) return
							const age_name = $(element).find('.age_label').text()

							let age_amount_sum = 0
							$(element).find('.age').each((_, inputElem) => {
								const age_amount = $(inputElem).val() as any
								const parsed_age_amount = age_amount === '' ? 0 : parseInt(age_amount)
								age_amount_sum += parsed_age_amount
							});
							ages.push({ ...age, age_name, age_amount: age_amount_sum })
						})

					const postData = {
						payer_id: payer ? payer.payer_id : null,
						ages,
						rate_id: file ? file.rate_id : null
					}
					const { rc, costs, totals, adjustment, is_chari } = await fetchPost('/data/submit', { type: "calc_costs", data: postData })
					if (rc != 'OK') return

					if (costs) {
						costs.forEach(c => {
							if (c.cost_name == $row.data('cost_name')) {
								$row.find('.cost_amount').html(`${formatter.format(c.cost_amount / 100)}`)
							}
						});
					}

					if (totals) {
						$('#file_total_lives').html(`${totals.total_lives}`)
						$('#file_total_overdue').html(`${totals.overdue} days`)
						$('#file_total_interest').html(`${formatter.format(totals.interest / 100)}`)
						$('#file_total_amount').html(`${formatter.format(totals.total_cost / 100)}`).addClass('text-warning')
					}

					if (adjustment.file_adjustment && adjustment.file_adjustment != 0) {
						$('.adjustment').show()

						if (adjustment.file_adjust_type == ADJUST_DEBIT) {
							$('#file_total_adjustment').html(`+ ${formatter.format(adjustment.file_adjustment / 100)}`).addClass('text-danger')
							$('#file_total_adjustreason').addClass('text-danger')
						}
						if (adjustment.file_adjust_type == ADJUST_CREDIT) {
							$('#file_total_adjustment').html(`- ${formatter.format(adjustment.file_adjustment / 100)}`).addClass('text-success')
							$('#file_total_adjustreason').addClass('text-success')
						}

						$('#file_total_adjustreason').html(`${adjustment.file_adjustreason ?? ''}`)
					} else {
						$('.adjustment').hide()
					}

					if (is_chari) {
						try {
							const { rc, total, cost } = await fetchPost('/data/submit', { type: "calc_chari", data: postData })
							if (rc != 'OK') throw rc

							$('#file_chari_lives').html(`${total.lives}`)
							$('#file_chari_overdue').html(`${totals.overdue} days`)
							$('#file_chari_cost').html(`${formatter.format(cost / 100)}`)
							$('#file_chari_amount').html(`${formatter.format(total.cost / 100)}`)
							$('#file_chari_interest').html(`${formatter.format(total.interest / 100)}`)

						} catch (error) {
							switch (error) {
								case 'NO_RATE':
									displayNotification('CHA-RI Rate Does Not Exist', 'danger')
									break;
								default:
									displayNotification('Error Calculating Cha-Ri Rate', 'danger')
									break;
							}
							console.error('Calculate Chari', error)
						}
					}

				} catch (e) {
					console.log(e)
				}
			})

		const showPrintModal = (file_id, rate_id) => {
			if (!file_id && !rate_id) return

			setTimeout(() => $('#printModal').modal('show'), 1000)

			$('#print_filing').off('click').on('click', async (event) => {
				const $btn = $(event.target)
				const $container = $btn.closest('#print_totals_container')

				try {
					$('#print_spinner').show()
					$('#print_spinner').prop('disabled', true)

					const post_data = {
						file_id: file_id,
						rate_id: rate_id
					}
					const { rc, data, ages } = await fetchPost('/data/submit', { type: 'get_print_info', data: post_data })
					if (rc != 'OK') return

					//get logo
					const response = await fetch(`/data/get_logo/${data.logo_guid}`, { method: 'GET' })
					const json = await response.json(); // Parse the JSON body
					console.log(json)

					const url = json.url
					const image = document.createElement('img')
					image.style.maxHeight = '150px'
					image.style.maxWidth = '350px'
					image.src = url

					let $tabClone = $container.clone()
					$tabClone.find('#print_filing').remove()
					$tabClone.find('#file_inputs').remove()
					$tabClone.find('#chari_totals_container').remove()
					$tabClone.find('#totals_heading').remove()
					$tabClone.find('#print_spinner').remove()
					$tabClone.find('#print_file_logo').show()
					$tabClone.find('#print_file_content').show()

					//header
					$tabClone.find('#file_logo_container').append(image)
					$tabClone.find('#file_logo_container').append(`
						<div style='text-align: center; margin-top: 2rem;'>
						<p class='print_text'>${data.print_heading}</p></div>`
					)
					$tabClone.find('#file_print_content').prepend(`
						<div style='text-align: end;' class=' print_text'>
							<p>
								Filed: &nbsp ${data.filing.file_duepretty}</br>
								Reference: &nbsp ${data.filing.file_program}
							</p>
						</div>
						<div style='display: flex; justify-content: center; margin-top: .5rem;' class='print_text''>
							<div style='text-align: end; margin-top: 0;' class='text-center'>
								<p style='font-size:16px !important; margin-top: 0;' ><b>${data.program} Remittance Form</b></br>
							</div>
						</div>
						<div class='print_text'>
							<p>
								Report for: ${data.rate.rate_name}<br>
								Due: ${data.rate.rate_duepretty}<br>
								Federal EIN: ${data.payer.payer_fein}</br>
								Company Name: ${data.payer.payer_name}
							</p>
						</div>
					`)

					//calc table
					$tabClone.append(`
						<div class='row container-fluid print_texts'> 
							<table class="table table-borderless">
								<thead style='border-bottom: 2px solid black;'>
									<tr>
										<th style='border-bottom: 1px solid black;' scope="col"></th>
										<th style='border-bottom: 1px solid black;' scope="col"><b>Lives</b></th>
										<th style='border-bottom: 1px solid black;' scope="col"><b>Rate</b></th>
										<th style='border-bottom: 1px solid black;' scope="col"><b>Assessment</b></th>
									</tr>
								</thead>
								<tbody class='table-group-divider' id='print_body'>
								</tbody>
							</table>
						</div>
					`)
					ages.forEach(age => {
						$tabClone.find('#print_body').append(`
							<tr>
								<td style='margin-bottom: 2rem;' scope="row">${data.program} Resident ${age.age_name} Covered Lives Reported:</td>
								<td style='margin-bottom: 2rem;'>${Number(age.total_count_amount).toLocaleString('en-US') ?? 0}</td>
								<td style='margin-bottom: 2rem;'>${formatter.format(age.cost_amount / 100)}</td>
								<td style='margin-bottom: 2rem; text-align: end;'>${formatter.format((age.total_count_amount * age.cost_amount) / 100)}</td>
							</tr>
						`)
					});
					if (data.rate.rate_progid != PROG_WAPAL) {
						if (data.filing.file_adjustment && (Number(data.filing.file_adjust_type) || Number(data.filing.file_adjust_type) === 0)) { //adjustment
							console.log('hitting here', data.filing.file_adjustment, data.filing.file_adjust_type)
							const type = data.filing.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
							const operation = data.filing.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
							let total_before_adjustment = 0;
							ages.forEach(age => {
								total_before_adjustment += ((age.total_count_amount * age.cost_amount) / 100)
							});

							$tabClone.find('#print_body').append(`
								<tr>
									<td class='print_text' scope="row">Asessment due</td>
									<td></td>
									<td></td>
									<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
								</tr>
								<tr>
									<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
									<td></td>
									<td></td>
									<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
								</tr>
								<tr>
									<td><p class='print_text'>Adjustment ${type}:</p></td>
									<td></td>
									<td></td>
									<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${operation} ${formatter.format(data.filing.file_adjustment / 100)} </p></td>
								</tr>
								<tr>
									<td class='print_text'><b>Total Due</b></td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
								</tr>
							`)

						} else {

							$tabClone.find('#print_body').append(`
								<tr>
									<td class='print_text' scope="row">Asessment due</td>
									<td></td>
									<td></td>
									<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.filing.file_assessment / 100)}</td>
								</tr>
								<tr>
									<td><p class='print_text'>Interest for late filing ((${data.filing.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
									<td></td>
									<td></td>
									<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.filing.file_interest / 100)} </p></td>
								</tr>
								<tr>
									<td class='print_text'><b>Total Due</b></td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>								
								</tr>

							`)

						}

					} else { //wapal custom body

						if (data.filing.file_adjustment && data.filing.file_adjust_type) { //adjustment
							const type = data.filing.file_adjust_type == ADJUST_DEBIT ? 'Debit' : 'Credit'
							const operation = data.filing.file_adjust_type == ADJUST_DEBIT ? '+' : '-'
							let total_before_adjustment = 0;
							ages.forEach(age => {
								total_before_adjustment += (Number(age.total_count_amount) * Number(age.cost_amount))
							});

							$tabClone.find('#print_body').append(`
								<tr>
									<td class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style= 'text-align: end; border-bottom: black 1px solid; border-top: black 1px solid;'><strong>${formatter.format(total_before_adjustment / 100)}</strong></p></td>
								</tr>
								<tr>
									<td class='print_text'><b>Adjustment ${type} &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style = 'text-align: end; margin-bottom: 0px;'><strong>${operation} ${formatter.format(data.filing.file_adjustment / 100)}</strong></p></td>
								</tr>
								<tr>
									<td style='padding-right: 5rem; margin-top: 0px;' class='print_text'><b>Total Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
									<td></td>
									<td></td>
									<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
								</tr>
							`)
						} else {
							$tabClone.find('#print_body').append(`
							<tr>
								<td style='padding-right: 5rem;' class='print_text'><b>Assessment Due &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</b> </td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end; border-top: black 1px solid;'><strong>${formatter.format(data.filing.file_amount / 100)}</strong></p></td>
							</tr>
							`)
						}

					}

					//footer
					if (data.rate.rate_progid != PROG_MVB) {
						$tabClone.append(`
							<div><p> <strong>Please wire or ACH transaction payment in full as follows:</br></strong> ${data.print_wire}</p> </div>
							<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
								<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check} </a></b></p>
							</div>	
							<p style='margin-bottom: 0;' class='print_text mb-0'>mail all payments and correspondence to:</p>
							<div style='display:flex; justify-content: space-between;'>
								<div style='text-align: center'>
									<p><strong>USPS:</strong> </br>${data.print_usps}</p>
								</div>
								<div style='text-align: center;'>
									<p><strong>UPS/Fed Ex requiring a street address:</strong> </br>${data.print_ups}</p>
								</div>
							</div>
							<div style='text-align: center; margin-top: 2rem;'>
							<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>`
						)
					} else { //custom maine footer
						$tabClone.append(`
							<div style='display: flex; justify-content: space-between;'>
								<p><strong>ALL ACH GO TO TD BANK</strong></br>${data.print_ach}</p>
								<p style='text-align: left;'><strong>ALL WIRE GO TO TD BANK</strong></br>${data.print_wire}</p>
							</div>
							<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
								<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.print_color} !important; font-size: 22px;'>${data.print_check}</a></b></p>
							</div>	
							<div style='display: flex; justify-content: space-between; text-align: center'>
								<div style='text-align: center'>
									<p style='text-align: center';><strong>ALL CHECKS AND REGULAR MAIL</strong></br>${data.print_usps}</p>
								</div>
								<div style='text-align: center'>
									<p style='text-align: center; '><strong>ALL, FEDEX, UPS, OVERNIGHTS</strong></br>${data.print_ups}</p>
								</div>
							</div>
							<div style='text-align: center; margin-top: 2rem;'>
							<p>The Taxpayer ID for ${data.program} is ${data.print_taxid}</p></div>
						`)
					}
					$tabClone.find('.print_modal').remove()

					//CHARI 
					if (data.chari) {
						//chari logo
						const response = await fetch(`/data/get_logo/${data.chari.logo_guid}`, { method: 'GET' })
						const json = await response.json(); // Parse the JSON body
						console.log(json)
						const chariUrl = json.url
						const chariLogo = document.createElement('img')
						chariLogo.style.maxHeight = '150px'
						chariLogo.style.maxWidth = '400px'
						chariLogo.src = chariUrl

						//new page
						$tabClone.append("<footer style='page-break-before:always'></footer>")
						$tabClone.append("<div style='text-align: center;' id='file_charilogo_container'></div> <div id='file_chariprint_content'><div>")

						//header
						$tabClone.find('#file_charilogo_container').append(chariLogo)
						$tabClone.find('#file_charilogo_container').append(`
							<div style='text-align: center; margin-top: 2rem;'>
								<p class='print_text'>${data.chari.print_heading}</p>
							</div>`
						)
						$tabClone.find('#file_chariprint_content').prepend(`
							<div style='text-align: end;' class=' print_text'>
								<p>
									Filed: &nbsp ${data.chari.filing.file_duepretty}</br>
									Reference: &nbsp ${data.chari.filing.file_program}
								</p>
							</div>
							<div style='display: flex; justify-content: center; margin-top: .5rem;' class='print_text''>
								<div style='text-align: end; margin-top: 0;' class='text-center'>
									<p style='font-size:16px !important; margin-top: 0;'><b>CHA-RI Remittance Form</b></br>
								</div>
							</div>
							<div class='print_text'>
								<p>
									Report for: ${data.chari.filing.rate_name}<br>
									Due: ${data.chari.filing.file_duepretty}<br>
									Federal EIN: ${data.payer.payer_fein}</br>
									Company Name: ${data.payer.payer_name}
								</p>
							</div>
						`)

						$tabClone.append(`
							<div class='row container-fluid print_texts'> 
								<table class="table table-borderless">
									<thead style='border-bottom: 2px solid black;'>
										<tr>
											<th style='border-bottom: 1px solid black;' scope="col"></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Lives</b></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Rate</b></th>
											<th style='border-bottom: 1px solid black;' scope="col"><b>Assessment</b></th>
										</tr>
									</thead>
									<tbody class='table-group-divider' id='print_chari_body'>
									</tbody>
								</table>
							</div>
						`)

						data.chari.ages.forEach(age => {
							$tabClone.find('#print_chari_body').append(`
								<tr>
									<td class='print_text' scope="row">CHA-RI Resident ${age.age_name} Covered Lives Reported:</td>
									<td class='print_text'>${Number(age.total_count_amount).toLocaleString('en-US') ?? 0}</td>
									<td class='print_text'>${formatter.format(age.cost_amount / 100)}</td>
									<td style='text-align: end;' class='print_text text-end'>${formatter.format((age.total_count_amount * age.cost_amount) / 100)}</td>
								</tr>
							`)
						});

						$tabClone.find('#print_chari_body').append(`
							<tr>
								<td class='print_text' scope="row">Asessment due</td>
								<td></td>
								<td></td>
								<td style='border-top: 1px solid black; text-align: end;'class='print_text text-end'>${formatter.format(data.chari.filing.file_assessment / 100)}</td>
							</tr>
							<tr>
								<td><p class='print_text'>Interest for late filing ((${data.chari.interest_amount}) x (# of days past due) x (Assessment Due)):</p></td>
								<td></td>
								<td></td>
								<td><p class='print_text text-end' style='border-bottom: black 1px solid; text-align: end;'>${formatter.format(data.chari.filing.file_interest / 100)} </p></td>
							</tr>
							<tr>
								<td class='print_text'><b>Total Due</b></td>
								<td></td>
								<td></td>
								<td class='print_text text-end'><p style='border-bottom: black 3px double; text-align: end;'><strong>${formatter.format(data.chari.filing.file_amount / 100)} </strong></p></td>
							</tr>
						`)

						//footer
						$tabClone.append(`
							<div><p> <strong>Please wire or ACH transaction payment in full as follows:</br></strong> ${data.chari.print_wire}</p> </div>
							<div style='text-align: center; margin-bottom: 0;' print_text mb-0'>
								<p><i>If unable to pay via wire or ACH transaction as stated above, please make check payable to:</i></br> <b><a style='color: ${data.chari.print_color} !important; font-size: 22px;'>${data.chari.print_check} </a></b></p>
							</div>	
							<p style='margin-bottom: 0;' class='print_text mb-0'>mail all payments and correspondence to:</p>
							<div style='display:flex; justify-content: space-between;'>
								<div style='text-align: center'>
									<p><strong>USPS:</strong> </br>${data.chari.print_usps}</p>
								</div>
								<div style='text-align: center;'>
									<p><strong>UPS/Fed Ex requiring a street address:</strong> </br>${data.chari.print_ups}</p>
								</div>
							</div>
							<div style='text-align: center; margin-top: 2rem;'>
							<p>The Taxpayer ID for CHARI is ${data.chari.print_taxid}</p></div>`
						)
					}

					//convert to html and generate pdf
					const html = $tabClone.html()
					post('/data/get_pdf', { html: html, program: data.print_progid })

				} catch (error) {
					displayNotification('Error Downloading Pdf', 'danger')
					console.error('File Print', error)
				} finally {
					$('#print_spinner').hide()
					$('#print_spinner').prop('disabled', false)
				}
			})
		}

		const reloadFilePeriodDt = () => filePeriodDt.ajax.reload(undefined, false)

		const submitFiling = async (postData) => {
			try {
				const { rc, file_id, rate_id, curr_user } = await fetchPost('/form/submit_filing', { data: postData })
				if (rc != "OK") throw rc

				displayNotification("Filing Submitted Successfully", "success")
				updateAssessLang()
				setTimeout(() => exitFiling(file_id, rate_id, curr_user, true), 1000)
				filingHistoryDt.ajax.reload()
			} catch (error) {
				switch (error) {
					case "NO_REASON":
						displayNotification('Please Provide a Reason For Any Question Left Blank', 'danger')
						break
					case "FILE_EXISTS":
						displayNotification('A Filing Already Currently Exists For This Period', 'danger')
						break
					case "AGE_NEGATIVE":
						displayNotification('Please Provide Positive Values for # of Lives', 'danger')
						break
					default:
						displayNotification('Error Saving File', 'danger')
						console.error('file save', error)
				}
			}
		}

		$('.assessmentAdminUser').off('change').on('change', (event) => {
			const $value = event.target.value
			if ($value == 'custom') {
				$('#assessmentAdminUserCustomContainer').slideDown()
			} else {
				$('#assessmentAdminUserCustomContainer').slideUp()
			}
		})

		$('#file_save').off('click').on('click', async (event) => {
			try {
				const ages: Age[] = []
				$('.age_template').toArray().forEach((element) => {
					const age = ageMap.get(element)
					if (!age) return
					const age_name = $(element).find('.age_label').text()
					$(element).find('.age').each((_, inputElem) => {
						const age_amount = $(inputElem).val() as any
						const age_month = parseInt($(inputElem).attr('id').split('_')[1])
						ages.push({ ...age, age_name, age_amount, age_month })
					});
				})

				//find the selected user
				let $curr_radio = null
				$('.assessmentAdminUser').toArray().forEach((element) => {
					const $element = $(element)
					if ($element.is(':checked')) {
						$curr_radio = $element.val()
					}
				})

				const isCustom = $curr_radio == 'custom'
				if (isCustom && $('#assessmentAdminUserCustomForm').val() != '') {
					const isValidated = validateEmail($('#assessmentAdminUserCustomForm').val())
					if (!isValidated) return displayNotification('Please Enter a Valid Email', 'danger')
				}

				const postData = {
					payer_id: payer ? payer.payer_id : null,
					rate: file ? file.rate_id : null,
					zero: $('#file_zeroLives').is(":checked") ? 1 : 0,
					reason: $('#file_zeroLives').is(":checked") ? null : $('#file_reason').val(),
					exempt: $('#file_exempt').is(":checked") ? 1 : null,
					ages,
					user: $curr_radio == 'custom' ? String($('#assessmentAdminUserCustomForm').val()) : Number($curr_radio),
				}
				let ageTotal = 0
				postData.ages.forEach((age) => ageTotal += Number(age.age_amount))
				if (ageTotal = 0) postData.zero = ZERO_LIVES

				if ($('input[name=question]:not(:checked)').length > 0 && $('#file_reason').val() == '') {
					displayNotification('Please Provide a Reason For Any Questions Left Blank', 'danger')
					return
				}

				//check to make sure that all amounts are positve
				if (postData.ages.some(age => age.age_amount < 0)) {
					displayNotification('Please Provide Positive Values for # of Lives', 'danger')
					return
				}

				confirmDialog({
					dialogTitle: 'Submit Filing',
					bodyText: 'Are you sure you want to submit a filing for this period? </br> You will not be able to submit again until next quarter.',
					confirmText: 'Submit',
					confirmStyle: 'warning',
					showCancelButton: false,
					confirmFunction: () => submitFiling(postData)
				})
			} catch (error) {
				console.error('fileSave', error)
			}
		})

		const clearUserSection = (userId) => {
			$('.assessmentAdminUser').toArray().forEach((el) => {
				const element = $(el)
				element.val() == userId ? element.prop('checked', true) : element.prop('checked', false)
			})
			$('#assessmentAdminUserCustomForm').val('')
		}

		const exitFiling = (file_id = null, rate_id = null, user_id = null, submit = false) => {
			$("#file_zeroLives").prop('checked', false)
			$('#file_home_container').fadeIn(300)
			$('#filing_container').hide()
			reloadFilePeriodDt()
			clearFiling()
			clearUserSection(user_id)
			$(window).trigger('resize')
			if (submit) showPrintModal(file_id, rate_id)
		}
		$('#file_exit').on('click', async (event) => exitFiling())
	}

	$('.file_tohistory').on('click', async (e) => {
		e.preventDefault()
		toTab('historyTabContent')
	})
}

async function initMultiplePayer() {

	const initPayerSelectDt = () => {
		const columnsInfo = [
			{
				data: 'payer_id',
				visible: false,
			},
			{
				data: 'payer_fein',
				header: 'Fein',
			},
			{
				data: 'payer_name',
				header: 'Payer',
			},
			{
				data: 'prog_name',
				header: 'Program',
			},
			{
				data: 'payer_naic',
				header: 'Naic',
			},
			{
				data: 'payer_group',
				header: 'Group',
			},
			{
				data: 'payer_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<div><button class="show-edit btn btn-primary btn-sm"><i class="fa-regular fa-folder-open"></i> Open Payer</button> <button class="btn btn-danger btn-sm remove-payer"> <i class="fa-solid fa-trash"></i> Remove</button> </div>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const payerSelectDt = $('#payerSelectTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/payer_select',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			buttons: [
				{
					text: '<span class="fas fa-plus mr-1"></span> Register',
					className: 'add ms-2 btn btn-sm btn-success',
					action: () => registerPayer(),
				},
				{
					text: '<span class="fas fa-plus mr-1"></span> Add',
					className: 'add ms-2 btn btn-sm btn-primary',
					action: () => addPayer(),
				},
			],
			dom: datatablesButtonDom,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No payers to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> payers",
				infoEmpty: "Showing 0 to 0 of 0 payers",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total payer)",
				lengthMenu: "Show _MENU_ payer",
			}
		})

		$('#payerSelectTableContainer').find('th').addClass('custom-header')

		$('#payerSelectTableContainer').on('click', '.show-edit', ({ target }) => {
			let row = payerSelectDt.row($(event.target).closest('tr')).data()
			post('/ui/assessment', { payer: JSON.stringify(row) })
		})

		$('#payerSelectTableContainer').on('click', '.remove-payer', ({ target }) => {
			let row = payerSelectDt.row($(event.target).closest('tr')).data() as any
			confirmDialog({
				dialogTitle: 'Remove Payer',
				bodyText: 'Are you sure you want to remove this payer? </br>',
				confirmText: 'Remove',
				confirmStyle: 'danger',
				showCancelButton: true,
				confirmFunction: () => removePayer(row.payer_id)
			})
		})

		return payerSelectDt
	}

	let payerSelectDt = initPayerSelectDt()

	const resetPayer = () => $("#payerManageForm :input").val('')

	const closePayer = () => $('#payerManageForm').slideUp(slideSpeed, () => resetPayer())

	const payerForm = [
		{
			$: $('#payerManageName'),
			source: 'payer_name',
		},
		{
			$: $('#payerManageStatus'),
			source: 'payer_status',
		},
		{
			$: $('#payerManageFein'),
			source: 'payer_fein',
		},
		{
			$: $('#payerManageAddress1'),
			source: 'payer_address1',
		},
		{
			$: $('#payerManageAddress2'),
			source: 'payer_address2',
		},
		{
			$: $('#payerManageCity'),
			source: 'payer_city',
		},
		{
			$: $('#payerManageState'),
			source: 'payer_state',
		},
		{
			$: $('#payerManageZip'),
			source: 'payer_zip',
		},
		{
			$: $('#payerManageNaic'),
			source: 'payer_naic',
		},
		{
			$: $('#payerManageGroup'),
			source: 'payer_group',
		},
	]

	$("#payerManageClose").off('click').on("click", async () => closePayer())

	const updatePayerSelect = async () => {
		const { data, email, rc } = await fetchPost('/data/get_existing_payers', {})
		if (rc != "OK") return

		let payerSelect = $('#payerManageExistingPayers');
		payerSelect.empty()

		let options = '';
		$.each(data, function (_, payer) {
			options += `<option value="${payer.payer_id}">${payer.payer_name}</option>`;
		});

		payerSelect.html(`<optgroup label="Payers">${options}</optgroup>`);

		if (data.length < 1) {
			$('#payerManageModalWording').html(`You are not authorized for any other payers. Please contact support at ${email} if you are missing a payer you file for or register for a new one below.`)
			payerSelect.prop('disabled', true)
		} else {
			$('#payerManageModalWording').html('Please select any payers you wish to add to this program.')
			payerSelect.prop('disabled', false)
		}

		payerSelect.trigger('chosen:updated')
	}

	const removePayer = async (payerId) => {
		try {
			const res = await fetchPost('/form/submit', { type: 'remove_payer', data: { payer_id: payerId } })
			if (res.rc != 'OK') throw res.rc

			displayNotification('Payer removed.', 'success')
			payerSelectDt.ajax.reload()
			updatePayerSelect()
		} catch (error) {
			console.error('removePayer', error)
			switch (error) {
				case 'NO_DATA':
					displayNotification('Error, insignificant data', 'danger')
					break;
				case 'LAST_PAYER':
					displayNotification('Error, you must have at least one payer.', 'danger')
					break;
				default:
					displayNotification('Error removing payer', 'danger')
					break;
			}
		}
	}

	const registerPayer = async () => {
		$('#payerManageForm').slideDown(slideSpeed)

		$("#payerManageSave").off('click').on("click", async () => {
			if (($("#payerManageForm")[0] as HTMLFormElement).checkValidity() === false) {
				($("#payerManageForm")[0] as HTMLFormElement).reportValidity();
				return;
			}


			try {
				const res = await fetchPost('/form/submit', { type: 'add_payer', data: getForm(payerForm) })
				if (res.rc != 'OK') throw res.rc

				displayNotification('Payer saved.', 'success')
				closePayer()
				payerSelectDt.ajax.reload()
			} catch (error) {
				console.error('addPayer', error)
				switch (error) {
					case 'NO_DATA':
						displayNotification('Error, insignificant data', 'danger')
						break;
					case 'INVALID_PAYER':
						displayNotification('Error, please provide a valid FEIN', 'danger')
						break;
					case 'PAYER_EXISTS':
						displayNotification('Error, this payer already exists', 'danger')
						break;
					default:
						displayNotification('Error saving payer', 'danger')
						break;
				}
			}
		})

	}

	const addPayer = async () => {
		$('#payerManageModal').modal('show')

		$('#payerManageAdd').off('click').on("click", async ({ target }) => {
			const $spinner = $(target).find('.spinner-border')
			$spinner.show()

			try {
				const res = await fetchPost('/form/submit', { type: 'add_existing_payer', data: $('#payerManageExistingPayers').val() })
				if (res.rc != 'OK') throw res.rc

				displayNotification('Saved Successfully', 'success')
				payerSelectDt.ajax.reload()
				$('#payerManageModal').modal('hide')
				$('#payerManageExistingPayers').val('').trigger('chosen:updated')
				updatePayerSelect()

			} catch (error) {
				if (error == 'NO_DATA') return displayNotification('Please select at least one payer to add', 'danger')

				displayNotification('Error adding payer', 'danger')
				console.error('addPayer', error)
			} finally {
				$spinner.hide()
			}
		})
	}
}

function init_about() {

	$(".nav-link").on("click", () => {
		setTimeout(() => {
			$(window).trigger('resize')
		}, 200)
	})

	$("#staff_form").on("change", () => {
		if ($("#staff_global").is(':checked')) {
			$("#staff_scope").prop('disabled', true).trigger("chosen:updated")
		} else {
			$("#staff_scope").prop('disabled', false).trigger("chosen:updated")
		}
	})

	$("#member_form").on("change", () => {
		if ($("#memb_global").is(':checked')) {
			$("#memb_scope").prop('disabled', true).trigger("chosen:updated")
		} else {
			$("#memb_scope").prop('disabled', false).trigger("chosen:updated")
		}
	})

	const getStaffFilters = () => ({
		status: $('#staff_filterstatus').val(),
		scope: $('#staff_filterscope').val()
	})
	setCollapse({ $btn: $('#staff_filterscollapse'), $target: $('#staff_filters') })

	const getMemberFilters = () => ({
		status: $('#memb_filterstatus').val(),
		scope: $('#memb_filterscope').val()
	})
	setCollapse({ $btn: $('#memb_filterscollapse'), $target: $('#member_filters') })

	const initStaffDt = () => {
		const columnsInfo = [
			{
				data: 'staff_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'staff_name',
				header: 'Name',
			},
			{
				data: 'staff_scope',
				header: 'Scope',
			},
			{
				data: 'staff_title',
				header: 'Title',
			},
			{
				data: 'staff_status',
				header: 'status',
				visible: false,
			},
			{
				data: 'staff_sort',
				header: 'Sort',
			},
			{
				data: 'staff_startpretty',
				header: 'Start',
			},
			{
				data: 'staff_endpretty',
				header: 'End',
			},
			{
				data: 'staff_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const staffDt = $('#staff_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/staff',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getStaffFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => staffEdit({ staff_id: 0 }),
				}
			],
			language: {
				emptyTable: "No staff to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> staff members",
				infoEmpty: "Showing 0 to 0 of 0 staff",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total staff members)",
				lengthMenu: "Show _MENU_ staff",
			},
			initComplete: () => {
				const $scroll = $('#stafftable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#stafftable_container').find('th').addClass('custom-header')

		$('#stafftable_container').off('click').on('click', '.show-edit', ({ target }) => {
			let row = staffDt.row($(event.target).closest('tr')).data() as Staff
			staffEdit(row)
		})

		//Reload table when filters change
		$('#staff_filters').on('change', () => staffDt.ajax.reload(undefined, false))

		return staffDt
	}
	let staffDt = initStaffDt()

	type Staff = {
		staff_id: number
		staff_img: number
		staff_name: string
		staff_sort: string,
		staff_description: string
		staff_status: number,
		staff_title: string
		staff_startdate: string
		staff_enddate: string
		img_originalname: string
		img_size: string
	}

	const clearStaff = () => {
		$('#staff_container').slideUp(slideSpeed)
		$("#staff_form :input").val('')
		$(".staff_form").hide()
		$(".staff_toggle").hide()
		$("#staff_new").show()
		$("#staffphoto_container").hide()
		$("#staff_scope").val('').trigger("chosen:updated")
		$('#staff_global').prop('checked', false)
		$("#staff_scope").prop('disabled', false).trigger("chosen:updated")
		tinymce.get('description').setContent('')
	}

	$("#staff_new").off("click",).on("click", () => {
		$("#staff_new").hide()
		staffEdit({ staff_id: 0 })
	})

	tinymce.init({
		selector: '#description',
		toolbar: tinyMceTools,
		font_family_formats: tinyMceFonts,
		plugins: tinyMcePlugins,
		promotion: false,
		height: 400,
		content_style: tinyMceDefaultFont,
		browser_spellcheck: true,
		content_css: false,
		relative_urls: false,
		skin: false
	})

	const staffEdit = async (staff: Partial<Staff>) => {
		$('#staff_container').slideDown(slideSpeed)

		let isInsert = staff.staff_id == 0
		if (isInsert) { //INSERT

			$("#staff_cancel").show()
			$(".staff_form").show()
			$("#staff_save").show()
			$("#staff_photo").show()

		} else { //UPDATE 
			let data = { type: "staff_scope", id: staff.staff_id }
			const { scope } = await fetchPost('/data/submit', data)
			scope.forEach(s => { s == 0 || s == '0' ? $('#staff_global').prop('checked', true) : $('#staff_global').prop('checked', false) })

			if (staff.staff_status == STAFF_INACTIVE) {
				$("#staff_archive").hide()
				$("#staff_reactivate").show()
			} else {
				$("#staff_archive").show()
				$("#staff_reactivate").hide()
			}

			$("#staff_photo").hide()
			$("#staffphoto_container").show()
			$("#staff_new").hide()
			$("#staff_cancel").show()
			$("#staff_save").show()
			$(".staff_form").show()
			$('#id').val(staff.staff_id)
			$("#staff_scope").val(scope).trigger("chosen:updated")
			$('#name').val(staff.staff_name)
			$('#sort').val(staff.staff_sort)
			$('#title').val(staff.staff_title)
			$('#start').val(staff.staff_startdate)
			$('#end').val(staff.staff_enddate)

			tinymce.get('description').setContent(staff.staff_description)

			$('#staffphoto_original').html(htmlEsc(staff.img_originalname))
			$('#staffphoto_size').html(htmlEsc(staff.img_size))

			$("#staffphoto_change").off("click").on("click", () => {
				$("#staffphoto_container").hide()
				$("#staff_photo").show()
			})

			//hide scope if global checked
			$("#staff_global").is(':checked') ? $("#staff_scope").prop('disabled', true).trigger("chosen:updated") : $("#staff_scope").prop('disabled', false).trigger("chosen:updated")
		}

		$("#staff_cancel").off("click").on("click", (e) => {
			clearStaff()
			e.preventDefault()
		})

		$(".staff_toggle").off("click").on("click", (e) => {
			staffArchive(staff)
			e.preventDefault()
		})

		$("#staff_save").off('click').on("click", (e) => {
			const description = tinymce.get("description").getContent()
			let postData = {
				id: staff.staff_id,
				img_id: staff.staff_img,
				scope: $('#staff_global').is(':checked') ? [0] : $('#staff_scope').val(),
				sort: Number($('#sort').val()),
				name: $('#name').val(),
				photo: $('#staff_photo').val(),
				description: description,
				title: $('#title').val(),
				start: $('#start').val(),
				end: $('#end').val(),
			}

			//tinmyce validation
			// if (description.length === 0 || description == null || description == '<p>&nbsp;</p>' || description == '<p><br data-mce-bogus="1"></p>') {
			// 	$("#description").setCustomValidity('Please enter some content in the editor');
			// } 

			if (($("#staff_form")[0] as HTMLFormElement).checkValidity() === false) {
				($("#staff_form")[0] as HTMLFormElement).reportValidity();
				return;
			}

			if (postData.sort < 1) { //sort starts at 1
				displayNotification('Please enter a sort greater than 0', 'danger')
				return
			}

			staffSave(postData)
		})
	}

	const staffSave = async (postData) => {
		try {
			const formData = new FormData($('#staff_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('type', 'save_staff')
			const res = await fetchPost('/marketing/submit', formData)
			if (res.rc === 'OK') {
				displayNotification("Staff Member Saved Successfully", "success")
				staffDt.ajax.reload(undefined, false)
				clearStaff()
			} else {
				displayNotification("error saving this staff member", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const staffArchive = async (staff: Partial<Staff>) => {
		let postData = {
			type: 'archive_staff',
			id: staff.staff_id,
			status: staff.staff_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Staff Status Changed Successfully", "success")
				staffDt.ajax.reload()
				clearStaff()
			} else {
				displayNotification("Error Changing Staff Status", "danger")
			}
		} catch (e) {
			console.error(e)
		}
	}

	function initMembersDt() {
		const columnsInfo = [
			{
				data: 'member_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'member_title',
				header: 'Title',
			},
			{
				data: 'member_name',
				header: 'Name',
			},
			{
				data: 'member_scope',
				header: 'Scope',
			},
			{
				data: 'member_status',
				header: 'status',
				visible: false,
			},
			{
				data: 'member_sort',
				header: 'Sort',
			},
			{
				data: 'member_startpretty',
				header: 'Start',
			},
			{
				data: 'member_endpretty',
				header: 'End',
			},
			{
				data: 'member_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const memberDt = $('#member_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/member',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getMemberFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => memberEdit({ member_id: 0 }),
				}
			],
			language: {
				emptyTable: "No members to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> members",
				infoEmpty: "Showing 0 to 0 of 0 member",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total members)",
				lengthMenu: "Show _MENU_ members",
			},
			initComplete: () => {
				const $scroll = $('#memberTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#membertable_container').find('th').addClass('custom-header')

		$('#membertable_container').on('click', '.show-edit', ({ target }) => {
			let row = memberDt.row($(event.target).closest('tr')).data() as member
			memberEdit(row)
		})

		//Reload table when filters change
		$('#member_filters').on('change', () => {
			memberDt.ajax.reload(undefined, false)
		})

		return memberDt
	}
	let memberDt = initMembersDt()

	$("#member_insert").off("click").on("click", () => {
		memberEdit({ member_id: 0 })
	})

	const clearMember = () => {
		$("#member_container").slideUp(slideSpeed)
		$("#member_form :input").val('')
		$(".member_form").hide()
		$(".memb_toggle").hide()
		$("#member_insert").show()
		$("#memb_scope").val('').trigger("chosen:updated")
		$('#memb_global').prop('checked', false)
		$("#memb_scope").prop('disabled', false).trigger("chosen:updated")
	}

	type member = {
		member_id?: number,
		member_name?: string,
		member_title?: string,
		member_sort?: number,
		member_status?: number,
		member_description?: string,
		member_effectivedate?: string,
		member_enddate?: string,
	}


	async function memberEdit(member: Partial<member>) {
		$("#member_insert").hide()
		$("#member_container").slideDown(slideSpeed)

		let isInsert = member.member_id == 0
		if (isInsert) { //ADD

			$("#memb_cancel").show()
			$("#memb_update").show()
			$(".member_form").show()

		} else { //UPDATE
			let postData = { type: "member_scope", id: member.member_id }
			const { scope } = await fetchPost('/data/submit', postData)
			scope.forEach(s => { s == 0 || s == '0' ? $('#memb_global').prop('checked', true) : $('#memb_global').prop('checked', false) })

			//hide scope if global checked
			$("#memb_global").is(':checked') ? $("#memb_scope").prop('disabled', true).trigger("chosen:updated") : $("#memb_scope").prop('disabled', false).trigger("chosen:updated")

			if (member.member_status == MEMBER_INACTIVE) { //change to constant
				$("#memb_archive").hide()
				$("#memb_reactivate").show()
			} else {
				$("#memb_archive").show()
				$("#memb_reactivate").hide()
			}

			$("#memb_update").show()
			$(".member_form").show()
			$("#memb_cancel").show()
			$("#memb_scope").val(scope).trigger("chosen:updated")
			$('#memb_name').val(member.member_name)
			$('#memb_sort').val(member.member_sort)
			$('#memb_description').val(member.member_description)
			$('#memb_title').val(member.member_title)
			$('#memb_start').val(member.member_effectivedate)
			$('#memb_end').val(member.member_enddate)
		}

		$(".memb_toggle").off("click").on("click", (e) => {
			e.preventDefault()
			memberToggle(member)
		})

		$("#memb_cancel").off("click").on("click", (e) => {
			e.preventDefault()
			clearMember()
		})

		$("#memb_update").off("click").on("click", (e) => {
			let data = {
				type: "save_member",
				id: member.member_id,
				scope: $('#memb_global').is(':checked') ? [0] : $('#memb_scope').val(),
				sort: $('#memb_sort').val(),
				name: $('#memb_name').val(),
				title: $('#memb_title').val(),
				start: $('#memb_start').val(),
				end: $('#memb_end').val(),
			}

			if (Number(data.sort) < 1 || data.sort == "") { //sort starts at 1
				displayNotification('Please Enter a Sort Greater Than 0', 'danger')
				return
			}

			memberSave(data)
		})
	}

	async function memberToggle(member: member) {
		let postData = {
			type: 'archive_member',
			id: member.member_id,
			status: member.member_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Member Archived Successfully", "success")
				memberDt.ajax.reload()
				clearMember()
			} else {
				displayNotification("Error archiving this council member", "danger")
			}
		} catch (e) {
			console.error(e)
		}
	}

	async function memberSave(postData) {
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Member Saved Successfully", "success")
				memberDt.ajax.reload(undefined, false)
				clearMember()
			} else {
				displayNotification("Error saving this member", "danger")
			}
		} catch (e) {
			console.error(e)
		}
	}
}

function initSupport() {


	function questions() {

		$("#quest_form").on("change", () => {
			if ($("#quest_global").is(':checked')) {
				$("#quest_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#quest_scope").prop('disabled', false).trigger('chosen:updated')
			}
		})

		const getQuestFilters = () => ({
			status: $('#quest_filterstatus').val(),
			scope: $('#quest_filterscope').val()
		})
		setCollapse({ $btn: $('#quest_filterscollapse'), $target: $('#quest_filters') })

		const initQuestDt = () => {
			const columnsInfo = [
				{
					data: 'quest_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'quest_typepretty',
					header: 'Type',
				},
				{
					data: 'quest_scope',
					header: 'Scope',
				},
				{
					data: 'sec_name',
					header: 'Section',
				},
				{
					data: 'quest_question',
					header: 'Question',
				},
				{
					data: 'quest_answer',
					header: 'Answer',
					visible: false,
				},
				{
					data: 'quest_startpretty',
					header: 'Start',
				},
				{
					data: 'quest_endpretty',
					header: 'End',
				},
				{
					data: 'quest_statuspretty',
					header: 'Status',
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const questDt = $('#quest_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/question',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getQuestFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => questEdit({ quest_id: 0 }),
					},
					{
						text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
						className: ' ms-1 download btn btn-sm btn-primary',
						action: () => downloadQuestions(),
					},
				],
				language: {
					emptyTable: "No question to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> questions",
					infoEmpty: "Showing 0 to 0 of 0 questions",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total questions)",
					lengthMenu: "Show _MENU_ questions",
				},
				initComplete: () => {
					const $scroll = $('#questtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			const downloadQuestions = async () => {
				const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
				post('/tables/question', { filters: JSON.stringify(getQuestFilters()), mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields) })
			}

			$('#questtable_container').find('th').addClass('custom-header')

			$('#questtable_container').on('click', '.show-edit', ({ target }) => {
				let row = questDt.row($(event.target).closest('tr')).data() as question
				questEdit(row)
			})

			//Reload table when filters change
			$('#quest_filters').on('change', () => questDt.ajax.reload(undefined, false))

			return questDt
		}
		let questDt = initQuestDt()

		type question = {
			quest_id: number,
			quest_type: number,
			quest_section: number,
			quest_scope: number,
			quest_status: number,
			quest_question: string,
			quest_answer: string,
			quest_startdate: string,
			quest_enddate: string,
			quest_link: string
		}

		$("#question_insert").on("click", () => questEdit({ quest_id: 0 }))

		const clearQForm = () => {
			$("#quest_container").slideUp(slideSpeed)
			$("#quest_form :input").val('')
			$("#quest_scope").val('').trigger("chosen:updated")
			$(".quest_form").hide()
			$(".quest_hide").hide()
			$("#question_insert").show()
			$('#quest_global').prop('checked', false)
			$("#quest_scope").prop('disabled', false).trigger("chosen:updated")
		}

		tinymce.init({
			selector: '#quest_question',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		tinymce.init({
			selector: '#quest_answer',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		const questEdit = async (quest: Partial<question>) => {
			$("#question_insert").hide()
			$("#quest_container").slideDown(slideSpeed)

			let isInsert = quest.quest_id == 0
			if (isInsert) { //ADD

				$("#quest_cancel").show()
				$("#quest_update").show()
				$(".quest_form").show()

			} else { //UPDATE
				let postData = { type: "question_scope", id: quest.quest_id }
				const { scope } = await fetchPost('/data/submit', postData)
				scope.forEach(s => { s == 0 || s == '0' ? $('#quest_global').prop('checked', true) : $('#quest_global').prop('checked', false) })

				if (quest.quest_status == QUEST_INACTIVE) {
					$("#quest_archive").hide()
					$("#quest_reactivate").show()
				} else {
					$("#quest_archive").show()
					$("#quest_reactivate").hide()
				}

				$("#quest_cancel").show()
				$("#quest_link_container").show()
				$("#quest_update").show()
				$(".quest_form").show()
				$("#quest_scope").val(scope).trigger("chosen:updated")
				$('#quest_section').val(quest.quest_section).trigger("chosen:updated")
				$('#quest_type').val(quest.quest_type).trigger("chosen:updated")
				$('#quest_start').val(quest.quest_startdate)
				$('#quest_end').val(quest.quest_enddate)
				$('#quest_link').val(quest.quest_link)

				tinymce.get('quest_question').setContent(quest.quest_question)
				tinymce.get('quest_answer').setContent(quest.quest_answer)

				//hide scope if global checked
				$("#quest_global").is(':checked') ? $("#quest_scope").prop('disabled', true).trigger("chosen:updated") : $("#quest_scope").prop('disabled', false).trigger("chosen:updated")
			}

			$(".quest_toggle").off("click").on("click", (e) => {
				questToggle(quest)
				e.preventDefault()
			})

			$("#quest_cancel").off("click").on("click", (e) => {
				clearQForm()
				e.preventDefault()
			})

			$("#quest_update").off("click").on("click", (e) => {
				const question = tinymce.get('quest_question').getContent()
				const answer = tinymce.get('quest_answer').getContent()
				let postData = {
					type: "question_insert",
					id: quest.quest_id,
					qtype: $('#quest_type').val(),
					scope: $('#quest_global').is(':checked') ? [0] : $('#quest_scope').val(),
					section: $('#quest_section').val(),
					question: question,
					answer: answer,
					start: $('#quest_start').val(),
					end: $('#quest_end').val(),
				}
				questSave(postData)
			})
		}

		const questSave = async (postData) => {
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Question Saved Successfully", "success")
					questDt.ajax.reload()
					clearQForm()
				} else if (res.rc === 'NO_MATCH') {
					displayNotification("This question section cannot be added for that question type", "danger")
				} else {
					displayNotification("error saving this content", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		const questToggle = async (quest) => {
			let postData = {
				id: quest.quest_id,
				status: quest.quest_status,
				type: 'question_archive',
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Question Status Changed Successfully", "success")
					questDt.ajax.reload()
					clearQForm()
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function supportContent() {
		$("#qcont_form").on("change", () => {
			if ($("#qcont_global").is(':checked')) {
				$("#qcont_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#qcont_scope").prop('disabled', false).trigger('chosen:updated')
			}
		})

		const getQcontentFilters = () => ({
			status: $('#qcont_filterstatus').val(),
			scope: $('#qcont_filterscope').val(),
		})
		setCollapse({ $btn: $('#qcont_filterscollapse'), $target: $('#qcont_filters') })

		const initQcontDt = () => {
			const columnsInfo = [
				{
					data: 'sup_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'sup_header',
					header: 'Content Description',
				},
				{
					data: 'sup_scope',
					header: 'Scope',
				},
				{
					data: 'sup_phone',
					header: 'Phone',
				},
				{
					data: 'sup_fax',
					header: 'Fax',
				},
				{
					data: 'sup_address',
					header: 'Address',
				},
				{
					data: 'sup_statuspretty',
					header: 'Status',
				},
				{
					data: 'sup_faq_overview',
					header: 'faq overview',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const qcontDt = $('#qcont_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/support',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getQcontentFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => qcontEdit({ sup_id: 0 }),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#qconttable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			$('#qconttable_container').find('th').addClass('custom-header')

			$('#qconttable_container').on('click', '.show-edit', ({ target }) => {
				let row = qcontDt.row($(event.target).closest('tr')).data()
				qcontEdit(row)
			})

			//Reload table when filters change
			$('#qcont_filters').on('change', () => qcontDt.ajax.reload(undefined, false))

			return qcontDt
		}
		let qcontDt = initQcontDt()

		$("#qcont_insert").on("click", () => qcontEdit({ sup_id: 0 }))

		const clearQForm = () => {
			$('#qcont_container').slideUp(slideSpeed)
			$("#qcont_form :input").val('')
			$("#qcont_scope").val('').trigger("chosen:updated")
			$(".qcont_form").hide()
			$(".qcont_hide").hide()
			$("#qcont_insert").show()
			$('#qcont_global').prop('checked', false)
			$("#qcont_scope").prop('disabled', false).trigger("chosen:updated")
		}

		const qcontEdit = async (qcont) => {
			$("#qcont_insert").hide()
			$('#qcont_container').slideDown(slideSpeed)

			let isInsert = qcont.sup_id == 0
			if (isInsert) { //ADD

				$("#qcont_cancel").show()
				$("#qcont_update").show()
				$(".qcont_form").show()

			} else { //UPDATE
				let postData = { type: "qcont_scope", id: qcont.sup_id }
				const { scope } = await fetchPost('/data/submit', postData)
				scope.forEach(s => { s == 0 || s == '0' ? $('#qcont_global').prop('checked', true) : $('#qcont_global').prop('checked', false) })

				if (qcont.sup_status == QCONT_INACTIVE) {
					$("#qcont_archive").hide()
					$("#qcont_reactivate").show()
				} else {
					$("#qcont_archive").show()
					$("#qcont_reactivate").hide()
				}
				$("#qcont_cancel").show()
				$("#qcont_update").show()
				$(".qcont_form").show()
				$("#qcont_scope").val(scope).trigger("chosen:updated")
				$('#qcont_phone').val(qcont.sup_phone)
				$('#qcont_fax').val(qcont.sup_fax)
				$('#qcont_address').val(qcont.sup_address)
				$('#qcont_description').val(qcont.sup_header)

				//hide scope if global checked
				$("#qcont_global").is(':checked') ? $("#qcont_scope").prop('disabled', true).trigger("chosen:updated") : $("#qcont_scope").prop('disabled', false).trigger("chosen:updated")
			}

			$(".qcont_toggle").off("click").on("click", () => qcontToggle(qcont))

			$("#qcont_cancel").off("click").on("click", () => clearQForm())

			$("#qcont_update").off("click").on("click  ", (e) => {
				let postData = {
					type: "save_qcontent",
					id: qcont.sup_id,
					scope: $('#qcont_global').is(':checked') ? [0] : $('#qcont_scope').val(),
					header: $('#qcont_description').val(),
					phone: $('#qcont_phone').val(),
					fax: $('#qcont_fax').val(),
					address: $('#qcont_address').val(),
				}
				qcontSave(postData)
			})
		}

		const qcontSave = async (postData) => {
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					qcontDt.ajax.reload()
					clearQForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("error saving this content", "danger")
				}
			} catch (e) {
				console.error(e)
			}
		}

		const qcontToggle = async (qcont) => {
			let postData = {
				type: 'toggle_qcontent',
				id: qcont.sup_id,
				status: qcont.sup_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					qcontDt.ajax.reload()
					clearQForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		//print all questions and answers
		$("#print_faqs").off("click").on("click", async (e) => {
			const $btn = $(e.target)
			const $tab = $btn.closest('.tab-pane')

			let config = {
				title: 'Print Event',
				callback: function () {
					let $tabClone = $tab.clone()
					$tabClone.prepend('<h3 class="pb-2 mb-3 mt-2" style="border-bottom: 1px #444 solid">All Frequently Asked Questions</h3>')
					$tabClone.find('#print_faqs').remove()
					return $tabClone
				}
			}
			print_this(config)
		})
	}

	if ($('#questionsTabContent').length) questions()
	if ($('#qcontentTabContent').length) supportContent()
}

function initCalEdit() {

	$("#cal_form").on("change", () => {
		if ($("#cal_global").is(':checked')) {
			$("#cal_scope").prop('disabled', true).trigger('chosen:updated')
		} else {
			$("#cal_scope").prop('disabled', false).trigger('chosen:updated')
		}
	})


	const getCalFilters = () => ({
		status: $('#cal_filterstatus').val(),
		scope: $('#cal_filterscope').val(),
	})
	setCollapse({ $btn: $('#cal_filterscollapse'), $target: $('#cal_filters') })
	setCollapse({ $btn: $('#cal_notifcollapse'), $target: $('#cal_notifications') })

	const initCalDt = () => {
		const columnsInfo = [
			{
				data: 'cal_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'calt_name',
				header: 'Type',
			},
			{
				data: 'cal_scope',
				header: 'Scope',
			},
			{
				data: 'cal_title',
				header: 'Title',
			},
			{
				data: 'cal_datepretty',
				header: 'Date',
			},
			{
				data: 'cal_time',
				header: 'Time',
			},
			{
				data: 'cal_location',
				header: 'Location',
				visible: false,
			},
			{
				data: 'cal_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const calDt = $('#cal_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/cal_edit',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getCalFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => calEdit({ cal_id: 0 }),
				}
			],
			language: {
				emptyTable: "No event to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> events",
				infoEmpty: "Showing 0 to 0 of 0 event",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total events)",
				lengthMenu: "Show _MENU_ events",
			},
			initComplete: () => {
				const $scroll = $('#caledittable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#caledittable_container').find('th').addClass('custom-header')

		$('#caledittable_container').on('click', '.show-edit', ({ target }) => {
			let row = calDt.row($(event.target).closest('tr')).data() as calEvent
			calEdit(row)
		})

		//Reload table when filters change
		$('#cal_filters').on('change', () => calDt.ajax.reload(undefined, false))

		return calDt
	}
	let calDt = initCalDt()

	type calEvent = {
		cal_id: number,
		cal_type: number,
		cal_scope: number,
		cal_status: number,
		cal_title: string,
		cal_date: string,
		cal_time: string,
		cal_location: string,
		cal_email_location: string,
		cal_email_description: string,
		cal_agenda: string,
		cal_material: string,
		cal_minutes: string,
		cal_timezone: string,
	}

	$("#cal_insert").on("click", () => {
		calEdit({ cal_id: 0 })
	})

	const clearCalForm = () => {
		$("#cal_content_container").slideUp(slideSpeed)
		$("#cal_form :input").val('')
		$("#cal_scope").val('').trigger("chosen:updated")
		$('#cal_timezone').val('AKST').trigger("chosen:updated")
		$("#cal_scope").prop('disabled', false).trigger("chosen:updated")
		$(".cal_form").hide()
		$(".cal_hide").hide()
		$("#cal_insert").show()
		$('#cal_global').prop('checked', false)
		$('#cal_include_ics').prop('checked', false)
		$('#cal_type').val('1').trigger("chosen:updated") // set to general meeting type
		$("#material_row").show()
		$("#minutes_row").show()
		$("#agenda_row").show()
		tinymce.get('cal_location').setContent('')
	}

	const getDocuments = async (docIds) => {
		try {
			let postData = { type: "get_doc", data: docIds }
			const { names, rc } = await fetchPost('/data/submit', postData)
			if (rc != "OK") throw rc

			return names
		} catch (error) {
			displayNotification(('Error Fetching Documentss'))
			console.error('get documents', error)
		}
	}

	const getDocName = async (id) => {
		let postData = { type: "get_doc", id: id }
		const { name } = await fetchPost('/data/submit', postData)
		return name
	}

	tinymce.init({
		selector: '#cal_location',
		toolbar: tinyMceTools,
		font_family_formats: tinyMceFonts,
		plugins: tinyMcePlugins,
		promotion: false,
		height: 400,
		content_style: tinyMceDefaultFont,
		browser_spellcheck: true,
		content_css: false,
		relative_urls: false,
		skin: false
	})

	const calEdit = async (calEvent: Partial<calEvent>) => {
		let isInsert = calEvent.cal_id == 0
		$("#cal_content_container").slideDown(slideSpeed)

		if (isInsert) { //ADD

			$("#cal_cancel").show()
			$("#cal_update").show()
			$(".cal_form").show()
			$(".cal_file").show()
			$("#calfile_container").hide()
			$("#cal_notif_container").hide()

		} else { //UPDATE
			let postData = { type: "cal_scope", id: calEvent.cal_id }
			const { scope } = await fetchPost('/data/submit', postData)
			scope.forEach(s => { s == 0 || s == '0' ? $('#cal_global').prop('checked', true) : $('#cal_global').prop('checked', false) })

			if (calEvent.cal_status == CAL_INACTIVE) {
				$("#cal_archive").hide()
				$("#cal_reactivate").show()
			} else {
				$("#cal_archive").show()
				$("#cal_reactivate").hide()
			}

			$("#cal_reactivate").hide()
			$(".cal_file").hide()
			$("#cal_archive").show()
			$("#calfile_container").show()
			$("#cal_cancel").show()
			$("#cal_update").show()
			$(".cal_form").show()
			$("#cal_notif_container").show()
			$("#cal_scope").val(scope).trigger("chosen:updated")
			$('#cal_type').val(calEvent.cal_type).trigger("chosen:updated")
			$('#cal_timezone').val(calEvent.cal_timezone).trigger("chosen:updated")
			$('#cal_title').val(calEvent.cal_title)
			$('#cal_date').val(calEvent.cal_date)
			$('#cal_time').val(calEvent.cal_time)
			$('#cal_email_location').val(calEvent.cal_email_location)
			$('#cal_email_description').val(calEvent.cal_email_description)
			tinymce.get('cal_location').setContent(calEvent.cal_location)

			//replace file
			$("#calfile_agenda_replace").off("click").on("click", () => $("#agenda_file_container").show())
			$("#calfile_material_replace").off("click").on("click", () => $("#material_file_container").show())
			$("#calfile_minutes_replace").off("click").on("click", () => $("#minutes_file_container").show())

			//delete file
			$("#calfile_agenda_delete").off("click").on("click", () => calDeleteFile({ id: calEvent.cal_id, type: CAL_FILE_AGENDA }))
			$("#calfile_material_delete").off("click").on("click", () => calDeleteFile({ id: calEvent.cal_id, type: CAL_FILE_MINUTES }))
			$("#calfile_minutes_delete").off("click").on("click", () => calDeleteFile({ id: calEvent.cal_id, type: CAL_FILE_MATERIAL }))

			interface calDoc {
				$: $ | null
				doc_id: number | null
				doc_name: string
			}
			const docMap: Map<HTMLElement, calDoc> = new Map()

			// calfile table
			const tableData = {
				cal_agenda: calEvent.cal_agenda,
				cal_minutes: calEvent.cal_minutes,
				cal_material: calEvent.cal_material,
			}
			const fileNames = await getDocuments(tableData)

			try {
				$('#calfile_agenda').append(`<div id="calfile_agenda_spinner" class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
					</div>`
				)
				const agendaName = await getDocName(calEvent.cal_agenda)
				$('#calfile_agenda').html(htmlEsc(agendaName))
			} catch (error) {
				console.error('get documents', error)
			} finally {
				$('#calfile_agenda_spinner').show()
			}

			try {
				$('#calfile_material').append(`<div id="calfile_material_spinner" class="spinner-border spinner-border-sm" role="status">
				<span class="visually-hidden">Loading...</span>
				</div>`
				)
				const materialName = await getDocName(calEvent.cal_material)
				$('#calfile_material').html(htmlEsc(materialName))
			} catch (error) {
				console.error('get documents', error)
			} finally {
				$('#calfile_material_spinner').show()
			}

			try {
				$('#calfile_minutes').append(`<div id='calfile_minutes_spinner' class="spinner-border spinner-border-sm" role="status">
					<span class="visually-hidden">Loading...</span>
					</div>`
				)
				const minutesName = await getDocName(calEvent.cal_minutes)
				$('#calfile_minutes').html(htmlEsc(minutesName))
			} catch (error) {
				console.error('get documents', error)
			} finally {
				$('#calfile_minutes_spinner').show()
			}

			//hide scope if global checked
			$("#cal_global").is(':checked') ? $("#cal_scope").prop('disabled', true).trigger('chosen:updated') : $("#cal_scope").prop('disabled', false).trigger('chosen:updated')

			$("#cal_notif_preview").off("click").on("click", (e) => {
				calNotifSend(NOTIF_MODE_PREVIEW)
				e.preventDefault()
			})

			$("#cal_notif_send").off("click").on("click", (e) => {
				confirmDialog({
					dialogTitle: 'Send Notifications',
					bodyText: 'Are you sure you want to send this calendar notification to all subscribers.',
					confirmText: 'Send',
					confirmStyle: 'warning',
					showCancelButton: false,
					confirmFunction: () => calNotifSend(NOTIF_MODE_SEND)
				})
				e.preventDefault()
			})

			const calNotifSend = async (mode) => {
				let $btn: JQuery<HTMLElement>
				mode == NOTIF_MODE_SEND ? $btn = $('#cal_notif_spinner').show() : $btn = $('#cal_notif_preview_spinner').show()
				$btn.show()
				try {
					const location = tinymce.get('cal_location').getContent()
					const postData = {
						id: calEvent.cal_id,
						cal_agenda: calEvent.cal_agenda,
						cal_minutes: calEvent.cal_minutes,
						cal_material: calEvent.cal_material,
						scope: $('#cal_global').is(':checked') ? [0] : $('#cal_scope').val(),
						event_type: $('#cal_type').val(),
						title: $('#cal_title').val(),
						date: $('#cal_date').val(),
						time: $('#cal_time').val(),
						timezone: $('#cal_timezone').val(),
						location: location,
						email_location: $('#cal_email_location').val(),
						email_description: $('#cal_email_description').val(),
						include_ics: $('#cal_include_ics').is(':checked') ? 1 : 0,
						mode: mode,
					}
					const res = await fetchPost('/form/submit', { type: 'cal_notif_send', data: postData })
					if (res.rc != 'OK') throw res.rc

					calDt.ajax.reload()
					clearCalForm()
					displayNotification('Success Sending Notifications', 'success')
				} catch (e) {
					switch (e) {
						case 'NO_PRIV':
							displayNotification('Error, No Access', 'danger')
							break
						case 'NO_DATA':
							displayNotification('Error, Insignificant Data', 'danger')
							break
						case 'EMAIL_FAILED':
							displayNotification('Error, Email Failed', 'danger')
							break
						case 'MULTIPLE_PROGRAMS':
							displayNotification('Error, Please Only Select One Program', 'danger')
							break
						case 'NO_EMAIL':
							displayNotification('Error, No Users to send Notifications', 'danger')
							break
						default:
							displayNotification('Error Sending Notification', 'danger')
							break
					}
				} finally {
					$btn.hide()
				}
			}
		}

		$(".cal_toggle").off("click").on("click", () => calToggle(calEvent))

		$("#cal_cancel").off("click").on("click", () => clearCalForm())

		$("#cal_update").off("click").on("click", () => {
			const location = tinymce.get('cal_location').getContent()
			let postData = {
				id: calEvent.cal_id,
				cal_agenda: calEvent.cal_agenda,
				cal_minutes: calEvent.cal_minutes,
				cal_material: calEvent.cal_material,
				scope: $('#cal_global').is(':checked') ? [0] : $('#cal_scope').val(),
				event_type: $('#cal_type').val(),
				title: $('#cal_title').val(),
				date: $('#cal_date').val(),
				time: $('#cal_time').val(),
				timezone: $('#cal_timezone').val(),
				location: location,
				email_location: $('#cal_email_location').val(),
				email_description: $('#cal_email_description').val(),
			}
			calSave(postData)
		})
	}

	const calDeleteFile = async (postData) => {
		const res = await fetchPost('/data/submit', { type: 'cal_file_delete', data: postData })
		if (res.rc != 'OK') {
			displayNotification('Error deleting file', 'danger')
			return
		}
		calDt.ajax.reload()
		clearCalForm()
		displayNotification('Success deleting file', 'success')
	}

	const calSave = async (postData) => {
		try {
			const formData = new FormData($('#cal_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('type', 'cal_save')

			// Check file sizes
			let allFilesBelow7MB = true
			for (let [key, value] of formData.entries()) {
				if (value instanceof File) {
					if (value.size >= 7 * 1024 * 1024) {  // Updated size limit to 7MB
						allFilesBelow7MB = false;
						break;
					}
				}
			}

			if (!allFilesBelow7MB) {
				displayNotification("All files must be smaller than 7MB", "danger");  // Updated message
				return;
			}

			const res = await fetchPost('/marketing/submit', formData)
			if (res.rc === 'OK') {
				displayNotification("Event Saved Successfully", "success")
				calDt.ajax.reload()
				clearCalForm()
			} else {
				displayNotification("error saving this event", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const calToggle = async (calEvent) => {
		let postData = {
			type: 'cal_archive',
			id: calEvent.cal_id,
			status: calEvent.cal_status,
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Event Status Changed Successfully", "success")
				calDt.ajax.reload()
				clearCalForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}
}

function initDocuments() {

	$("#doc_form").on("change", () => {
		if ($("#doc_global").is(':checked')) {
			$('#doc_scope').prop('disabled', true).trigger("chosen:updated")
		} else {
			$('#doc_scope').prop('disabled', false).trigger("chosen:updated")
		}
	})

	const getDocFilters = () => ({
		status: $('#doc_filterstatus').val(),
		scope: $('#doc_filterscope').val()
	})
	setCollapse({ $btn: $('#doc_filterscollapse'), $target: $('#doc_filters') })

	const initDocDt = () => {
		const columnsInfo = [
			{
				data: 'doc_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'doc_original',
				header: 'Name',
			},
			{
				data: 'doc_scope',
				header: 'Scope',
			},
			{
				data: 'doct_name',
				header: 'Type',
			},
			{
				data: 'doc_startpretty',
				header: 'Start',
			},
			{
				data: 'doc_endpretty',
				header: 'End',
			},
			{
				data: 'doc_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const docDt = $('#doc_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/documents',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getDocFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => docEdit({ doc_id: 0 }),
				}
			],
			language: {
				emptyTable: "No document to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> documents",
				infoEmpty: "Showing 0 to 0 of 0 document",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total documents)",
				lengthMenu: "Show _MENU_ documents",
			},
			initComplete: () => {
				const $scroll = $('#doctable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#doctable_container').find('th').addClass('custom-header')

		//Reload table when filters change
		$('#doc_filters').on('change', () => docDt.ajax.reload(undefined, false))

		$('#doctable_container').off('click').on('click', '.show-edit', ({ target }) => {
			let row = docDt.row($(event.target).closest('tr')).data() as doc
			docEdit(row)
		})

		return docDt
	}
	let docDt = initDocDt()

	type doc = {
		doc_id: number,
		doc_type: number,
		doc_scope: number,
		doc_original: string,
		doc_doctid: string,
		doc_status: number,
		doc_startdate: string,
		doc_enddate: string,
		doct_name: string | null,
		doc_link: string | null,
		doc_visible: number

	}

	$("#doc_insert").on("click", () => docEdit({ doc_id: 0 }))

	const clearDocForm = () => {
		$("#doc_container").slideUp(slideSpeed)
		$("#doc_form :input").val('')
		$(".doc_form").hide()
		$(".hide").hide()
		$("#doc_insert").show()
		$("#doc_scope").val('').trigger("chosen:updated")
		$("#doc_type").val('0').trigger("chosen:updated")
		$('#doc_global').prop('checked', false)
		$('#doc_visible').prop('checked', false)
		$("#doc_scope").prop('disabled', false).trigger("chosen:updated")
	}

	const docEdit = async (doc: Partial<doc>) => {
		let isInsert = doc.doc_id == 0
		$("#doc_container").slideDown(slideSpeed)

		if (isInsert) { //ADD

			$("#doc_cancel").show()
			$("#doc_update").show()
			$(".doc_form").show()
			$("#doc_file").show()
			$("#docfile_container").hide()
			$("#doc_archive").hide()

		} else { //UPDATE
			let postData = { type: "doc_scope", id: doc.doc_id }
			const { scope } = await fetchPost('/data/submit', postData)
			scope.forEach(s => { s == 0 || s == '0' ? $('#doc_global').prop('checked', true) : $('#doc_global').prop('checked', false) })

			doc.doc_visible == 0 ? $('#doc_visible').prop('checked', true) : $('#doc_visible').prop('checked', false)
			$("#doc_global").is(':checked') ? $('#doc_scope').prop('disabled', true).trigger("chosen:updated") : $('#doc_scope').prop('disabled', false).trigger("chosen:updated")

			if (doc.doc_status == DOC_INACTIVE) {
				$("#doc_archive").hide()
				$("#doc_reactivate").show()
			} else {
				$("#doc_archive").show()
				$("#doc_reactivate").hide()
			}

			$(".doc_form").show()
			$("#doc_file").hide()
			$("#docfile_container").show()
			$("#doc_cancel").show()
			$("#doc_update").show()
			$("#doc_scope").val(scope).trigger("chosen:updated")
			$('#doc_type').val(Number(doc.doc_doctid)).trigger("chosen:updated")
			$('#doc_start').val(String(doc.doc_startdate))
			$('#doc_end').val(String(doc.doc_enddate))

			//docfile table
			$('#docfile_original').html(htmlEsc(String(doc.doc_original)))
			$('#docfile_type').html(htmlEsc(doc.doct_name ? doc.doct_name : null))
			$('#docfile_link').html(htmlEsc(String(doc.doc_link)))

			$("#docfile_change").off("click").on("click", () => {
				$("#docfile_container").hide()
				$("#doc_file").show()
			})
		}

		let newOrder = []
		const loadDocSettings = async (doc) => {
			const $spinner = $('#docSetLoadingSpinner').show()
			const $docSetContent = $('#docSetContent').hide()
			try {
				// Fetch current document types from the server
				const res = await fetchPost('/data/submit', { type: 'get_doct_info' })
				if (res.rc != 'OK') throw new Error('Failed to fetch document types')
				const docTypes = res.doc_types

				// Clear the current list
				const $sortableList = $('#sortableList')
				$sortableList.empty()

				// Populate the list with fetched document types
				docTypes.forEach(docType => {
					$sortableList.append(`
						<li class="list-group-item" data-id="${docType.doct_id}" data-sort="${docType.doct_sort}">
							<div class="d-flex justify-content-between">
								<p>${docType.doct_name}</p>
								<button class="edit-doc-name btn btn-primary btn-sm">Edit</button>
							</div>
						</li>
					`)
				})

				const sortable = new Sortable($sortableList[0], {
					onUpdate: function (evt) {
						newOrder = sortable.toArray() // Update the new order of list items
					},
					onStart: function (evt) {
						evt.item.classList.add('dragging');
						evt.item.classList.add('active');
					},
					onEnd: function (evt) {
						evt.item.classList.remove('dragging');
						evt.item.classList.remove('active');
					},
				})

			} catch (error) {
				console.error(error);
				displayNotification('Failure Loading Document Type Settings', 'danger')
			} finally {
				$spinner.hide()
				$docSetContent.show()
			}
		}

		$("#docSettingsOpen").off("click").on("click", () => {
			console.log('opening document settings modal')
			const $modal = $('#docSetModal').modal('show')
			loadDocSettings(doc)

			$('#docSetSubmit').off('click').on('click', async () => {
				const $spinner = $('#docSetSpinner').show()
				try {
					const postData = { order: newOrder }
					const res = await fetchPost('/data/submit', { type: 'doct_sort_save', data: postData })
					if (res.rc != 'OK') throw res.rc

					$modal.modal('hide')
					setTimeout(() => displayNotification('Saved Document Type Settings', 'success'), 500)
				} catch (error) {
					console.error(error)
					displayNotification('Failure Saving Generate Rate Settings', 'danger')
				} finally {
					$spinner.hide()
				}
			})
		})

		$('#sortableList').on('click', '.edit-doc-name', function () {
			console.log('edit doc name')
			const $docItem = $(this).closest('.list-group-item') // getting the list item
			const $docName = $docItem.find('p') // get the paragraph inside the list item
			const originalName = $docName.text().trim()

			// Store original name in the 'data-original-name' attribute of the list item
			$docItem.data('original-name', originalName)

			// Replace doc-name span with an input
			$docName.replaceWith(`<input type="text" class="doc-name-input form-control" id="doc-name-input" value="${originalName}" style="display: inline-block; width: auto;">`)

			// Focus the new input and select all text
			const $docNameInput = $('.doc-name-input')
			$docNameInput.focus().select()
		})

		$('#sortableList').on('blur keypress', '.doc-name-input', function (e) {
			if (e.type === 'blur' || e.which === 13) {
				const newName = String($(this).val()).trim()
				const $docItem = $(this).closest('.list-group-item') // getting the list item

				// Get the original name from the 'data-original-name' attribute of the list item
				const originalName = $docItem.data('original-name')

				if (newName === '') return displayNotification('Document Type Name Cannot Be Empty', 'danger')

				if (newName == originalName) {
					console.log('name unchanged')
					$(this).replaceWith(`<p>${newName}</p>`)
					return
				}

				// Replace input with a span
				$(this).replaceWith(`<p>${newName}</p>`)

				// Save new name to the database
				const doctId = $docItem.data('id') // get the 'data-id' attribute from the list item
				const postData = {
					doct_id: doctId,
					doct_name: newName
				}
				saveDocName(postData)
			}
		})

		const saveDocName = async (postData) => {
			try {
				const res = await fetchPost('/data/submit', { type: 'doct_name_save', data: postData })
				if (res.rc != 'OK') throw res.rc
				displayNotification('Saved Document Type Name', 'success')
			} catch (error) {
				console.error(error)
				displayNotification('Failure Saving Document Type Name', 'danger')
			}
		}

		$(".doc_toggle").off("click").on("click", async (e) => {
			e.preventDefault()
			try {
				let postData = {
					id: doc.doc_id,
					status: doc.doc_status,
					type: 'doc_toggle',
				}
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Document Status Changed Successfully", "success")
					docDt.ajax.reload()
					clearDocForm()
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		})

		$("#doc_cancel").off("click").on("click", (e) => {
			e.preventDefault()
			clearDocForm()
		})

		$("#doc_update").off("click").on("click", (e) => {
			let postData = {
				id: doc.doc_id,
				scope: $('#doc_global').is(':checked') ? [0] : $('#doc_scope').val(),
				visible: $('#doc_visible').is(':checked') ? DOC_VISIBLE : DOC_HIDDEN,
				type: $('#doc_type').val(),
				start: $('#doc_start').val(),
				end: $('#doc_end').val(),
			}
			docSave(postData)
		})
	}

	const docSave = async (postData) => {
		const formData = new FormData($('#doc_form').get(0) as HTMLFormElement)
		formData.append('data', JSON.stringify(postData))
		formData.append('type', 'doc_save')

		let allFilesBelow7MB = true;
		for (let [key, value] of formData.entries()) {
			if (value instanceof File) {
				if (value.size >= 7 * 1024 * 1024) {  // Updated size limit to 7MB
					allFilesBelow7MB = false;
					break;
				}
			}
		}

		if (!allFilesBelow7MB) {
			displayNotification("All files must be smaller than 7MB", "danger");  // Updated message
			return;
		}

		try {
			const res = await fetchPost('/marketing/submit', formData)
			if (res.rc === 'OK') {
				displayNotification("Document Saved Successfully", "success")
				docDt.ajax.reload()
				clearDocForm()
			} else {
				displayNotification("error saving this document", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const docToggle = async (doc) => {
		let postData = {
			id: doc.doc_id,
			status: doc.doc_status,
			type: 'doc_toggle',
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Document Status Changed Successfully", "success")
				docDt.ajax.reload()
				clearDocForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const initDocHelpDt = () => {
		const columnsInfo = [
			{
				data: 'doc_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'doc_original',
				header: 'Name',
			},
			{
				data: 'doct_name',
				header: 'Type',
			},
			{
				data: 'doc_link',
				header: 'Link',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		$('.docinfo_table').each((_, element) => {
			const $table = $(element)
			const docDt = $table.tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/documents',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getDocFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				searching: false,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No document to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> documents",
					infoEmpty: "Showing 0 to 0 of 0 document",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total documents)",
					lengthMenu: "Show _MENU_ documents",
				},
				deferLoading: 0,
			})
			$table.closest('.modal-body').find('.doc_tab').on('show.bs.tab', () => docDt.ajax.reload(null, false))
		})
	}

	initDocHelpDt()
}

function initContent() {
	payers()
	providers()
	home()
	prog()

	function payers() {

		$("#payers_form").on("change", () => {
			if ($("#payers_global").is(':checked')) {
				$("#payers_scope").prop('disabled', true).trigger("chosen:updated")
			} else {
				$("#payers_scope").prop('disabled', false).trigger("chosen:updated")
			}
		})

		const getPayerFilters = () => ({
			status: $('#payers_filterstatus').val(),
			scope: $('#payers_filterscope').val(),
		})
		setCollapse({ $btn: $('#payers_filterscollapse'), $target: $('#payers_filters') })

		const initPayerDt = () => {
			const columnsInfo = [
				{
					data: 'markpayer_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'markpayer_overview',
					header: 'Overview',
					visible: false,
				},
				{
					data: 'markpayer_support',
					header: 'Support',
					visible: false,
				},
				{
					data: 'markpayer_heading',
					header: 'Content Description',
				},
				{
					data: 'markpayer_scope',
					header: 'Scope',
				},
				{
					data: 'markpayer_statuspretty',
					header: 'Status',
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const payerDt = $('#payers_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/payers',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getPayerFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => payerEdit({ markpayer_id: 0 }),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#payerstable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			$('#payerstable_container').find('th').addClass('custom-header')

			$('#payerstable_container').off('click').on('click', '.show-edit', ({ target }) => {
				let row = payerDt.row($(event.target).closest('tr')).data()
				payerEdit(row)
			})

			//Reload table when filters change
			$('#payers_filters').on('change', () => {
				payerDt.ajax.reload(undefined, false)
			})

			return payerDt
		}
		let payerDt = initPayerDt()

		const clearPayerforn = () => {
			$('#payers_container').slideUp(slideSpeed)
			$("#payers_form :input").val('')
			$("#payers_scope").val('').trigger("chosen:updated")
			$(".hide").hide()
			$(".payers_form").hide()
			$(".payers_toggle").hide()
			$("#payers_insert").show()
			$('#payers_global').prop('checked', false)
			$("#payersfile_container").hide()
			$("#payers_scope").prop('disabled', false).trigger("chosen:updated")

			tinymce.get('payers_support').setContent('')
			tinymce.get('payers_overview').setContent('')
			tinymce.get('payers_assessment').setContent('')
		}

		tinymce.init({
			selector: '#payers_support',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 300,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			relative_urls: false,
			content_css: false,
			skin: false
		})

		tinymce.init({
			selector: '#payers_overview',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 300,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		tinymce.init({
			selector: '#payers_assessment',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 300,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		const payerEdit = async (payer) => {
			let isInsert = payer.markpayer_id == 0
			$('#payers_container').slideDown(slideSpeed)

			if (isInsert) { //ADD

				$("#payers_cancel").show()
				$("#payers_update").show()
				$(".payers_form").show()

			} else { //UPDATE
				let postData = { type: "payer_scope", id: payer.markpayer_id }
				const { scope } = await fetchPost('/data/submit', postData)
				scope.forEach(s => { s == 0 || s == '0' ? $('#payers_global').prop('checked', true) : $('#payers_global').prop('checked', false) })

				if (payer.markpayer_status == PAYER_INACTIVE) {
					$("#payers_archive").hide()
					$("#payers_reactivate").show()
				} else {
					$("#payers_archive").show()
					$("#payers_reactivate").hide()
				}

				$("#payers_file").hide()
				$("#payersfile_container").show()
				$("#payers_cancel").show()
				$("#payers_update").show()
				$(".payers_form").show()
				$("#payers_scope").val(scope).trigger("chosen:updated")
				$('#payers_heading').val(payer.markpayer_heading)
				$('#payers_overview').val(payer.markpayer_overview)
				$('#payers_support').val(payer.markpayer_support)
				$('#payers_assessment').val(payer.markpayer_assessment)

				tinymce.get('payers_support').setContent(payer.markpayer_support)
				tinymce.get('payers_overview').setContent(payer.markpayer_overview)
				tinymce.get('payers_assessment').setContent(payer.markpayer_assessment)

				$('#payersfile_original').html(htmlEsc(payer.img_originalname))

				$("#payersfile_change").off("click").on("click", () => {
					$("#payersfile_container").hide()
					$("#payers_file").show()
				})

				//hide scope if global checked
				$("#payers_global").is(':checked') ? $("#payers_scope").prop('disabled', true).trigger("chosen:updated") : $("#payers_scope").prop('disabled', false).trigger("chosen:updated")
			}

			$(".payers_toggle").off("click").on("click", () => payerToggle(payer))

			$("#payers_cancel").off("click").on("click", () => clearPayerforn())

			$("#payers_update").off("click").on("click", (e) => {
				const support = tinymce.get('payers_support').getContent(payer.markpayer_support)
				const overview = tinymce.get('payers_overview').getContent(payer.markpayer_overview)
				const assessment = tinymce.get('payers_assessment').getContent(payer.markpayer_assessment)

				let postData = {
					id: payer.markpayer_id,
					scope: $('#payers_global').is(':checked') ? [0] : $('#payers_scope').val(),
					header: $('#payers_heading').val(),
					overview: overview,
					assessment: assessment,
					support: support,
				}
				payerSave(postData)
				e.preventDefault()
			})
		}

		const payerSave = async (postData) => {
			try {
				const formData = new FormData($('#payers_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_payer')
				const res = await fetchPost('/marketing/submit', formData)
				if (res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					payerDt.ajax.reload()
					clearPayerforn()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("error saving this payer information", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		const payerToggle = async (payer) => {
			let postData = {
				type: 'toggle_payer',
				id: payer.markpayer_id,
				status: payer.markpayer_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					payerDt.ajax.reload()
					clearPayerforn()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function providers() {

		$("#prov_form").on("change", () => {
			if ($("#prov_global").is(':checked')) {
				$("#prov_scope").prop('disabled', true).trigger('chosen:updated')
			} else {
				$("#prov_scope").prop('disabled', false).trigger('chosen:updated')
			}
		})

		const getProvFilters = () => ({
			status: $('#prov_filterstatus').val(),
			scope: $('#prov_filterscope').val(),
		})
		setCollapse({ $btn: $('#prov_filterscollapse'), $target: $('#prov_filters') })

		const initProvDt = () => {
			const columnsInfo = [
				{
					data: 'prov_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prov_header',
					header: 'Content Description',
				},
				{
					data: 'prov_scope',
					header: 'Scope',
				},
				{
					data: 'prov_statuspretty',
					header: 'Status',
				},
				{
					data: 'prov_description',
					header: 'Description',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const provDt = $('#prov_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/providers',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getProvFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => provEdit({ prov_id: 0 }),
					}
				],
				language: {
					emptyTable: "No content to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> content",
					infoEmpty: "Showing 0 to 0 of 0 content",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total content)",
					lengthMenu: "Show _MENU_ content",
				},
				initComplete: () => {
					const $scroll = $('#provtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			$('#provtable_container').find('th').addClass('custom-header')

			$('#provtable_container').off('click').on('click', '.show-edit', ({ target }) => {
				let row = provDt.row($(event.target).closest('tr')).data()
				provEdit(row)
			})

			//Reload table when filters change
			$('#prov_filters').on('change', () => {
				provDt.ajax.reload(undefined, false)
			})

			return provDt
		}
		let provDt = initProvDt()

		const clearProvForm = () => {
			$("#prov_container").slideUp(slideSpeed)
			$("#prov_form :input").val('')
			$("#prov_scope").val('').trigger("chosen:updated")
			$(".prov_form").hide()
			$(".prov_hide").hide()
			$("#prov_insert").show()
			$('#prov_global').prop('checked', false)
			$("#prov_scope").prop('disabled', false).trigger("chosen:updated")

			tinymce.get('prov_description').setContent('')
		}

		tinymce.init({
			selector: '#prov_description',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		const provEdit = async (prov) => {
			let isInsert = prov.prov_id == 0
			$("#prov_container").slideDown(slideSpeed)

			if (isInsert) { //ADD

				$("#prov_cancel").show()
				$("#prov_update").show()
				$(".prov_form").show()

			} else { //UPDATE
				let postData = { type: "prov_scope", id: prov.prov_id }
				const { scope } = await fetchPost('/data/submit', postData)
				scope.forEach(s => { s == 0 || s == '0' ? $('#prov_global').prop('checked', true) : $('#prov_global').prop('checked', false) })

				if (prov.prov_status == PROV_INACTIVE) {
					$("#prov_archive").hide()
					$("#prov_reactivate").show()
				} else {
					$("#prov_archive").show()
					$("#prov_reactivate").hide()
				}

				$("#prov_file").hide()
				$("#provfile_container").show()
				$("#prov_cancel").show()
				$("#prov_update").show()
				$(".prov_form").show()
				$("#prov_scope").val(scope).trigger("chosen:updated")
				$('#prov_header').val(prov.prov_header)
				tinymce.get('prov_description').setContent(prov.prov_description)

				$("#provfile_change").off("click").on("click", () => {
					$("#provfile_container").hide()
					$("#prov_file").show()
				})

				$("#provfile_original").html(htmlEsc(prov.img_originalname))

				//hide scope if global checked
				$("#prov_global").is(':checked') ? $("#prov_scope").prop('disabled', true).trigger("chosen:updated") : $("#prov_scope").prop('disabled', false).trigger("chosen:updated")
			}

			$(".prov_toggle").off("click").on("click", (e) => {
				provToggle(prov)
				e.preventDefault()
			})

			$("#prov_cancel").off("click").on("click", (e) => {
				clearProvForm()
				e.preventDefault()
			})

			$("#prov_update").off("click").on("click", (e) => {
				const description = tinymce.get('prov_description').getContent()
				let postData = {
					type: "save_prov",
					id: prov.prov_id,
					scope: $('#prov_global').is(':checked') ? [0] : $('#prov_scope').val(),
					header: $('#prov_header').val(),
					description: description,
				}
				provSave(postData)
				e.preventDefault()
				e.stopImmediatePropagation()
			})
		}

		const provSave = async (postData) => {
			try {
				const formData = new FormData($('#prov_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_prov')
				const res = await fetchPost('/marketing/submit', formData)
				if (res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					provDt.ajax.reload()
					clearProvForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("error saving content", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		const provToggle = async (prov) => {
			let postData = {
				type: 'toggle_prov',
				id: prov.prov_id,
				status: prov.prov_status,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Content Status Changed Successfully", "success")
					provDt.ajax.reload()
					clearProvForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function home() {
		tinymce.init({
			selector: '#home_description',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		tinymce.init({
			selector: '#home_about',
			toolbar: tinyMceTools,
			font_family_formats: tinyMceFonts,
			plugins: tinyMcePlugins,
			promotion: false,
			height: 400,
			content_style: tinyMceDefaultFont,
			browser_spellcheck: true,
			content_css: false,
			relative_urls: false,
			skin: false
		})

		$("#home_form").on("change", () => {
			if ($("#home_global").is(':checked')) {
				$('#home_scope').prop('disabled', true).trigger('chosen:updated')
			} else {
				$('#home_scope').prop('disabled', false).trigger('chosen:updated')
			}
		})

		const getHomeFilters = () => ({
			status: $('#home_filterstatus').val(),
			scope: $('#home_filterscope').val(),
		})
		setCollapse({ $btn: $('#home_filterscollapse'), $target: $('#home_filters') })

		const initHomeDt = () => {
			const columnsInfo = [
				{
					data: 'home_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'home_scope',
					header: 'Scope',
				},
				{
					data: 'home_contdesc',
					header: 'Content Description',
				},
				{
					data: 'home_startpretty',
					header: 'Start Date',
				},
				{
					data: 'home_endpretty',
					header: 'End Date',
				},
				{
					data: 'home_statuspretty',
					header: 'Status',
				},
				{
					data: 'home_description',
					header: 'Description',
					visible: false,
				},
				{
					data: 'home_about',
					header: 'About Overview',
					visible: false,
				},
				{
					data: 'home_member',
					header: 'Member Overview',
					visible: false,
				},
				{
					data: 'home_attributes',
					header: 'Attributes',
					visible: false,
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const homeDt = $('#home_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/home',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getHomeFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => homeEdit({ home_id: 0 }),
					}
				],
				language: {
					emptyTable: "No program to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> program",
					infoEmpty: "Showing 0 to 0 of 0 programs",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total program)",
					lengthMenu: "Show _MENU_ programs",
				},
				initComplete: () => {
					const $scroll = $('#hometable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			$('#hometable_container').find('th').addClass('custom-header')

			$('#hometable_container').off('click').on('click', '.show-edit', ({ target }) => {
				let row = homeDt.row($(event.target).closest('tr')).data()
				homeEdit(row)
			})

			//Reload table when filters change
			$('#home_filters').on('change', () => {
				homeDt.ajax.reload(undefined, false)
			})

			return homeDt
		}
		let homeDt = initHomeDt()

		const clearHomeForm = () => {
			$("#home_container").slideUp(slideSpeed)
			$("#home_form :input").val('')
			$("#home_scope").val('').trigger("chosen:updated")
			$(".home_form").hide()
			$(".home_hide").hide()
			$("#home_insert").show()
			$('#home_global').prop('checked', false)
			$("#home_scope").prop('disabled', false).trigger("chosen:updated")
			$("#home_aboutimg").show()
			$("#home_backimg").show()
			$("#homefile_container").hide()

			tinymce.get('home_description').setContent('')
			tinymce.get('home_about').setContent('')
		}

		const getImageName = async (id) => {
			let postData = { type: 'get_img', data: id }
			const { name } = await fetchPost('/data/submit', postData)
			return name
		}

		const homeEdit = async (home) => {
			let isInsert = home.home_id == 0
			$("#home_container").slideDown(slideSpeed)
			if (isInsert) { //ADD

				$("#home_cancel").show()
				$("#home_save").show()
				$(".home_form").show()

			} else { //UPDATE
				let postData = { type: "home_scope", id: home.home_id }
				const { scope } = await fetchPost('/data/submit', postData)
				scope.forEach(s => { s == 0 || s == '0' ? $('#home_global').prop('checked', true) : $('#home_global').prop('checked', false) })

				if (home.home_status == HOME_INACTIVE) {
					$("#home_archive").hide()
					$("#home_reactivate").show()
				} else {
					$("#home_archive").show()
					$("#home_reactivate").hide()
				}

				$("#home_cancel").show()
				$("#home_save").show()
				$(".home_form").show()
				$("#home_scope").val(scope).trigger("chosen:updated")
				$('#home_contdesc').val(home.home_contdesc)
				$('#home_title').val(home.home_title)
				$('#home_start').val(home.home_start)
				$('#home_end').val(home.home_end)

				tinymce.get('home_description').setContent(home.home_description)
				tinymce.get('home_about').setContent(home.home_about)

				if (home.home_aboutimg || home.home_backgroundimg) {
					$("#home_aboutimg").hide()
					$("#home_backimg").hide()

					const about_text = await getImageName(home.home_aboutimg)
					const background_text = await getImageName(home.home_backgroundimg)
					$("#aboutfile_original").html(htmlEsc(about_text))
					$("#homefile_original").html(htmlEsc(background_text))

					$("#homefile_container").show()
					$("#replace_home_about").off("click").on("click", (e) => {
						e.preventDefault()
						$("#home_aboutimg").show()
					})

					$("#replace_background").off("click").on("click", (e) => {
						e.preventDefault()
						$("#home_backimg").show()
					})
				}

				//hide scope if global checked
				$("#home_global").is(':checked') ? $('#home_scope').prop('disabled', true).trigger('chosen:updated') : $('#home_scope').prop('disabled', false).trigger('chosen:updated')
			}

			$(".home_toggle").off("click").on("click", (e) => {
				homeToggle(home)
				e.preventDefault()
			})

			$("#home_cancel").off("click").on("click", (e) => {
				clearHomeForm()
				e.preventDefault()
			})

			$("#home_save").off("click").on("click", (e) => {
				e.preventDefault()
				const home_description = tinymce.get("home_description").getContent()
				const home_about = tinymce.get("home_about").getContent()
				let postData = {
					id: home.home_id,
					img_about: home.home_aboutimg,
					img_background: home.home_backgroundimg,
					file_type: UPLOAD_IMG,
					scope: $('#home_global').is(':checked') ? [0] : $('#home_scope').val(),
					cdescription: $('#home_contdesc').val(),
					title: $('#home_title').val(),
					start: $('#home_start').val(),
					end: $('#home_end').val(),
					attributes: $('#home_attributes').val(),
					description: home_description,
					about: home_about,
					member: $('#home_member').val(),
				}

				homeSave(postData)
			})
		}

		const homeSave = async (postData) => {
			try {
				const formData = new FormData($('#home_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_home')
				const res = await fetchPost('/marketing/submit', formData)
				if (res.rc === 'OK') {
					displayNotification("Content Saved Successfully", "success")
					homeDt.ajax.reload()
					clearHomeForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				}
				else {
					displayNotification("error saving this content", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		const homeToggle = async (home) => {
			let postData = {
				type: 'toggle_home',
				id: home.home_id,
				status: home.home_status
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Status Changed Successfully", "success")
					homeDt.ajax.reload()
					clearHomeForm()
				} else if (res.rc === 'SCOPE_TAKEN') {
					displayNotification("Content already available for this scope", "danger")
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}

	function prog() {
		const initprogDt = () => {
			const columnsInfo = [
				{
					data: 'prog_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prog_name_long',
					header: 'Name',
				},
				{
					data: 'prog_name',
					header: 'Abbreviation',
				},
				{
					data: 'prog_taxid',
					header: 'Tax Id',
				},
				{
					data: 'prog_email',
					header: 'Email',
				},
				{
					header: 'Actions',
					data: '',
					orderable: false,
					searchable: false,
					defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const progDt = $('#prog_table').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/programs',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				dom: datatablesButtonDom,
				buttons: [
					{
						text: '<i class="fas fa-plus me-2"></i>Add',
						className: 'btn btn-sm btn-success',
						action: () => progEdit({ prog_id: 0 }),
					}
				],
				language: {
					emptyTable: "No program to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> program",
					infoEmpty: "Showing 0 to 0 of 0 programs",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total program)",
					lengthMenu: "Show _MENU_ programs",
				},
				initComplete: () => {
					const $scroll = $('#progtable_container').find('.dataTables_scroll')
					const $scrollBody = $scroll.find('.dataTables_scrollBody')
					$scrollBody.doubleScroll({
						$insertBefore: $scroll,
					})
				},
			})

			$('#progtable_container').find('th').addClass('custom-header')

			$('#progtable_container').on('click', '.show-edit', ({ target }) => {
				let row = progDt.row($(event.target).closest('tr')).data()
				progEdit(row)
			})

			return progDt
		}

		let progDt = initprogDt()

		const clearprogForm = () => {
			$("#prog_container").slideUp(slideSpeed)
			$("#prog_form :input").val('')
			$("#prog_scope").val('').trigger("chosen:updated")
			$(".prog_form").hide()
			$(".prog_hide").hide()
			$("#prog_insert").show()
			$("#prog_logo").show()
			$("#proglogo_container").hide()
		}

		const progEdit = async (prog) => {
			let isInsert = prog.prog_id == 0
			$("#prog_container").slideDown(slideSpeed)

			if (isInsert) { //ADD

				$("#prog_cancel").show()
				$("#prog_save").show()
				$(".prog_form").show()

			} else { //UPDATE

				$("#prog_logo").hide()
				$("#proglogo_container").show()
				$("#prog_cancel").show()
				$("#prog_save").show()
				$(".prog_form").show()
				$('#prog_namelong').val(prog.prog_name_long)
				$('#prog_nameshort').val(prog.prog_name)
				$('#prog_taxid').val(prog.prog_taxid)
				$('#prog_tax').val(prog.prog_taxid)
				$('#prog_email').val(prog.prog_email)
				$('#prog_color').val(prog.prog_color)

				//docfile table
				$('#proglogo_original').html(htmlEsc(prog.logo_original))
				$('#proglogo_size').html(htmlEsc(prog.logo_size))

				$("#proglogo_change").off("click").on("click", () => {
					$("#proglogo_container").hide()
					$("#prog_logo").show()
				})
			}

			$(".prog_toggle").off("click").on("click", (e) => {
				progToggle(prog)
				e.preventDefault()
			})

			$("#prog_cancel").off("click").on("click", (e) => {
				clearprogForm()
				e.preventDefault()
			})

			$("#prog_save").off("click").on("click", (e) => {
				e.preventDefault()
				let postData = {
					type: "save_prog",
					id: prog.prog_id,
					file_type: UPLOAD_IMG,
					name: $('#prog_nameshort').val(),
					name_long: $('#prog_namelong').val(),
					logo: $('#prog_logo').val(),
					tax: $('#prog_tax').val(),
					email: $('#prog_email').val(),
					color: $('#prog_color').val(),
				}
				progSave(postData)
			})
		}

		const progSave = async (postData) => {
			try {
				const formData = new FormData($('#prog_form').get(0) as HTMLFormElement)
				formData.append('data', JSON.stringify(postData))
				formData.append('type', 'save_prog')
				const res = await fetchPost('/marketing/submit', formData)
				if (res.rc === 'OK') {
					displayNotification("Program Saved Successfully", "success")
					progDt.ajax.reload()
					clearprogForm()
				} else {
					displayNotification("error saving this program", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}

		const progToggle = async (prog) => {
			let postData = {
				type: 'prog_toggle',
				id: prog.sup_id,
				status: prog.sup_id,
			}
			try {
				const res = await fetchPost('/marketing/submit', postData)
				if (res.rc === 'OK') {
					displayNotification("Status Changed Successfully", "success")
					progDt.ajax.reload()
				} else {
					displayNotification("Error Changing Status", "danger")
				}
			} catch (e) {
				console.log(e)
			}
		}
	}
}

function initUsers() {

	$("#user_form").on("change", () => {
		if ($("#user_super").is(':checked')) { //super user

			$("#user_role").prop('disabled', true).trigger('chosen:updated')
			$("#user_program_access").prop('disabled', true).trigger('chosen:updated')
			$("#user_programs_access").prop('disabled', true).trigger('chosen:updated')
			$("#user_global").prop('disabled', true)
			$("#user_global_warning").show()

		} else { //regular
			$("#user_role").prop('disabled', false).trigger('chosen:updated')
			$("#user_program_access").prop('disabled', false).trigger('chosen:updated')
			$("#user_program_access").prop('disabled', false).trigger('chosen:updated')
			$("#user_global").prop('disabled', false)
			$("#user_global_warning").hide()

			if ($("#user_global").is(':checked')) { //regular - global access  
				$("#user_program_access").prop('disabled', true).trigger('chosen:updated')
			}
		}
	})

	const getUserFilters = () => ({
		status: $('#user_filterstatus').val(),
	})
	setCollapse({ $btn: $('#user_filterscollapse'), $target: $('#user_filters') })

	const initUserDt = () => {
		const columnsInfo = [
			{
				data: 'user_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'role_pretty',
				header: 'Role',
			},
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last Name',
			},
			{
				data: 'user_startpretty',
				header: 'Start',
			},
			{
				data: 'user_endpretty',
				header: 'End',
			},
			{
				data: 'user_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"><i class="fa-solid fa-pen-to-square"></i> Edit</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const userDt = $('#user_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/users',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getUserFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			dom: datatablesButtonDom,
			buttons: [
				{
					text: '<i class="fas fa-plus me-2"></i>Add',
					className: 'btn btn-sm btn-success',
					action: () => userEdit({ user_id: 0 }),
				}
			],
			language: {
				emptyTable: "No user to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> user",
				infoEmpty: "Showing 0 to 0 of 0 users",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total user)",
				lengthMenu: "Show _MENU_ users",
			},
			initComplete: () => {
				const $scroll = $('#usertable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#usertable_container').find('th').addClass('custom-header')

		//Reload table when filters change
		$('#user_filters').on('change', () => userDt.ajax.reload(undefined, false))

		$('#usertable_container').off('click').on('click', '.show-edit', ({ target }) => {
			let row = userDt.row($(event.target).closest('tr')).data()
			userEdit(row)
		})

		return userDt
	}
	let userDt = initUserDt()

	const clearuserForm = () => {
		$("#user_content_container").slideUp(slideSpeed)
		$("#user_form :input").val('')
		$("#user_role").val('').trigger("chosen:updated")
		$("#user_program_access").val('').trigger("chosen:updated")
		$("#user_program_access").prop('disabled', false).trigger("chosen:updated")
		$("#user_state").val('').trigger("chosen:updated")
		$(".user_form").hide()
		$(".user_hide").hide()
		$("#user_insert").show()
		$('#user_global').prop('checked', false)
		$('#user_super').prop('checked', false)
		$('#user_global').prop('disabled', false)
		$("#user_role").prop('disabled', false).trigger("chosen:updated")
		$("#user_global_warning").hide()
	}

	const getPrograms = async (id) => {
		try {
			let data = { type: "access_user", id: id }
			const res = await fetchPost('/data/submit', data)
			if (res.global == 1) return -1
			return res.program
		} catch (e) {
			console.log(e)
		}
	}

	const showAuthorizations = async (user) => {
		$('#auth_container').show()

		//super user
		$('.auth_role').html(htmlEsc("Super User"))
		$('.auth_program_edit').html(htmlEsc(`MANAGE`))
		$('.auth_marketing').html(htmlEsc(`MANAGE`))
		$('.auth_filing').html(htmlEsc(`MANAGE`))
		$('.auth_accounting').html(htmlEsc(`MANAGE`))
		$('.auth_user').html(htmlEsc(`MANAGE`))
		$('.auth_programlist').html((`ALL`))

		//not global super user
		if (!user.user_super) {
			const getAuthorizations = async (id) => {
				try {
					let data = { type: "get_auth", id: id }
					const res = await fetchPost('/data/submit', data)
					return res
				} catch (e) {
					console.log(e)
				}
			}
			const res = await getAuthorizations(user.user_id)
			const auth = res.auth
			const programs = res.programs
			if (res.rc === "OK") {
				$('.auth_role').html(`${auth.role}`)
				$('.auth_programlist').html((`${programs}`))
				$('.auth_program_edit').html(htmlEsc(`${auth.program_edit}`))
				$('.auth_marketing').html(htmlEsc(`${auth.marketing}`))
				$('.auth_filing').html(htmlEsc(`${auth.filing}`))
				$('.auth_accounting').html(htmlEsc(`${auth.accounting}`))
				$('.auth_user').html(htmlEsc(auth.user))
			} else {
				console.log('error')
			}
		}
	}

	const userEdit = async (user) => {
		console.log('user edit', user)

		let isInsert = user.user_id == 0
		$("#user_content_container").slideDown(slideSpeed)

		if (isInsert) { //ADD

			$('#auth_container').hide()
			$("#program_access_container").show()
			$("#user_cancel").show()
			$("#user_save").show()
			$(".user_form").show()

		} else { //UPDATE
			let programData = await getPrograms(user.user_id)
			if (user.user_super == 0) { // not super user
				$("#user_super").prop('checked', false)
				$('#user_global').prop('checked', false)
				$('#user_global_warning').hide()
				$("#user_program_access").prop('disabled', false).trigger('chosen:updated')
				$("#user_role").prop('disabled', false).trigger('chosen:updated')
				$("#user_global").prop('disabled', false)

				if (programData == -1) { //global accesss
					$('#user_global').prop('checked', true)
					$("#user_program_access").prop('disabled', true).trigger('chosen:updated')
				}

			} else { //super user
				$('#user_super').prop('checked', true)
				$("#user_role").prop('disabled', true).trigger('chosen:updated')
				$("#user_program_access").prop('disabled', true).trigger('chosen:updated')
				$("#user_global").prop('disabled', true)
				$('#user_global_warning').show()
			}

			if (user.user_status == USER_INACTIVE) {
				$("#user_archive").hide()
				$("#user_reactivate").show()
			} else {
				$("#user_archive").show()
				$("#user_reactivate").hide()
			}

			$('#user_password_container').html(`
				<label for="name">Password</label>
				<div class="input-group" style="height: 38px;">  <!-- Added fixed height to match other inputs -->
				<input disabled class="form-control" type="password" placeholder="Password" id="user_password" rows="3" required></input>
				<button class="btn btn-outline-secondary" type="button" id="change_password_btn">
					<i class="fa-solid fa-key"></i> Change
				</button>
				</div>
				<div id="new_password_container" style="display:none" class="mt-2">
				<input class="form-control" type="password" placeholder="New Password" id="user_new_password"></input>
				</div>
			`)
			$('#user_password').val(user.user_password)

			$('#change_password_btn').off('click').on('click', (e) => {
				e.preventDefault()
				$('#new_password_container').slideToggle()
				$('#user_new_password').val('')
			})

			$("#user_cancel").show()
			$("#user_save").show()
			$(".user_form").show()
			$('#user_role').val(user.auth_role).trigger("chosen:updated")
			$('#user_program_access').val(programData).trigger("chosen:updated")
			$('#user_email').val(user.user_email)
			$('#user_first').val(user.user_first)
			$('#user_last').val(user.user_last)
			$('#user_phone').val(user.user_phone)
			$('#user_address').val(user.user_address)
			$('#user_city').val(user.user_city)
			$('#user_state').val(user.user_state).trigger("chosen:updated")
			$('#user_zip').val(user.user_zip)
			$('#user_start').val(user.user_startdate)
			$('#user_end').val(user.user_enddate)

			if (user) showAuthorizations(user) // show authorization table
		}

		$(".user_toggle").off("click").on("click", (e) => {
			userToggle(user)
			e.preventDefault()
		})

		$("#user_cancel").off("click").on("click", (e) => {
			clearuserForm()
			e.preventDefault()
		})

		$("#user_save").off("click").on("click", (e) => {
			let postData = {
				type: 'save_user',
				data: {
					id: user.user_id,
					super: $("#user_super").is(':checked') ? 1 : 0,
					global: $("#user_global").is(':checked') ? 1 : 0,
					program_access: $('#user_super').is(':checked') || $('#user_global').is(':checked') ? null : $('#user_program_access').val(),
					password: $('#user_password').val(),
					new_password: $('#user_new_password').val(),
					role: $('#user_role').val(),
					email: $('#user_email').val(),
					first: $('#user_first').val(),
					last: $('#user_last').val(),
					phone: $('#user_phone').val(),
					address: $('#user_address').val(),
					city: $('#user_city').val(),
					state: $('#user_state').val(),
					zip: $('#user_zip').val(),
					start: $('#user_start').val(),
					end: $('#user_end').val(),
				}
			}
			userSave(postData)
			e.preventDefault()
		})
	}

	const userSave = async (postData) => {
		try {
			const res = await fetchPost('/form/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("User Saved Successfully", "success")
				userDt.ajax.reload()
				clearuserForm()
			} else {
				displayNotification("error saving this user", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	const userToggle = async (user) => {
		let postData = {
			type: 'toggle_user',
			id: user.user_id,
			status: user.user_status
		}
		try {
			const res = await fetchPost('/marketing/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Status Changed Successfully ", "success")
				userDt.ajax.reload()
				clearuserForm()
			} else {
				displayNotification("Error Changing Status", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}
}

//login
if ($('#login_form').length == 1) {
	init_login()
}

function init_login() {
	localStorage.setItem('logout-event', 'logout' + Math.random()) // trigger logout for any other tabs that might still be open

	const lastemail = Cookies.get('lastemail')
	if (lastemail != null) {
		$('#login_form_email').val(lastemail)
		$('#login_form_remember').prop('checked', true)
	}

	//start login on enter
	$('#login_form_email, #login_form_password').on('keyup', function (e) {
		if (e.key === "Enter") {
			$('#login_form_submit').click()
		}
	})

	$('#login_form_submit').off('click').on('click', async (e) => {
		const $btn = $(e.currentTarget)
		buttonIsLoading($btn, true) //start loading

		type UserType = {
			email: string,
			password: string,
		}

		let user: UserType = {
			email: String($('#login_form_email').val()),
			password: String($('#login_form_password').val()),
		}

		if (user.email == "" || user.password == "") {
			displayNotification('Please fill out all fields', "danger")
		}

		try {
			const res = await fetchPost('/login/auth', user)
			if (res.rc != 'OK') throw res.rc

			//set cookie for for email if remember me is checked
			$('#login_form_remember').is(':checked') ? Cookies.set('lastemail', user.email, { expires: 365 }) : Cookies.remove('lastemail')

			window.location = res.redirect
		} catch (err) {
			buttonIsLoading($btn, false) //stop loading
			switch (err) {
				case 'WRONG_PROGRAM':
					displayNotification('You are not authorized for this program', 'danger')
					break
				default:
					displayNotification("Login Failed", "danger")
					break
			}
			console.error(err)
		}
		e.preventDefault()
	})

	$('#login_form_forgot').off('click').on('click', (event) => {
		event.preventDefault()
		$('#login_forgot_email').val($('#login_form_email').val())

		$('#login_forgot_send').off('click').on('click', async (event) => {
			event.preventDefault()

			if ($('#login_forgot_email').val().toString() == '') {
				displayNotification('Please enter the email address your account was registered with.', 'danger')
				return
			}

			try {
				await setTimeoutPromise(100)
				const res = await fetchPost('/login/forgot_password', { email: $('#login_forgot_email').val().toString() })

				if (res.rc != 'OK') throw res.rc

				displayNotification('Temporary password has been sent.', 'success')
				$('#login_forgot_modal').modal('hide')
			} catch (error) {
				console.error('forgotpassword', error)
				if (error == 'NO_USER') return displayNotification('No user found with that email address.', 'danger')
				displayNotification('An error occurred while sending this request.', 'danger')
			}
		})
	})

	$('#password_save').off('click').on('click', async (event) => {
		event.preventDefault()
		if ($('#password_change').val() != $('#password_confirm').val()) {
			displayNotification('Passwords do not match', 'danger')
			return
		}
		try {
			const res = await fetchPost('/login/change_password', { password: $('#password_change').val() })
			if (res.rc !== 'OK') {
				displayNotification('An error occurred while changing passwords.', 'danger')
				return
			}

			displayNotification('Temporary password has been sent.', 'success')
			window.location = res.redirect

		} catch (error) {
			console.error('changepassword', error)
		}
	})
}

function init_history() {

	const getHistoryFilters = () => ({
		system: $('#hist_filtersystem').is(':checked') ? 1 : 0,
		user: $('#hist_filterusers').val(),
		type: $('#hist_filtertype').val(),
	})
	setCollapse({ $btn: $('#hist_filterscollapse'), $target: $('#hist_filters') })

	const initHistoryDt = () => {
		const columns = [
			{
				data: 'audit_id',
				visible: false,
			},
			{
				data: 'user_id',
				visible: false,
			},
			{
				data: 'user_email',
				header: 'User',
			},
			{
				data: 'user_impersonate',
				header: 'Impersonating User',
			},
			{
				data: 'user_name',
				header: 'Name',
			},
			{
				data: 'audit_type',
				header: 'Type',
			},
			{
				data: 'audit_action',
				header: 'Audit Action',
			},
			{
				data: 'audit_datepretty',
				header: 'Date',
			},
			{
				data: 'audit_data',
				header: 'Target',
			},
		]

		const headers = columns.map((col) => col.header)
		const historyDt = $('#history_table').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/history',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getHistoryFilters(),
				}),
			},
			scrollX: true,
			columns: columns,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No value to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> values",
				infoEmpty: "Showing 0 to 0 of 0 value",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total values)",
				lengthMenu: "Show _MENU_ values",
			},
			initComplete: () => {
				const $scroll = $('#doctable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#historytable_container').find('th').addClass('custom-header')

		//Reload table when filters change
		$('#hist_filters').on('change', () => {
			historyDt.ajax.reload(undefined, false)
		})

		return historyDt
	}

	let historyDt = initHistoryDt()
}

const initMfa = () => {
	const user = JSON.parse(atob($('#mfa_form').data('params')))
	let selectedMethod = null

	const changeSelectedMethod = (method) => {
		selectedMethod = method
		switch (method) {
			case MFA_METHOD_EMAIL:
				$('#mfa_form_resend').show()
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.email)}</b>.`)
				break
			case MFA_METHOD_SMS:
				$('#mfa_form_resend').show()
				$('#mfa_form_title').html(`Please enter the code sent to <b>${htmlEsc(user.phone)}</b>.`)
				break
			case MFA_METHOD_APP:
				$('#mfa_form_resend').hide()
				$('#mfa_form_title').html(`Please enter the code displayed on your authenticator app.`)
				break
		}
	}
	changeSelectedMethod(user.mfa_method)

	$('#mfa_form_submit').off('click').on('click', async (event) => {
		event.preventDefault()
		const $btn = $(event.currentTarget)

		$('#mfa_form_submit').prop('disabled', true)
		buttonIsLoading($btn, true)

		try {
			const postData = {
				code: $('#mfa_form_code').val().toString(),
				method: selectedMethod,
				remember: $('#mfa_form_remember').is(':checked') ? 1 : 0,
			}

			await setTimeoutPromise(100)

			const res = await fetchPost('/login/mfa', postData)

			if (res.rc !== 'OK') throw res.rc

			window.location = res.redirect
			buttonIsLoading($btn, false)
		} catch (error) {
			buttonIsLoading($btn, false)
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = ''
					break
				case 'INVALID_CODE':
					displayNotification('Incorrect code.', 'danger')
					break
				default:
					displayNotification('An error occured while logging in.', 'danger')
					console.error('mfa_form_submit', error)
			}
		} finally {
			$('#mfa_form_submit').prop('disabled', false)
		}
	})

	$('#mfa_form_resend').off('click').on('click', async (event) => {
		event.preventDefault()

		$('#mfa_form_resend').prop('disabled', true)

		try {
			await setTimeoutPromise(100)

			const res = await fetchPost('/login/send_mfa', { method: selectedMethod })

			if (res.rc !== 'OK') throw res.rc

			displayNotification('A new code has been sent.', 'success')
		} catch (error) {
			switch (error) {
				case 'NO_MFA_LOGIN':
					window.location.href = ''
					break
				default:
					displayNotification('An error occured while sending the code.', 'danger')
					console.error('mfa_form_resend', error)
			}
		}
		$('#mfa_form_resend').prop('disabled', false)
	})

	$('#mfa_form_change_method').off('click').on('click', () => {
		$('#mfa_method_send').off('click').on('click', async (event) => {
			event.preventDefault()

			const method = +$('#mfa_method_body').find('input[name="mfa_method"]:checked').val()
			if (method == selectedMethod || method == MFA_METHOD_APP) {
				if (method == MFA_METHOD_APP) changeSelectedMethod(method)
				$('#mfa_form_code').val('')
				$('#mfa_method_modal').modal('hide')
				return
			}

			$('#mfa_method_send').prop('disabled', true)

			try {
				await setTimeoutPromise(100)

				const res = await fetchPost('/login/send_mfa', { method })

				if (res.rc !== 'OK') throw res.rc
				changeSelectedMethod(method)

				displayNotification('A new code has been sent.', 'success')
				$('#mfa_method_modal').modal('hide')
			} catch (error) {
				switch (error) {
					case 'NO_MFA_LOGIN':
						window.location.href = ''
						break
					default:
						displayNotification('An error occured while sending the code.', 'danger')
						console.error('mfa_form_resend', error)
				}
			}

			$('#mfa_method_send').prop('disabled', false)
		})
		$('#mfa_form_code').val('')
		$('#mfa_method_modal').modal('show')
	})
}

const initAccount = async () => {

	const clearGeneralSettings = () => {
		$("#settings_general_form :input").val('')
		$("#settingsModal").modal('hide')
	}

	const getUser = async () => {
		try {
			const data = { type: 'get_user_settings' }
			const { user } = await fetchPost('/data/submit', data)
			return user
		} catch (e) {
			console.error(e)
		}
	}

	const userSettingsSave = async (postData) => {
		try {
			const res = await fetchPost('/form/submit', postData)
			if (res.rc === 'OK') {
				displayNotification("Settings Saved Successfully", "success")
				clearGeneralSettings()
			} else {
				displayNotification("Error Saving Settings", "danger")
			}
		} catch (e) {
			console.log(e)
		}
	}

	setCollapse({ $btn: $('#acccount_form_appcollapse'), $target: $('#account_form_appconfig') })

	$("#account_form_mfa").on('click', () => {
		($('#account_form_mfa').is(':checked')) ? $('.auth_radio').prop('disabled', false) : $('.auth_radio').prop('disabled', true)
	})

	$('#account_form_authsave').off('click').on('click', async (event) => {
		event.preventDefault()

		let method = MFA_METHOD_EMAIL
		if ($('#auth_email').is(':checked')) method = MFA_METHOD_EMAIL
		if ($('#auth_sms').is(':checked')) method = MFA_METHOD_SMS
		if ($('#auth_app').is(':checked')) method = MFA_METHOD_APP
		const postData = {
			type: 'account_save',
			mfa: $('#account_form_mfa').length ? ($('#account_form_mfa').is(':checked') ? 1 : 0) : null,
			mfa_method: method,
		}

		const $btn = $(event.target)
		$btn.prop('disabled', true)
		$('#spinner').show()

		try {
			await setTimeoutPromise(100)
			const res = await fetchPost('/form/submit', postData)
			if (res.rc !== 'OK') throw res.rc

			$("#settingsModal").modal('hide')
			$("#account_form_appconfig").collapse('hide')
			displayNotification('Account update successful.', 'success')
		} catch (error) {
			switch (error) {
				default:
					displayNotification('An error occurred while sending this request.', 'danger')
					console.error('account submit', error)
					break
			}
		}
		$('#spinner').hide()
		$btn.prop('disabled', false)
	})

	const checkQrCode = () => {
		if (!$('#account_mfa_app_qr_code_img').length) {
			$('#account_mfa_app_qr_code').append(`<img id="account_mfa_app_qr_code_img" src="/data/account_mfa_app_qr_code?t=${(new Date()).getTime()}" class="position-absolute" style="border-radius: 6px">`)
		}
	}

	$('#acccount_form_appcollapse').on('click', () => {
		checkQrCode()
	})

	$("#settings_dropdown").off('click').on('click', async () => {
		const user = await getUser()
		$('#settings_email').val(user.user_email)
		$('#settings_first').val(user.user_first)
		$('#settings_last').val(user.user_last)
		$('#settings_password').val(user.password)
		$('#settings_phone').val(user.user_phone)
		$('#settings_address1').val(user.user_address1)
		$('#settings_address2').val(user.user_address2)
		$('#settings_city').val(user.user_city)
		$('#settings_state').val(user.user_state).trigger("chosen:updated")
		$('#settings_zip').val(user.user_zip)
		$('#settings_start').val(user.user_startdate)
		$('#settings_end').val(user.user_enddate)
		$('#settings_end').val(user.user_enddate)
		$('#settings_role').val(user.role).trigger("chosen:updated")

		let method = user.user_mfa_method
		switch (parseInt(method)) {
			case MFA_METHOD_EMAIL:
				$('#auth_email').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', true)
				break
			case MFA_METHOD_SMS:
				$('#auth_sms').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', true)
				break
			case MFA_METHOD_APP:
				$('#auth_app').prop('checked', true)
				$('#acccount_form_appcollapse').prop('disabled', false)
				break
		}

		$('.auth_radio').on('click', () => {
			method = $('#auth_type').find('input[name="auth_method"]:checked').val()
			if (parseInt(method) == MFA_METHOD_APP) {
				$('#acccount_form_appcollapse').prop('disabled', false)
				$("#account_form_appconfig").collapse('show')
				checkQrCode()
			} else {
				$('#acccount_form_appcollapse').prop('disabled', true)
				$("#account_form_appconfig").collapse('hide')
			}
		})

		$("#account_form_generalsave").off('click').on('click', async () => {
			let postData = {
				type: 'general_save',
				email: $('#settings_email').val(),
				first: $('#settings_first').val(),
				last: $('#settings_last').val(),
				password: $("#settings_password").val(),
				password_match: $("#settings_password_match").val(),
				phone: $('#settings_phone').val(),
				address1: $('#settings_address1').val(),
				address2: $('#settings_address2').val(),
				city: $('#settings_city').val(),
				state: $('#settings_state').val(),
				zip: $('#settings_zip').val(),
				start: $('#settings_start').val(),
				end: $('#settings_end').val(),
			}

			if (postData.password != postData.password_match) {
				displayNotification('Password Do Not Match', "danger")
				return
			}

			userSettingsSave(postData)
		})
	})

	$('#account_mfa_app_qr_code_regenerate').off('click').on('click', async ({ target }) => {
		const $btn = $(target)
		$btn.prop('disabled', true)

		try {
			$('#account_mfa_app_qr_code_img').remove()

			await setTimeoutPromise(10)

			const res = await fetchPost('/form/submit', { type: 'account_mfa_app_reset' })
			if (res.rc !== 'OK') throw res.rc

			checkQrCode()
		} catch (error) {
			console.error('account submit', error)
			displayNotification('An error occurred while sending this request.', 'danger')
		}

		$btn.prop('disabled', false)
	})
}

async function initUserSupport() {

	setCollapse({ $btn: $('#notifFiltersCollapse'), $target: $('#notifFilters') })

	const getNotifFilterss = () => ({
		program: $('#notifFilterProgram').val(),
		type: $('#notifFilterType').val(),
		status: $('#notifFilterStatus').val(),
	})

	const initNotificationsDt = () => {
		const columnsInfo = [
			{
				data: 'notif_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'notif_progname',
				header: 'Program',
			},
			{
				data: 'notif_email',
				header: 'Email',
			},
			{
				data: 'notif_first',
				header: 'First',
			},
			{
				data: 'notif_last',
				header: 'Last',
			},
			{
				data: 'notif_organization',
				header: 'Organization',
			},
			{
				data: 'notif_position',
				header: 'Position',
			},
			{
				data: 'notif_statuspretty',
				header: 'Status',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const notificationDt = $('#notificationsTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/notifications',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getNotifFilterss(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'ASC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No notifications to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> notifications",
				infoEmpty: "Showing 0 to 0 of 0 notifications",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total notifications)",
				lengthMenu: "Show _MENU_ notifications",
			},
			initComplete: () => {
				const $scroll = $('#notificationtable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#notificationsTableContainer').find('th').addClass('custom-header')

		//Reload table when filters change
		$('#notifFilters').on('change', () => {
			notificationDt.ajax.reload(undefined, false)
		})

		$('#notificationsTable').on('click', 'tbody tr', ({ target }) => {
			const notification = getTableRowData(notificationDt, target)
			viewNotification(notification)
		})

		return notificationDt
	}
	let notifDt = initNotificationsDt()

	const notifForm = [
		{
			$: $('#notifViewPrograms'),
			source: 'notif_progid',
		},
		{
			$: $('#notifViewEmail'),
			source: 'notif_email',
		},
		{
			$: $('#notifViewTitle'),
			source: 'notif_title',
		},
		{
			$: $('#notifViewFirst'),
			source: 'notif_first',
		},
		{
			$: $('#notifViewLast'),
			source: 'notif_last',
		},
		{
			$: $('#notifViewPosition'),
			source: 'notif_position',
		},
		{
			$: $('#notifViewOrganization'),
			source: 'notif_organization',
		},
		{
			$: $('#notifViewAddress1'),
			source: 'notif_address1',
		},
		{
			$: $('#notifViewAddress2'),
			source: 'notif_address2',
		},
		{
			$: $('#notifViewCity'),
			source: 'notif_city',
		},
		{
			$: $('#notifViewState'),
			source: 'notif_state',
		},
		{
			$: $('#notifViewZip'),
			source: 'notif_zip',
		},
		{
			$: $('#notifViewPhone'),
			source: 'notif_phone',
		},
		{
			$: $('#notifViewFax'),
			source: 'notif_fax',
		},
		{
			$: $('#notifViewStatus'),
			source: 'notif_status',
		},
	]

	const reloadNotifications = () => notifDt.ajax.reload(undefined, false)

	const closeNotification = () => {
		$('#notificationView').slideUp(slideSpeed)
		$("#notificationViewForm :input").val('')
		$('#notifEditToggle').hide()
	}

	const viewNotification = (notif = null) => {
		$('#notificationView').slideDown(slideSpeed)

		$('#notifEditCancel').off('click').on('click', () => closeNotification())

		if (notif) {
			$('#notifEditToggle').html(Number(notif.notif_status) === 0 ? 'Archive' : 'Reactivate');
			setForm(notifForm, notif)

			$('#notifEditToggle').show().off('click').on('click', async ({ target }) => {
				$(target).prop('disabled', true)
				try {
					const res = await fetchPost('/form/submit', { type: 'notif_toggle', data: { notif_status: notif.notif_status, notif_id: notif.notif_id } })
					if (res.rc != "OK") throw res.rc

					displayNotification('Notification status updated', 'success')
					reloadNotifications()
					closeNotification()
				} catch (error) {
					displayNotification('Error, There was an error updating the notification', 'danger')
					console.log(error)
				} finally {
					$(target).prop('disabled', false)
				}
			})

			$('#notifEditSave').off('click').on('click', async ({ target }) => {
				try {
					$(target).prop('disabled', true)
					const res = await fetchPost('/form/submit', { type: 'notif_save', data: { id: notif.notif_id, notif: getForm(notifForm) } })
					if (res.rc != "OK") throw res.rc

					displayNotification('Notification status updated', 'success')
					reloadNotifications()
					closeNotification()
				} catch (error) {
					if (error == 'DUPLICATE') return displayNotification('Error, Duplicate notification', 'danger')
					displayNotification('Error, There was an error updating the notification', 'danger')
					console.log(error)
				} finally {
					$(target).prop('disabled', false)
				}
			})
		}
	}

	const getMessageFilters = () => ({
		program: $('#messageFilterProgram').val(),
		zero: $('#messageFilterZero').val(),
	})

	setCollapse({ $btn: $('#messageUsersCollapse'), $target: $('#additional_users') })

	let messageDt: DataTables.Api | null = null
	const $messageTableTemplate = $('#messagesTable').remove()

	const initMessagesDt = () => {

		const data = {
			filters: getMessageFilters(),
			group: $('#message_group').val(),
			to: $('#message_to').val(),
			cc: $('#message_cc').val(),
			bcc: $('#message_bcc').val(),
		}

		const columnsInfo = [
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last name',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: ' <div><button class="remove-user btn btn-danger btn-sm">Remove</button> </div>',
			}
		]
		const headers = columnsInfo.map((col) => col.header)

		if (messageDt != null) {
			messageDt.destroy()
			$('#messageTableContainer').empty()
		}
		const $messagesTable = $messageTableTemplate.clone()
		$('#messageTableContainer').append($messagesTable)

		messageDt = $messagesTable.tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/message_preview',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					data
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'ASC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			buttons: [
				{
					text: '<span class="fa-solid fa-file-arrow-down mr-1"></span> Download',
					className: 'ms-1 download btn btn-sm btn-primary',
					action: () => downloadMessages(),
				},
				{
					text: '<i class="fa-solid fa-rotate"></i> Reset Users',
					className: 'ms-1 btn btn-sm btn-success',
					action: () => resetUsers(),
				},
			],
			dom: datatablesButtonDom,
			language: {
				emptyTable: "No users to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> users",
				infoEmpty: "Showing 0 to 0 of 0 users",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total users)",
				lengthMenu: "Show _MENU_ users",
			},
		})

		const downloadMessages = async () => {
			const fields = columnsInfo.map((col) => ({ data: col.data, header: col.header }))
			post('/tables/message_preview', { filters: JSON.stringify(getMessageFilters()), mode: TABLE_DOWNLOAD, fields: JSON.stringify(fields), data: JSON.stringify(data) })
		}

		const resetUsers = async () => {
			clearMessageCache()
			reloadMessages()
		}

		$messagesTable.on('click', '.remove-user', ({ target }) => {
			const rowElement = $(target).closest('tr') as any
			const user = getTableRowData(messageDt, rowElement) as user
			removeRecipient(user, messageDt)
		});

		return messageDt
	}

	type user = {
		user_email: string,
		user_first: string,
		user_last: string,
	}

	const reloadMessages = () => messageDt.ajax.reload(undefined, false)

	const removeRecipient = async (user: user, messageDt: DataTables.Api) => {
		try {
			const res = await fetchPost('/data/submit', { type: 'remove_recipient', data: { user_email: user.user_email } })
			if (res.rc != "OK") throw res.rc

		} catch (error) {
			displayNotification('Error, There was an error removing the user', 'danger')
			console.log(error)
		}
		reloadMessages()
	}

	tinymce.init({
		selector: '#message_body',
		toolbar: tinyMceTools,
		font_family_formats: tinyMceFonts,
		plugins: tinyMcePlugins,
		promotion: false,
		height: 400,
		content_style: tinyMceDefaultFont,
		browser_spellcheck: true,
		content_css: false,
		relative_urls: false,
		skin: false,
	})

	$('#message_send').off('click').on('click', async () => {
		confirmDialog({
			dialogTitle: 'Send Messages',
			bodyText: '<p>Are you sure you would like to send all email messages?</p>',
			confirmText: 'Send',
			confirmStyle: 'primary',
			showCancelButton: false,
			confirmFunction: () => sendMessage()

		})
	})

	const sendMessage = async () => {
		try {
			showLoadingScreen()

			const body = tinymce.get('message_body').getContent()
			const postData = {
				to: $('#message_to').val() ?? null,
				subject: $('#message_subject').val(),
				body: body,
				filters: getMessageFilters(),
				group: $('#message_group').val(),
				send_self: $('#message_send_self').is(":checked") ? 1 : 0,
			}

			const formData = new FormData($('#message_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('mode', MAIL_SEND as any)

			const attachmentFiles = $('#message_attachments').prop('files');
			if (attachmentFiles && attachmentFiles.length > 0) {
				for (let i = 0; i < attachmentFiles.length; i++) {
					formData.append('attachments[]', attachmentFiles[i]);
				}
			}

			const { rc } = await fetchPost('/tables/message_preview', formData)
			if (rc != 'OK') throw rc

			displayNotification('Your Message Has Sent Successfully', 'success')
			clearMessage()
		} catch (Error) {
			displayNotification('Error sending messages', 'danger')
			console.log(Error)
		} finally {
			hideLoadingScreen()
		}
	}

	const clearMessageCache = async () => {
		try {
			const res = await fetchPost('/data/submit', { type: 'clear_message' })
			if (res.rc != "OK") throw res.rc
		} catch (error) {
			console.log()
			displayNotification('Error, There was an error clearing the message cache', 'danger')
		}
	}

	const clearMessageTable = () => {
		if (messageDt != null) {
			// messageDt.destroy()
			$('#messageTableContainer').empty()
		}
		clearMessageCache()
	}

	const clearMessage = async () => {
		try {
			clearMessageTable()
			showLoadingScreen()

			//hide table
			if ($('#message_to_row').hasClass('show')) $('#message_to_row').collapse('hide')
			$("#message_form :input").val('')
			$("#message_group").val('').trigger("chosen:updated")
			$('#message_send_self').prop('checked', false)
			// const footerHTML = `<div class="custom-footer" contenteditable="false"><img style="max-width: 200px; height: 50px;" src="` + logoPath + `" alt="Custom Footer" /></div>`;
			tinymce.get('message_body').setContent('')
		} catch (err) {
			displayNotification('Error, There was an error clearing the message', 'danger')
			console.log(err)
		} finally {
			hideLoadingScreen()
		}
	}

	$('#message_clear').off('click').on('click', async () => clearMessage())

	let prevGroup: string[] = [];
	$('#message_group').on('change', async ({ target }) => {
		const group = $(target).val() as string[];

		const elementMapping = {
			[GROUP_PAYERS]: ['#messageFilterProgramContainer', '#messageFilterZeroContainer'],
			[GROUP_NOTIFICATIONS]: ['#messageFilterProgramContainer']
		};

		// Show the elements that correspond to the selected values.
		group.forEach(value => {
			const selectors = elementMapping[value];
			if (selectors) {
				selectors.forEach(selector => $(selector).fadeIn(200));
			}
		});

		// Hide the elements that correspond to the deselected values.
		const deselectedValues = prevGroup.filter(value => !group.includes(value));
		deselectedValues.forEach(value => {
			const selectors = elementMapping[value];
			if (selectors) {
				selectors.forEach(selector => $(selector).fadeOut(200));
			}
		});

		// Update the previous state.
		prevGroup = group;
	});

	$('#message_preview_display').off('click').on('click', async () => {
		initMessagesDt()
		setTimeout(() => scrollBottom(), 1000)
	})

	$('#message_preview_mail').off('click').on('click', async () => {
		const body = tinymce.get('message_body').getContent()
		const postData = {
			to: $('#message_to').val(),
			cc: $('#message_cc').val(),
			bcc: $('#message_bcc').val(),
			send_self: $('#message_send_self').is(":checked") ? 1 : 0,
			subject: $('#message_subject').val(),
			body: body,
			group: $('#message_group').val()
		}
		try {
			showLoadingScreen()

			const formData = new FormData($('#message_form').get(0) as HTMLFormElement)
			formData.append('data', JSON.stringify(postData))
			formData.append('type', 'message_preview_mail')

			const attachmentFiles = $('#message_attachments').prop('files');
			if (attachmentFiles && attachmentFiles.length > 0) {
				for (let i = 0; i < attachmentFiles.length; i++) {
					formData.append('attachments[]', attachmentFiles[i]);
				}
			}

			const { rc } = await fetchPost('/form/submit', formData)
			if (rc != 'OK') throw rc

			displayNotification('Your Message Has Been Previewed Successfully', 'success')
		} catch (err) {
			switch (err) {
				case 'NO_DATA':
					displayNotification('Error, insignificant data', 'danger')
					break
				case 'NO USER':
					displayNotification('No User Associated With This Email', 'danger')
					break
			}
			console.log(err)
		} finally {
			hideLoadingScreen()
		}
	})

	setCollapse({ $btn: $('#userqFiltersCollapse'), $target: $('#userqFilters') })

	const getUserqFilters = () => ({
		program: $('#userqFiltersPrograms').val(),
		start: $('#userqFiltersStart').val(),
		end: $('#userqFiltersEnd').val(),
		status: $('#userqFiltersStatus').val(),
	})

	//user questions
	const initUserQuestionsDt = () => {
		const columnsInfo = [
			{
				data: 'userq_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'userq_progname',
				header: 'Program',
			},
			{
				data: 'userq_name',
				header: 'Name',
			},
			{
				data: 'userq_email',
				header: 'Email',
			},
			{
				data: 'userq_subject',
				header: 'Subject',
			},
			{
				data: 'userq_datepretty',
				header: 'Date Sent',
			},
			{
				data: 'userq_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: ' <div><button class="show-mail btn btn-primary btn-sm"><i class="fa-sharp fa-regular fa-envelope"></i>  Mail</button>  <button class="show-escalate btn btn-danger btn-sm"><i class="fa-solid fa-handshake-angle"></i> Help</button>  </div>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const userqTable = $('#userqTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/userQuestions',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getUserqFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No questions to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> questions",
				infoEmpty: "Showing 0 to 0 of 0 questions",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total questions)",
				lengthMenu: "Show _MENU_ questions",
			},
			initComplete: () => {
				const $scroll = $('#userqTableContainer').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#userqTableContainer').find('th').addClass('custom-header')

		//Reload table when filters change	
		$('#userqFilters').on('change', () => {
			userqTable.ajax.reload(undefined, false)
			$(window).trigger('resize')
		})

		$('#userqTableContainer').on('click', '.show-mail', ({ target }) => {
			const row = getTableRowData(userqTable, target) as any
			window.location.href = `mailto:${row.userq_email}`
		})

		$('#userqTableContainer').on('click', '.show-escalate', ({ target }) => {
			const question = getTableRowData(userqTable, target) as any
			escalateQuestion(question)
		})

		$('#userqTable').on('click', 'tbody tr', ({ target }) => {
			const userq = getTableRowData(userqTable, target)
			showUserQuestion(userq)
		})

		return userqTable
	}
	const userqDt = initUserQuestionsDt()

	const userqForm = [
		{
			$: $('#userqName'),
			source: 'userq_name',
		},
		{
			$: $('#userqEmail'),
			source: 'userq_email',
		},
		{
			$: $('#userqSubject'),
			source: 'userq_subject',
		},
		{
			$: $('#userqDate'),
			source: 'userq_createdate',
		},
		{
			$: $('#userqStatus'),
			source: 'userq_status',
		},
		{
			$: $('#userqQuestion'),
			source: 'userq_question',
		},
	]

	const reloadUserqTable = () => {
		userqDt.ajax.reload(undefined, false)
		$(window).trigger('resize')
		userqClose()
	}

	const userqClose = () => $('#userqEdit').slideUp(slideSpeed)

	$('#userqEditCancel').off('click').on('click', () => userqClose())

	const escalateQuestion = async (question) => {
		try {
			question['type'] = USERQ_ESCALATE
			const res = await fetchPost('/data/submit', { type: 'userq_escalate', data: question })
			if (res.rc != 'OK') throw (res.rc)

			displayNotification('question successfully escalated', 'success')
			reloadUserqTable()
		} catch (err) {
			if (err == 'EMAIL_FAILED') {
				displayNotification('email failed to send', 'danger')
				return
			}
			displayNotification('error escalating question', 'danger')
		}
	}

	const showUserQuestion = (question) => {
		$('#userqEdit').slideDown(slideSpeed)

		if (question) {
			setForm(userqForm, question)
			scrollTop()
			question.userq_status == USERQ_ACTIVE ? $('#userqEditToggle').text('Mark Complete') : $('#userqEditToggle').text('Reactivate Question')
		}

		$('#userqEditCancel').off('click').on('click', () => userqClose())

		$('#userqEditEscalate').off('click').on('click', () => escalateQuestion(question))

		$('#userqEditToggle').off('click').on('click', async () => {
			try {
				const res = await fetchPost('/form/submit', { type: 'userq_toggle', data: { userq_id: question.userq_id, userq_status: question.userq_status } })
				if (res.rc != "OK") throw (res.rc)

				displayNotification('question successfully saved', 'success')
				reloadUserqTable()
			} catch (e) {
				displayNotification('error toggling question', 'danger')
				console.error('Error toggling user question edit', e)
			}
		})

	}

}

async function newUserInit() {
	const res = JSON.parse(window.atob($('#newuser_form').data('params')))
	const user = res.data ? res.data : res

	$('#newuser_email').val(user.user_email)
	$('#newuser_position').val(user.user_position)
	$('#newuser_company').val(user.user_company)
	$('#newuser_first').val(user.user_first)
	$('#newuser_last').val(user.user_last)
	$('#newuser_address1').val(user.user_address1)
	$('#newuser_address2').val(user.user_address2)
	$('#newuser_phone').val(user.user_phone)
	$('#newuser_city').val(user.user_city)
	$('#newuser_state').val(user.user_state).trigger("chosen:updated")
	$('#newuser_zip').val(user.user_zip)

	$('#newUserSave').off('click').on('click', async () => {
		try {
			const postData = {
				guid: user.user_guid ? user.user_guid : user.guid,
				email: $('#newuser_email').val(),
				first: $('#newuser_first').val(),
				last: $('#newuser_last').val(),
				address1: $('#newuser_address1').val(),
				address2: $('#newuser_address2').val(),
				phone: $('#newuser_phone').val(),
				city: $('#newuser_city').val(),
				state: $('#newuser_state').val(),
				zip: $('#newuser_zip').val(),
				password: $('#newuser_password').val(),
				password_confirm: $('#newuser_password_confirm').val(),
			}

			if (postData.password != postData.password_confirm) {
				displayNotification('Please Ensure Your Passwords Match', "danger")
				return
			}

			confirmDialog({
				dialogTitle: 'Finish Onboarding',
				bodyText: '<p> Are you sure all of your information is correct? </p><p>After clicking submit you will be redirected to the login page. Use your email and password you created here to login.</p>',
				confirmText: 'Submit',
				confirmStyle: 'success',
				showCancelButton: false,
				confirmFunction: () => saveOnboarding(postData)
			})

		} catch (err) {
			displayNotification('Error Saving New User', 'danger')
			console.log(err)
		}
	})

	const saveOnboarding = async (postData) => {
		try {
			const res = await fetchPost('/data/onboard_save', { type: 'onboard_save', data: postData })
			if (res.rc != 'OK') throw res.rc

			displayNotification('Success Creating New User', 'success')
			window.location.href = res.redirect
		} catch (err) {
			displayNotification('Error Saving New User', 'danger')
			console.log(err)
		}
	}
}

async function initOnboard() {
	$('#inviteForm').on('change', async () => {
		try {
			const program = $('#onboardProgram').val()
			const { count, primed, inactive, specific, email_sent, user_signedup, rc } = await fetchPost('/data/get_remaining_users', { data: program })

			if (rc != 'OK') throw rc

			$('#remainCountSpecific').text(specific)
			$('#emailsentCount').text(email_sent)
			$('#signedUpCount').text(user_signedup)
			count.forEach(c => $('#remainCount').text(c.userCount));
			primed.forEach(p => $('#remainCountPrimed').text(p.userCount));
			inactive.forEach(p => $('#remainInactive').text(p.userCount));

		} catch (err) {
			displayNotification('Error Finding Count', 'danger')
			console.log(err)
		}
	})

	$('#onboard_activate').off('click').on('click', async () => {
		try {
			const postData = {
				multiple: $('#onboardMultiple').is(':checked'),
				program: $('#onboardProgram').val(),
				amount: $('#onboardCount').val(),
			}
			const res = await fetchPost('/data/submit', { type: 'onboard_activate', data: postData })
			if (res.rc != 'OK') throw res.rc

			$('#inviteForm').trigger("change");
			displayNotification('Users Are Activated', 'success')

		} catch (err) {
			displayNotification('Error Priming Users', 'danger')
			console.log(err)
		}
	})

	$('#onboard_prime').off('click').on('click', async () => {
		try {
			const postData = {
				multiple: $('#onboardMultiple').is(':checked'),
				program: $('#onboardProgram').val(),
				amount: $('#onboardCount').val(),
			}
			const res = await fetchPost('/data/submit', { type: 'onboard_prime', data: postData })
			if (res.rc != 'OK') throw res.rc

			$('#inviteForm').trigger("change");
			displayNotification('Users Are Primed', 'success')

		} catch (err) {
			displayNotification('Error Priming Users', 'danger')
			console.log(err)
		}
	})

	$('#onboard_unprime').off('click').on('click', async () => {
		try {
			const postData = {
				multiple: $('#onboardMultiple').is(':checked'),
				program: $('#onboardProgram').val(),
				amount: $('#onboardCount').val(),
			}
			const res = await fetchPost('/data/submit', { type: 'onboard_unprime', data: postData })
			if (res.rc != 'OK') throw res.rc

			$('#inviteForm').trigger("change");
			displayNotification('Users Are NOT Primed', 'success')
		} catch (err) {
			displayNotification('Error Unpriming Users', 'danger')
			console.log(err)
		}
	})

	$('#onboard_invite').off('click').on('click', async () => {
		try {
			const postData = {
				amount: $('#onboardCount').val(),
				multiple: $('#onboardMultiple').is(':checked'),
				program: $('#onboardProgram').val(),
				email: $('#onboardEmail').val(),
			}
			const res = await fetchPost('/data/submit', { type: 'onboard_invite', data: postData })
			if (res.rc != 'OK') throw res.rc

			$('#inviteForm').trigger("change");
			displayNotification('Sent Emails', 'success')
		} catch (err) {
			displayNotification('Error', 'danger')
			console.log(err)
		}
	})
}

async function initTest() {
	$("#img_upload").off('click').on("click", async (e) => {
		const formData = new FormData($('#test_form').get(0) as HTMLFormElement)
		formData.append('type', 'save_img')
		const res = await fetchPost('/form/submit', formData)
		if (res.rc === 'OK') {
			displayNotification("Image uploaded ", "success")
		} else {
			displayNotification("error saving this imagee", "danger")
		}
	})
}

async function initRegistration() {

	const initUserRegistration = async (payer) => {
		//set a timeout for one second
		setTimeout(() => scrollBottom(), 500)

		$('#payerRegisterSubmit').hide()
		$('#payerRegisterform').hide()
		$('#registerUserForm').collapse('show')

		$('#payerRegisterProg').val(payer.programs).trigger('chosen:updated'),
			$('#payerRegisterFein').val(payer.payer_fein),
			$('#payerRegisterName').val(payer.payer_name),
			$('#payerRegisterAddress1').val(payer.payer_address1),
			$('#payerRegisterAddress2').val(payer.payer_address2),
			$('#payerRegisterCity').val(payer.payer_city),
			$('#payerRegisterState').val(payer.payer_state),
			$('#payerRegisterZip').val(payer.payer_zip),
			$('#payerRegisterNaic').val(payer.payer_naic),
			$('#payerRegisterGroup').val(payer.payer_group),

			$('#registerUserSubmit').off('click').on('click', async (e) => {
				e.preventDefault()

				if (($("#registerUserForm")[0] as HTMLFormElement).checkValidity() === false) {
					($("#registerUserForm")[0] as HTMLFormElement).reportValidity();
					return;
				}

				const userData = {
					user_email: $('#registerUserEmail').val(),
					user_first: $('#registerUserFirst').val(),
					user_last: $('#registerUserLast').val(),
					user_address1: $('#registerUserAddress1').val(),
					user_address2: $('#registerUserAddress2').val(),
					user_city: $('#registerUserCity').val(),
					user_phone: $('#registerUserPhone').val(),
					user_password: $('#registerUserPassword').val(),
				}

				const payerData = {
					programs: $('#payerRegisterProg').val(),
					payer_fein: $('#payerRegisterFein').val(),
					payer_name: $('#payerRegisterName').val(),
					payer_address1: $('#payerRegisterAddress1').val(),
					payer_address2: $('#payerRegisterAddress2').val(),
					payer_city: $('#payerRegisterCity').val(),
					payer_state: $('#payerRegisterState').val(),
					payer_zip: $('#payerRegisterZip').val(),
					payer_naic: $('#payerRegisterNaic').val(),
					payer_group: $('#payerRegisterGroup').val(),
				}

				if (userData.user_password != $('#registerUserPasswordConfirm').val()) {
					displayNotification('Passwords Do Not Match', 'danger')
					return
				}

				confirmDialog({
					dialogTitle: 'Submit Registration',
					bodyText: '<p>Are you sure you would like to submit? This will create a new user and payer.</p> <p>If you are not sure, please cancel and review your information. If you are sure, please click submit. </p> <p>You will be redirected back to the login page.</p>',
					confirmText: 'Submit',
					confirmStyle: 'primary',
					showCancelButton: false,
					confirmFunction: () => submitRegistration(userData, payerData)
				})
			})
	}

	const submitRegistration = async (userData, payerData) => {
		try {
			const res = await fetchPost('/form/submit', { type: 'register_user', data: { user: userData, payer: payerData } })
			if (res.rc != 'OK') throw res.rc
			displayNotification('User Registered', 'success')
			window.location = '/logout' as any //flush session and redirect
		} catch (err) {
			switch (err) {
				case 'NO_DATA':
					displayNotification('Error, insignificant data', 'danger')
					break;
				case 'USER_EXISTS':
					displayNotification('Error, this user already exists', 'danger')
					break;
				case 'PAYER_EXISTS':
					displayNotification('Error, this payer already exists.', 'danger')
					break;
				default:
					displayNotification('Error, Registering User', 'danger')
					break;
			}
			console.log(err)
		}
	}

	const payerComplete = $('#payer_complete').val()
	if (payerComplete) {
		const { payer } = await fetchPost('/data/submit', { type: 'get_register_payer' })
		if (!payer) {
			displayNotification('Error Getting Payer', 'danger')
			return
		}
		initUserRegistration(payer)
	}

	$('#payerRegisterSubmit').off('click').on('click', async (e) => {
		e.preventDefault()
		e.stopImmediatePropagation()

		if (($("#registerPayerForm")[0] as HTMLFormElement).checkValidity() === false) {
			($("#registerPayerForm")[0] as HTMLFormElement).reportValidity();
			return;
		}

		const postData = {
			programs: $('#payerRegisterProg').val(),
			fein: $('#payerRegisterFein').val(),
			name: $('#payerRegisterName').val(),
			address1: $('#payerRegisterAddress1').val(),
			address2: $('#payerRegisterAddress2').val(),
			city: $('#payerRegisterCity').val(),
			state: $('#payerRegisterState').val(),
			zip: $('#payerRegisterZip').val(),
			naic: $('#payerRegisterNaic').val(),
			group: $('#payerRegisterGroup').val(),
		}

		try {
			const res = await fetchPost('/form/submit', { type: 'register_payer', data: postData })
			if (res.rc != 'OK') throw res.rc
			displayNotification('Payer Registered', 'success')
			initUserRegistration(res.payer)
		} catch (err) {
			switch (err) {
				case 'NO_DATA':
					displayNotification('Error, insignificant data please fill out all appropriate sections', 'danger')
					break;
				case 'NO_PROGRAMS':
					displayNotification('Error, please add the appropriate programs you wish to file for', 'danger')
					break;
				case 'PAYER_EXISTS':
					displayNotification('Error, this payer already exists.', 'danger')
					break;
				default:
					displayNotification('Error Registering Payer', 'danger')
					break;
			}
			console.log(err)
		}

	})
}

async function initOnboardStats() {
	setCollapse({ $btn: $('#statsFieldsCollapse'), $target: $('#statsFields') })

	$('#statSpinner').show();
	try {
		const streamSource = new EventSource('/data/stream');
		streamSource.onmessage = function (event) {
			const data = JSON.parse(event.data);

			//onboard
			$('#statsTotalEmails').html(`${formatNumber(data['total_emails'])} emails`);
			$('#statsRegistered').html(`${formatNumber(data['registered'])} users`);
			$('#statsInactive').html(`${formatNumber(data['inactive'])} users`);
			$('#statsSent').html(`${formatNumber(data['pending'])} users`);
			$('#statsAllUsers').html(`${formatNumber(data['all_users'])} users`);

			//filing counts
			const filing = data['filing']
			$('#statsFilingToday').html(`${formatNumber(filing['today'])}`);
			$('#statsFilingMonthly').html(`${formatNumber(filing['monthly'])}`);
			$('#statsFilingYTD').html(`${formatNumber(filing['yearly'])}`);
			$('#statsFilingAll').html(`${formatNumber(filing['all'])}`);

			//filing counts
			const amount = data['filing']['amount']
			$('#statsFilingAmtToday').html(`${formatter.format(amount['today'] / 100)}`);
			$('#statsFilingAmtMonthly').html(`${formatter.format(amount['monthly'] / 100)}`);
			$('#statsFilingAmtYTD').html(`${formatter.format(amount['yearly'] / 100)}`);
			$('#statsFilingAmtAll').html(`${formatter.format(amount['all'] / 100)}`);

			//TRENDS
			const trends = data['filing']['trends']

			//highest earning month this quarter
			$('#statsTrendsQuarter').html(`${trends.highest_quarter_month} - $${formatNumber(trends['highest_quarter_month_amount'] / 100)} `);

			//highest earning month year to date
			$('#statsTrendsYTD').html(`${trends.highest_ytd_month} - $${formatNumber(trends['highest_ytd_month_amount'] / 100)} `);

			//highest earning month for all time
			$('#statsTrendsMonthly').html(`${trends.highest_month} - $${formatNumber(trends['highest_month_amount'] / 100)} `);

			//highest paying payer
			const top_payer = data['filing']['top_payer']
			$('#statsTrendsPayer').html(`${top_payer.name} - $${formatNumber(top_payer['amount'] / 100)} `);

			$('#statSpinner').remove()
			$('#statsTableContainer').show()
		}

	} catch (err) {
		console.log(err)
	}

	$('#statsFieldOnboard').on('change', async (e) => {
		$('#statsOnboardContainer')[$('#statsFieldOnboard').is(':checked') ? 'fadeIn' : 'fadeOut'](300);
	})

	$('#statsFieldCount').on('change', async (e) => {
		$('#statsCountContainer')[$('#statsFieldCount').is(':checked') ? 'fadeIn' : 'fadeOut'](300);
	})

	$('#statsFieldAmount').on('change', async (e) => {
		$('#statsAmtContainer')[$('#statsFieldAmount').is(':checked') ? 'fadeIn' : 'fadeOut'](300);
	})

	$('#statsFieldTrends').on('change', async (e) => {
		$('#statsTrendsContainer')[$('#statsFieldTrends').is(':checked') ? 'fadeIn' : 'fadeOut'](300);
	})

}

async function initImpersonate() {

	const getImpersonateFilters = () => ({
		status: $('#impersonateFilterStatus').val(),
		program: $('#impersonateFilterProgram').val(),
		payer: $('#impersonateFilterPayer').val(),
	})

	setCollapse({ $btn: $('#impersonateFiltersCollapse'), $target: $('#impersonateFilters') })

	const initImpersonateDt = () => {
		const columnsInfo = [
			{
				data: 'user_id',
				header: 'Id',
				visible: false,
			},
			{
				data: 'user_email',
				header: 'Email',
			},
			{
				data: 'user_first',
				header: 'First Name',
			},
			{
				data: 'user_last',
				header: 'Last Name',
			},
			{
				data: 'user_statuspretty',
				header: 'Status',
			},
			{
				header: 'Actions',
				data: '',
				orderable: false,
				searchable: false,
				defaultContent: '<button class="show-edit btn btn-primary btn-sm"> <i class="fas fa-user-secret"></i> Impersonate</button>',
			},
		]

		const headers = columnsInfo.map((col) => col.header)
		const impersonateDt = $('#impersonateTable').tableHeaders(headers).DataTable({
			ajax: {
				url: '/tables/impersonate',
				type: 'POST',
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
				},
				data: (postData) => ({
					...postData,
					filters: getImpersonateFilters(),
				}),
			},
			scrollX: true,
			columns: columnsInfo,
			columnDefs: [{ targets: '_all', defaultContent: '' }],
			order: [[0, 'DESC']],
			serverSide: true,
			processing: true,
			responsive: {
				details: false,
			},
			language: {
				emptyTable: "No users to show",
				info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> users",
				infoEmpty: "Showing 0 to 0 of 0 users",
				infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total users)",
				lengthMenu: "Show _MENU_ users",
			},
			initComplete: () => {
				const $scroll = $('#usertable_container').find('.dataTables_scroll')
				const $scrollBody = $scroll.find('.dataTables_scrollBody')
				$scrollBody.doubleScroll({
					$insertBefore: $scroll,
				})
			},
		})

		$('#impersonateTableContainer').find('th').addClass('custom-header')

		$('#impersonateFilters').on('change', () => {
			impersonateDt.ajax.reload(undefined, false)
		})

		$('#impersonateTableContainer').off('click').on('click', '.show-edit', ({ target }) => {
			let row = impersonateDt.row($(event.target).closest('tr')).data()
			impersonate(row)
		})

		return impersonateDt
	}
	initImpersonateDt()

	async function impersonate(row) {
		try {
			const { rc, redirect } = await fetchPost('/data/submit', { type: 'impersonate', data: { user_id: row.user_id } })
			if (rc != 'OK') throw rc
			window.location = redirect
		} catch (err) {
			console.log(err)
		}
	}

	$('#impersonate_exit').off('click').on('click', async () => {
		try {
			const { rc, redirect } = await fetchPost('/data/submit', { type: 'exit_impersonate', })
			if (rc != 'OK') throw rc

			window.location = redirect
		} catch (err) {
			displayNotification('Error Exiting Impersonation', 'danger')
			console.log(err)
		}
	})
}

async function initFinance() {
	//check if this is a redirect from quickbooks
	if ($('#qbIsCallback').val() != null) {
		const $modal = $('#qbModal').modal('show')
		const $loading = $('#qbModalLoading').show()
		const $content = $('#qbModalContent').hide()

		const status = $('#qbCallbackStatus').val()
		if (status == QB_AUTH_SUCCESS) { //successful reconnect
			$('#qbConnectHeader').html('Successfully Connected! ')
			$('#qbConnectBody').html('You have successfully reconnected to your quickbooks online company. Automation is now active.')
			$('#qbConnectIcon').addClass('text-success fa-circle-check').removeClass('text-danger fa-times')
			$('#qbConnectJumptoSync').hide()
		} else if (status == QB_AUTH_INITIAL_SUCCESS) { //first time connecting - success
			$('#qbConnectHeader').html('Successfully Connected! ')
			$('#qbConnectBody').html(' Congratulations your quickbooks account is now connected. You may now start automatically syncing payers and rates.')
			$('#qbConnectIcon').addClass('text-success fa-circle-check').removeClass('text-danger fa-times')
			$('#qbConnectJumptoSync').show()
			showConfetti()
		} else { //unsuccessful connection
			$('#qbConnectHeader').html('Connection Unsuccessful ')
			$('#qbConnectBody').html('Unforunately something went wrong during the authorization process. Please try again or reach out to IT support.')
			$('#qbConnectIcon').addClass('text-danger fa-times').removeClass('text-success fa-circle-check')
			$('#qbConnectJumptoSync').hide()
		}

		$('#qbConnectJumptoSync').off('click').on('click', () => {
			$modal.modal('hide')
			toTabNew('pills-profile')
		})

		$loading.hide()
		$content.show()
	}

	const showLoadingError = () => {
		$('.qbStatsContentContainer').hide()
		$('.qbStatsError').show()
	}

	const loadStats = async () => {
		//start loading all cards
		const $loading = $('.qb_stats_loading').show()
		const $content = $('.qb_stats_content').hide()

		try {
			const res = await fetchPost('/support/submit', { type: 'get_qb_stats' })
			if (res.rc != 'OK') throw res.rc

			//connections card
			$('#qbStatsConnSuccess').html(`${res.connections.active} Successful Connection`)
			$('#qbStatsConnFail').html(`${res.connections.inactive} Programs Not Connected `)

			//synced payer card
			$('#qbStatsPayersSynced').html(`${res.payers.synced} Active Payers Connected`)
			$('#qbStatsPayersUnsynced').html(`${res.payers.not_synced} Active Payers Not Connected`)

			//refresh card
			$('#qbStatsRefresh').html(`${res.activity.past_24_hours}/${res.connections.total} Successful Refreshes in the Past 24 Hours`)

			//upload card
			$('#qbUploadStatsDate').html(`${res.recent_upload.date}`)
			$(`#qbUploadStatStatus`).html(`${res.recent_upload.success_pretty}`)
			if (Number(res.recent_upload.success) === UPLOAD_SUCCESS) { //if there was a successful upload
				$(`#qbUploadStatStatusIcon`).removeClass('text-danger fa-triangle-exclamation').addClass('text-success fa-check')
			} else {
				$(`#qbUploadStatStatusIcon`).removeClass('text-success fa-check').addClass('text-danger fa-triangle-exclamation')
			}
		} catch (err) {
			console.log(err)
			return showLoadingError()
		} finally {
			$loading.hide()
			$content.show()
		}
	}

	loadStats()

	const qbOverview = () => {
		type program = number | null

		$('.qb_program_expand').off('click').on('click', async ({ target }) => loadQbProgram(target))

		$('#qbProgRefresh').off('click').on('click', async ({ target }) => {
			const program: program = $('#qbProgContainer').data('program')
			loadQbProgram(target, program)
		})

		const loadQbProgram = async (target: HTMLElement, prog_id: program = null) => {
			const $loading = $('#qbProgLoading')
			$('#qbProgContainer').hide()
			$loading.show()

			const bsOffcanvas = new Offcanvas($('#offcanvasBottom') as any)
			let program: program = null
			if (prog_id == null) { //inital load
				bsOffcanvas.show() //only show canvas if it is closed
				let $row: $ = $(target).closest('.qb_program_expand')
				program = $row.data('program')
				$('#qbProgContainer').data('program', program)
			} else {
				program = prog_id
			}

			try {
				const res = await fetchPost('/support/submit', { type: 'qb_program_data', program: program })
				if (res.rc != 'OK') throw (res.rc)
				const data = res.data

				//map the data
				$('#qbProgTitle').text(`${data.prog_name} Connection`)
				data.qb_auth_status == QB_CONNECT_ACTIVE ? $('#qbProgStatus').addClass('text-success').removeClass('text-danger').text('Active') : $('#qbProgStatus').addClass('text-danger').removeClass('text-success').text('Inactive')
				data.qb_auth_status == QB_CONNECT_ACTIVE ? $('#qbProgRenewToken').prop('disabled', true) : $('#qbProgRenewToken').prop('disabled', false)
				$('#qbProgLastUpdate').text(data.qb_auth_datepretty)
				$('#qbProgUploadDate').val(data.upload_date)

				//rebind click handler manually
				$('#qbProgCloseBtn').on('click', () => bsOffcanvas.hide())

			} catch (err) {
				console.error('Error getting programs', err)
				displayNotification('Error getting programs', 'danger')
				bsOffcanvas.hide()
				return
			} finally {
				$loading.hide()
				$('#qbProgContainer').show()
			}

			const triggerDateChange = async (date, program) => {
				try {
					const { rc } = await fetchPost('/support/submit', { type: 'change_upload_date', data: { date, program } })
					if (rc != 'OK') throw rc
					displayNotification('Successfully Changed Upload Date', 'success')
				} catch (err) {
					displayNotification('Error changing upload date', 'danger')
					console.log('error disconnecting', err)
				}
			}

			$('#qbProgUploadDate').off('change').on('change', function () {
				const dateString = String($(this).val())
				if (!dateString) return displayNotification('Error getting date', 'danger')

				// Adjust how the Date object is created
				const parts = dateString.split('-')
				const year = parseInt(parts[0], 10)
				const month = parseInt(parts[1], 10) - 1 // Month is 0-indexed
				const day = parseInt(parts[2], 10)
				const dateObject = new Date(year, month, day)
				const options: Intl.DateTimeFormatOptions = {
					year: 'numeric',
					month: 'long',
					day: 'numeric'
				};
				const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObject)
				confirmDialog({
					dialogTitle: 'Date Change',
					bodyText: `<p>Are you sure you would like to change the upload date to ${formattedDate}.</p>`,
					confirmText: 'Save',
					confirmStyle: 'primary',
					showCancelButton: true,
					confirmFunction: () => triggerDateChange(dateString, program)
				});
			});

			$('#qbProgRenewToken').off('click').on('click', async () => {
				if (program == null) return displayNotification('Could not access program data', 'danger')
				startQuickbooksConnect(program)
			})

			$('#qbProgOperationFee').off('click').on('click', async ({ target }) => {
				if (program == null) return displayNotification('Could not access program data', 'danger')
			})

			$('#qbProgSingleRate').off('click').on('click', async ({ target }) => {
				if (program == null) return displayNotification('Error accessing program data', 'danger')
				try {
					confirmDialog({
						dialogTitle: 'Toggle Single Cost for all Ages',
						bodyText: '<p> Are you sure you want to enable unfiform cost between ages? This will enforce that all costs are the same between ages and cause any rate connections to be disconnected.  </p>',
						confirmText: 'Submit <i class="fas fa-unlink"></i>',
						confirmStyle: 'primary',
						showCancelButton: false,
						confirmFunction: () => triggerSingleCost(program)
					})
				} catch (err) {
					console.log('error disconnecting', err)
				}
			})

			const triggerSingleCost = async (program) => {
				try {
					const { rc } = await fetchPost('/support/change_costs', { program })
					if (rc != 'OK') throw rc

					displayNotification('Successfully Changed Costs', 'success')
				} catch (err) {
					console.log('error disconnecting', err)
				}
			}

			const disconnectQb = async (program) => {
				try {
					const { rc } = await fetchPost('/login/disconnect_local_qb', { program })
					if (rc != 'OK') throw rc

					displayNotification('Disconnected from Quickbooks', 'success')
					bsOffcanvas.hide()

					//update icon on frontend
					const $anchor = $('a[data-program="' + program + '"]')
					console.log('anchor', $anchor)
					const $icon = $anchor.find('.isConnected')

					console.log('icon', $icon)
					$icon.addClass('text-danger fa-unlink').removeClass('fa-link text-success')
				} catch (e) {
					console.log('error disconnecting', e)
					displayNotification('Error Disconnecting', 'danger')
				}
			}

			//disconnect from program entirely
			$('#qbProgDisconnect').off('click').on('click', async () => {
				try {
					confirmDialog({
						dialogTitle: 'Disconnect from Quickbooks Online',
						bodyText: '<p> Are you sure you want to disconnect? </p>',
						confirmText: 'Disconnect <i class="fas fa-unlink"></i>',
						confirmStyle: 'primary',
						showCancelButton: false,
						confirmFunction: () => disconnectQb(program)
					})
				} catch (err) {
					console.log('error disconnecting', err)
				}
			})
		}

		const startQuickbooksConnect = async (program: number) => {
			const qbConnect = async () => post('/login/connectQb', { program })
			try {
				confirmDialog({
					dialogTitle: 'Connect To Quickbooks Online',
					bodyText: '<p> Are you sure you want to connect to quickbooks online?</p>',
					confirmText: 'Connect <i class="fas fa-link"></i>',
					confirmStyle: 'primary',
					showCancelButton: false,
					confirmFunction: () => qbConnect()
				})

			} catch (err) {
				displayNotification('Error Saving New User', 'danger')
				console.log(err)
			}
		}

		$('#qb_dev_connect').off('click').on('click', async () => {
			const program = 0
			if (program == null) return displayNotification('Could not access program data', 'danger')
			startQuickbooksConnect(program)
		})

		$('.qb_connect').off('click').on('click', async (e) => {
			const program = Number($(e.currentTarget).data('program'))
			console.log('this is the progarm', program)
			if (program == null) return displayNotification('Could not access program data', 'danger')
			startQuickbooksConnect(program)
		})

		setCollapse({ $btn: $('#uploadFilterCollapse'), $target: $('#uploadFilters') })
		setCollapse({ $btn: $('#uploadProgramCollapse'), $target: $('#uploadProgram') })
		setCollapse({ $btn: $('#payerSyncFilterCollapse'), $target: $('#syncFilters') })

		const getUploadFilters = () => ({
			program: $('#uploadFilterProgram').val(),
			payer: $('#uploadFilterPayer').val(),
			status: $('#uploadFilterStatus').val(),
			mod_date: $('#uploadFilterDate').val(),
			batch: $('#uploadFilterBatch').val(),
		})

		const initUploadDt = () => {
			const columnsInfo = [
				{
					data: 'upload_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prog_name',
					header: 'Program',
				},
				{
					data: 'payer_name',
					header: 'Payer',
				},
				{
					data: 'rate_name',
					header: 'Rate',
				},
				{
					data: 'upload_datepretty',
					header: 'Upload Date',
				},
				{
					data: 'upload_successful',
					header: 'Upload Status',
					render: function (data, type, row) {
						if (type === 'display') {
							if (data == FILING_UPLOADED) {
								return '<span class="badge rounded pill text-bg-success fs-7"><i class="fa fa-check-circle"></i><span> Success</span> <span>'
							} else {
								return '<span class="badge rounded pill text-bg-danger fs-7"><i class="fa fa-times-circle"></i><span> Failed</span> <span>'
							}
						}
						return data;
					},
				},
			]

			const headers = columnsInfo.map((col) => col.header)
			const uploadDt = $('#uploadsTable').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/uploads',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						filters: getUploadFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'DESC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No uploads to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> uploads",
					infoEmpty: "Showing 0 to 0 of 0 uploads",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total uploads)",
					lengthMenu: "Show _MENU_ uploads",
				},
			})

			$('#uploadTableContainer').find('th').addClass('custom-header')

			$('#uploadsTable').on('click', 'tbody tr', ({ target }) => {
				const upload = getTableRowData(uploadDt, target) as Upload
				viewUpload(upload)
			})

			return uploadDt
		}
		const uploadDt = initUploadDt()

		const reloadUploadDt = () => uploadDt.ajax.reload(undefined, false)
		$('#uploadFilters').on('change', () => reloadUploadDt())

		const closeUploadDetails = () => $('#qbUploadInfoContainer').slideUp(slideSpeed)
		$('#qbInfoClose').on('click', () => closeUploadDetails())

		type Upload = {
			upload_id: number,
			prog_name: string,
			payer_name: string,
			rate_name: string,
			upload_datepretty: string,
			upload_successful: number,
			upload_status: string,
			upload_logs: string
			file_program: string
			file_amount: number
		}

		collapseClear({ $btn: $('#qbUploadGlobal'), $target: $('#qbUploadFilterContainer'), $clear: $('#qbUploadClear') })

		$('#qbUploadFilings').off('click').on('click', async () => {
			confirmDialog({
				dialogTitle: 'Upload Next Batch',
				bodyText: '<p> Are you sure you want to upload the next batch?</p>',
				confirmText: 'Upload',
				confirmStyle: 'primary',
				showCancelButton: false,
				confirmFunction: () => uploadNextBatch()
			})
		})

		const reloadBatchFilter = async () => {
			try {
				const { rc, data } = await fetchPost('/support/submit', { type: 'get_batch_filter' })
				if (rc != 'OK') throw rc
			} catch (err) {
				console.log(err)
				displayNotification('Error Getting Batch Filter', 'danger')
			}
		}

		const uploadNextBatch = async () => {
			const $spinner = $('#qbUploadFilings').find('.spinner-border').show()
			const $text = $('#qbUploadText').hide()
			const clearBatch = $('#qbUploadClearBatch').is(':checked') ? true : false
			const postData = {
				clear_batch: clearBatch,
				programs: $('#qbUploadProgram').val(),
			}
			try {
				const { rc, batch, empty_filing_programs } = await fetchPost('/support/submit', { type: 'upload_next_batch', data: postData })
				if (rc != 'OK') throw rc

				if (empty_filing_programs.length > 0) {
					displayNotification(`The following programs have no filings: ${empty_filing_programs.join(', ')}`, 'info')
				}

				displayNotification('Successfully Uploaded Next Batch', 'success')
				reloadUploadDt()
			} catch (err) {
				console.log(err)
				displayNotification('Error Uploading Next Batch', 'danger')
			} finally {
				$spinner.hide()
				$text.show()
			}
		}

		const viewUpload = (upload: Upload) => {
			scrollTop()
			$('#qbUploadInfoContainer').slideDown(slideSpeed)

			//banner for upload status
			if (upload.upload_successful == FILING_UPLOADED) {
				$('#qbInfoSuccess').removeClass('alert-danger').addClass('alert-success')
				$('#qbInfoSuccess').find('#qbInfoSuccessIcon').removeClass('fa-solid, fa-x').addClass('fa-check')
				$('#qbInfoSuccessLang').text('Successfully uploaded')
				$('#qbUploadRetry').prop('disabled', true)
			} else {
				$('#qbInfoSuccess').removeClass('alert-success').addClass('alert-danger')
				$('#qbInfoSuccess').find('#qbInfoSuccessIcon').removeClass('fa-check-circle').addClass('fa-solid, fa-x')
				$('#qbInfoSuccessLang').text('Failed to Upload')
				$('#qbUploadRetry').prop('disabled', false)
			}

			//general info
			$('#qbInfoProgram').text(upload.prog_name)
			$('#qbInfoPayer').text(upload.payer_name)
			$('#qbInfoRate').text(upload.rate_name)
			$('#qbInfoDate').text(upload.upload_datepretty)
			$('#qbInfoFiling').html(`Reference: <span id='qbInfoRef'>${upload.file_program}</span> - ${formatter.format(upload.file_amount / 100)}`)

			//logs
			const logs: string[] = upload.upload_logs ? JSON.parse(upload.upload_logs) : []
			type CustomInput = {
				lang: $,
				icon: $,
				source: string | number,
			}

			type CustomForm = {
				[key: string]: CustomInput;
			}

			const logMap: CustomForm = {
				'error_nodata': {
					lang: $('#errorDataLang'),
					icon: $('#errorDataIcon'),
					source: 'Sufficient Data',
				},
				'error_nopayer': {
					lang: $('#errorPayerLang'),
					icon: $('#errorPayerIcon'),
					source: 'Payer Found',
				},
				'error_result': {
					lang: $('#errorResultsLang'),
					icon: $('#errorResultsIcon'),
					source: 'Results received',
				},
				'error_quickbooks': {
					lang: $('#errorQBLang'),
					icon: $('#errorQbIcon'),
					source: 'Quickbooks Connection Established',
				},
			}

			let hasErrorOccurred = false
			for (const errorKey in logs) {
				if (logs.hasOwnProperty(errorKey)) {

					const errorValue: number = Number(logs[errorKey])

					// Skip if the log doesn't match any key in logMap
					if (!logMap[errorKey]) continue

					const { lang, icon, source } = logMap[errorKey]

					if (errorValue == TRIGGER_ERROR || hasErrorOccurred) {
						lang.text(`${source}: Failed`);
						icon.removeClass('fa-check text-success').removeClass('fa-times text-danger').addClass('fa-solid fa-x')

						// Set the flag to true if an error occurs
						if (!hasErrorOccurred) hasErrorOccurred = true
					} else {
						lang.text(`${source}: Success`)
						icon.removeClass('fa-solid fa-x').removeClass('fa-times text-danger').addClass('fa-check text-success')
					}
				}
			}

			$('#qbUploadRetry').off('click').on('click', async () => {
				const $icon = $('#qbUploadRetryIcon').hide()
				const $spinner = $('#qbUploadRetrySpinner').show()
				try {
					const res = await fetchPost('/support/submit', { type: 'upload_retry', data: { upload_id: upload.upload_id } })
					if (res.rc != 'OK') throw res.rc

					displayNotification('Success Uploading Filing', 'success')
					reloadUploadDt()
				} catch (err) {
					if (err == 'NO_FILE') return displayNotification('Insufficient Filing Data', 'danger')
					console.log(err)
					displayNotification('Failed Uploading Filing', 'danger')
				} finally {
					$spinner.hide()
					$icon.show()
				}
			})


			$('#qbFilingCopy').off('click').on('click', async function () {
				try {
					const textToCopy = $('#qbInfoRef').text()
					await navigator.clipboard.writeText(textToCopy)

					// Retrieve the tooltip instance
					const tooltipInstance = Tooltip.getInstance(this)

					// Update the content using setContent
					tooltipInstance.setContent({
						'.tooltip-inner': 'Copied!'
					})

					// You may need to manually show the tooltip again
					tooltipInstance.show()

					setTimeout(() => {
						tooltipInstance.hide()
						tooltipInstance.setContent({
							'.tooltip-inner': 'Copy Reference #'
						})
					}, 3000)

				} catch (error) {
					console.error('Error copying text:', error)

					const tooltipInstance = bootstrap.Tooltip.getInstance(this)
					tooltipInstance.setContent({
						'.tooltip-inner': 'Failed to copy!'
					})
					tooltipInstance.show()
				}
			});
		}

		//help modal
		$('#payerSyncHelp').off('click').on('click', async () => $('#psHelpModal').modal('show'))

		//launch qb
		$('#payerSyncLaunchQb').off('click').on('click', async () => toTab('payer_compare'))

		$('#payerSyncProgram, #payerSyncType, #syncRateContainer').off('change').on('change', async () => initSync())

		// change category
		$('#payerSyncCategory').off('change').on('change', async () => {
			changeSyncCategory(Number($('#payerSyncCategory').val()))
			initSync()
		})

		//toggles for updating proogram and rate selects for only active quickbook connections
		$('#payerSyncToggleConnect').off('change').on('change', async () => {
			try {
				await updateProgramSelect($('#payerSyncToggleConnect').is(':checked'))
				initSync()
			} catch (e) {
				console.log('error updating program select: ', e)
			}
		})

		$('#rateSyncToggleConnect').off('change').on('change', async () => {
			try {
				await updateRateSelect($('#rateSyncToggleConnect').is(':checked'))
				initSync()
			} catch (e) {
				console.log('error updating rate select: ', e)
			}
		})

		const changeSyncCategory = async (category: number) => {
			const isPayerSync = category == SYNC_PAYER;
			$('#syncPayerContainer').toggle(isPayerSync);
			$('#syncRateContainer').toggle(!isPayerSync);
		}

		//for inital render - trigger payer category
		changeSyncCategory(Number($('#payerSyncCategory').val()))

		//program select for payer sync
		const updateProgramSelect = async (isSelected: boolean) => {
			let progSelect = $('#payerSyncProgram')
			if (progSelect.prop('disabled')) return

			try {
				progSelect.prop('disabled', true).trigger('chosen:updated')
				const { rc, programs } = await fetchPost('/support/submit', { type: 'update_program_select', data: { isSelected } })
				if (rc != "OK") throw rc

				progSelect.empty().trigger('chosen:updated')
				let options = ''
				$.each(programs, function (_, prog) {
					options += `<option value="${prog.prog_id}">${prog.prog_name}</option>`
				})

				progSelect.html(`<optgroup label="Programs">${options}</optgroup>`)
				progSelect.trigger('chosen:updated')
			} catch (e) {
				console.log('error updating program select: ', e)
				displayNotification('Failed to update payer list', 'danger')
				progSelect.prop('disabled', false).trigger('chosen:updated')
			} finally {
				progSelect.prop('disabled', false).trigger('chosen:updated')
			}
		}

		//program select for rate sync
		const updateRateSelect = async (isSelected: boolean) => {
			let rateSelect = $('#rateSyncProgram')
			if (rateSelect.prop('disabled')) return

			try {
				rateSelect.prop('disabled', true).trigger('chosen:updated')
				const { rc, programs } = await fetchPost('/support/submit', { type: 'update_program_select', data: { isSelected } })
				if (rc != "OK") throw rc

				rateSelect.empty().trigger('chosen:updated')
				let options = ''
				$.each(programs, function (_, prog) {
					options += `<option value="${prog.prog_id}">${prog.prog_name}</option>`
				})

				rateSelect.html(`<optgroup label="Programs">${options}</optgroup>`)
				rateSelect.trigger('chosen:updated')
			} catch (e) {
				console.log('error updating program select: ', e)
				displayNotification('Failed to update rate program list', 'danger')
				rateSelect.prop('disabled', false).trigger('chosen:updated')
			} finally {
				rateSelect.prop('disabled', false).trigger('chosen:updated')
			}
		}

		//toggle between auto and manual payer sync
		const togglePayerContent = async (type: SyncType = null, prog_id: number = null) => {
			type == null ? type = Number($('#payerSyncType').val()) as SyncType : type = type

			const isAutoSync = type === SYNCT_AUTO
			$('#payerSyncAutomaticContainer').toggle(isAutoSync)
			$('#payerSyncManual').toggle(!isAutoSync)

			if (isAutoSync) { //check if we have this program connected if we are on auto
				const $payer_loading = $('#psAutoLoading').show()
				const $payer_content = $('#psAutoContent').hide()
				try {
					const res = await fetchPost('/support/submit', { type: 'sync_get_data', data: { prog_id } });
					if (res.rc != 'OK') throw res.rc

					let wording = ''
					const isConnected = Number(res.status) === QB_CONNECT_ACTIVE
					isConnected ? wording = 'Start Connect When Ready.' : wording = 'Automatic Connect Requires a Quickbooks Connection.'
					$('#psAutoLang').text(wording)

					if (isConnected) {
						$('#psAutoLang').find('i').removeClass('fa-warning').addClass('fa-check-circle')
						$('#psAutoLang').removeClass('text-warning').addClass('text-primary')
						$('#psAutoStart').prop('disabled', false)
						$('#psAutoStart').prop('disabled', false)

						$('#psAutoTotal').html(`${res.remaining_count} Remaining Customers / ${res.total} Total Customers`)

						$('#expandRemainingPayers').off('click').on('click', () => expandRemainingPayers(prog_id, 1))
					} else {
						$('#psAutoLang').find('i').removeClass('fa-check-circle text-primary').addClass('fa-check-circle text-warning')
						$('#psAutoLang').addClass('text-warning').removeClass('text-primary')
						$('#psAutoStart').prop('disabled', true)
						$('#psAutoTotal').html(`0 Customers`)
					}
				} catch (error) {
					console.log('error', error);
				} finally {
					$payer_loading.hide()
					$payer_content.show()
				}
			}
		}

		function createClickHandler(program, pageNum) {
			return function () {
				expandRemainingPayers(program, pageNum)
			}
		}

		// Function to create a page item
		function createPageItem(i, currentPage, program) {
			const $link = $(`<a href='#' class='page-link'>${i}</a>`);
			if (i === currentPage) $link.addClass('active');
			$link.off('click').on('click', createClickHandler(program, i));
			return $(`<li class='page-item'></li>`).append($link);
		}

		const expandRemainingPayers = async (program: number, page: number) => {
			const $modal = $('#psRemainingModal').modal('show')
			const $spinner = $('#psRemainingSpinner').show()
			const $content = $('#psRemainingContent').hide()
			$('#remainingCustomers').empty()
			try {
				const res = await fetchPost('/support/submit', { type: 'get_remaining_customers', data: program, page: page })
				if (res.rc != 'OK') throw res.rc

				if (res.remaining_count == 0) {
					$modal.modal('hide')
					return displayNotification('No Remaining Payers to Expand', 'info')
				}

				if (res.count) $('psRemainingModalLabel').html(`${res.count} Remaining Payers`)

				const customers = res.customers
				for (const key in customers) {
					if (customers.hasOwnProperty(key)) {
						const customer = customers[key]
						$('#remainingCustomers').append(`<a href='#' id="cust${customer.id}" class="list-group-item list-group-item-action">${customer.DisplayName}`)
					}
				}

				// Add pagination links
				const totalPages = Math.ceil(res.remaining_count / 5);
				const $pagination = $('#remainingCustomersPagination').empty();

				// // Add "Previous" button
				// const $prevLink = $(`<a href='#' class='page-link'>Previous</a>`)
				// if (page === 1) $prevLink.addClass('disabled')
				// $prevLink.off('click').on('click', () => {
				// 	if (page > 1) expandRemainingPayers(program, page - 1)
				// })
				// const $prevItem = $(`<li class='page-item'></li>`).append($prevLink)
				// $pagination.append($prevItem)

				// // Add page numbers
				// for (let i = 1; i <= totalPages; i++) {
				// 	const $link = $(`<a href='#' class='page-link'>${i}</a>`)
				// 	if (i === page) $link.addClass('active')

				// 	$link.off('click').on('click', createClickHandler(program, i))

				// 	const $item = $(`<li class='page-item'></li>`).append($link)
				// 	$pagination.append($item)
				// }

				// // Add "Next" button
				// const $nextLink = $(`<a href='#' class='page-link'>Next</a>`)
				// if (page === totalPages) $nextLink.addClass('disabled')
				// $nextLink.off('click').on('click', () => {
				// 	if (page < totalPages) expandRemainingPayers(program, page + 1)
				// })
				// const $nextItem = $(`<li class='page-item'></li>`).append($nextLink)
				// $pagination.append($nextItem)
				// Define the number of page links to show around the current page
				const numPagesToShow = 5;

				// Add "Previous" button
				const $prevLink = $(`<a href='#' class='page-link'>Previous</a>`);
				if (page === 1) $prevLink.addClass('disabled');
				$prevLink.off('click').on('click', () => {
					if (page > 1) expandRemainingPayers(program, page - 1);
				});
				const $prevItem = $(`<li class='page-item'></li>`).append($prevLink);
				$pagination.append($prevItem);

				// Calculate the range of page numbers to show
				let startPage = Math.max(page - Math.floor(numPagesToShow / 2), 1);
				let endPage = Math.min(startPage + numPagesToShow - 1, totalPages);

				// Adjust the range if we're close to the start or end
				if (endPage - startPage + 1 < numPagesToShow) {
					startPage = Math.max(endPage - numPagesToShow + 1, 1);
				}

				// Add first page and ellipsis if necessary
				if (startPage > 1) {
					$pagination.append(createPageItem(1, page, program));
					if (startPage > 2) {
						$pagination.append($(`<li class='page-item disabled'><span class='page-link'>...</span></li>`));
					}
				}

				// Add page numbers within the defined range
				for (let i = startPage; i <= endPage; i++) {
					const $item = createPageItem(i, page, program);
					$pagination.append($item);
				}

				// Add ellipsis and last page if necessary
				if (endPage < totalPages) {
					if (endPage < totalPages - 1) {
						$pagination.append($(`<li class='page-item disabled'><span class='page-link'>...</span></li>`));
					}
					$pagination.append(createPageItem(totalPages, page, program));
				}

				// Add "Next" button
				const $nextLink = $(`<a href='#' class='page-link'>Next</a>`);
				if (page === totalPages) $nextLink.addClass('disabled');
				$nextLink.off('click').on('click', () => {
					if (page < totalPages) expandRemainingPayers(program, page + 1);
				});
				const $nextItem = $(`<li class='page-item'></li>`).append($nextLink);
				$pagination.append($nextItem);

			} catch (error) {
				console.log(error)
				displayNotification('Error Expanding Payers', 'danger')
			} finally {
				$spinner.hide()
				$content.show()
			}
		}

		const toggleRateContent = async (prog_id: number | null = null) => {
			const $rate_loading = $('#rsAutoLoading').show()
			const $rate_content = $('#rsAutoContent').hide()
			try {
				const res = await fetchPost('/support/submit', { type: 'sync_get_data', data: { prog_id } });
				if (res.rc != 'OK') throw res.rc

				let wording = ''
				const isConnected = Number(res.status) === QB_CONNECT_ACTIVE
				isConnected ? wording = 'Start Connect When Ready.' : wording = 'Automatic Connect Requires a Quickbooks Connection.'
				$('#rsAutoLang').text(wording)

				if (isConnected) {
					$('#rsAutoLang').find('i').removeClass('fa-warning').addClass('fa-check-circle')
					$('#rsAutoLang').removeClass('text-warning').addClass('text-primary')
					$('#rsAutoStart').prop('disabled', false)
				} else {
					$('#rsAutoLang').find('i').removeClass('fa-check-circle text-primary').addClass('fa-check-circle text-warning')
					$('#rsAutoLang').addClass('text-warning').removeClass('text-primary')
					$('#rsAutoStart').prop('disabled', true)
				}

			} catch (error) {
				console.log('error', error);
			} finally {
				$rate_loading.hide()
				$rate_content.show()
			}
		}

		// const expandRemainingRates = async (program: number) => {
		// 	$("#psRemainingModal").modal('show')
		// 	const $spinner = $('#psRemainingSpinner').show()
		// 	const $content = $('#psRemainingContent').hide()
		// 	$('#remainingCustomers').empty()
		// 	try {
		// 		console.log(program)
		// 		const res = await fetchPost('/support/submit', { type: 'get_remaining_customers', data: program })
		// 		if (res.rc != 'OK') throw res.rc

		// 		if (res.count == 0) {
		// 			$("#psRemainingModal").modal('hide')
		// 			return displayNotification('No Remaining Payers to Expand', 'info')
		// 		}

		// 		if (res.count) $('psRemainingModalLabel').html(`${res.count} Remaining Payers`)

		// 		const customers = res.customers
		// 		for (const key in customers) {
		// 			if (customers.hasOwnProperty(key)) {
		// 				const customer = customers[key]
		// 				$('#remainingCustomers').append(`<a href='#' id="cust${customer.id}" class="list-group-item list-group-item-action">${customer.CompanyName}`)
		// 			}
		// 		}

		// 		displayNotification('Success Expanding Payers', 'success')
		// 	} catch (error) {
		// 		console.log(error)
		// 		displayNotification('Error Expanding Payers', 'danger')
		// 		$("#psRemainingModal").modal('hide')
		// 	} finally {
		// 		$spinner.hide()
		// 		$content.show()
		// 	}
		// }

		let syncDt: DataTables.Api | null = null
		const $SyncTableTemplate = $('#syncTable').remove()

		type SyncType = typeof SYNCT_AUTO | typeof SYNCT_MANUAL

		const initSync = () => {
			console.log('initializing sync')
			const isPayerSync = Number($('#payerSyncCategory').val()) == SYNC_PAYER
			const category = Number($('#payerSyncCategory').val())
			const progId = isPayerSync ? Number($('#payerSyncProgram').val()) : Number($('#rateSyncProgram').val())

			console.log('program id', progId)

			const type: SyncType = Number($('#payerSyncType').val()) as SyncType

			isPayerSync ? togglePayerContent(type, progId) : toggleRateContent(progId)

			const getPayerSyncFilters = () => ({
				date: $('#psFilterDate').val(),
				payer: $('#psFilterPayer').val(),
				status: $('#psFilterStatus').val(),
			})

			if (syncDt != null) {
				syncDt.destroy()
				$('#SyncTableContainer').empty()
			}

			const $SyncTable = $SyncTableTemplate.clone()
			$('#SyncTableContainer').append($SyncTable)
			const columnsInfo = [
				{
					data: 'ps_id',
					header: 'Id',
					visible: false,
				},
				{
					data: 'prog_name',
					header: 'Program',
				},
				{
					data: 'rate_name',
					header: 'Rate',
					visible: category === SYNC_RATE ? true : false,
				},
				{
					data: 'payer_name',
					header: 'Payer',
					visible: category === SYNC_PAYER ? true : false,
				},
				{
					data: 'ps_datepretty',
					header: 'Sync Date',
				},
				{
					data: 'ps_status',
					header: 'Status',
					render: function (data, type, row) {
						if (type === 'display') {
							if (data == SYNC_COMPLETE) {
								return '<span class="badge rounded pill text-bg-success fs-7"><i class="fa fa-check-circle"></i><span> Connected</span> <span>'
							} else {
								return '<span class="badge rounded pill text-bg-danger fs-7"><i class="fa fa-times-circle"></i><span> Failed</span> <span>'
							}
						}
						return data;
					},
				},
			]

			const headers = columnsInfo.map(col => col.header);
			syncDt = $('#syncTable').tableHeaders(headers).DataTable({
				ajax: {
					url: '/tables/sync',
					type: 'POST',
					headers: {
						'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
					},
					data: (postData) => ({
						...postData,
						program: progId,
						isPayerSync: isPayerSync ? 1 : 0,
						filters: getPayerSyncFilters(),
					}),
				},
				scrollX: true,
				columns: columnsInfo,
				columnDefs: [{ targets: '_all', defaultContent: '' }],
				order: [[0, 'ASC']],
				serverSide: true,
				processing: true,
				responsive: {
					details: false,
				},
				language: {
					emptyTable: "No connections to show",
					info: "Showing <span class='txt-color-darken'>_START_</span> to <span class='txt-color-darken'>_END_</span> of <span class='text-primary'>_TOTAL_</span> connections",
					infoEmpty: "Showing 0 to 0 of 0 connections",
					infoFiltered: "(filtered from <span class='txt-color-darken'>_MAX_</span> total connections)",
					lengthMenu: "Show _MENU_ connections",
				},
			})

			$('#SyncTableContainer').find('th').addClass('custom-header')

			$('#syncFilters').off('change').on('change', () => reloadSync())

			const reloadSync = () => {
				if (syncDt == null) return displayNotification('Sync Table is null, cannot reload table', 'danger')
				syncDt.ajax.reload()
			}

			$('#syncTable').on('click', 'tbody tr', ({ target }) => {
				if (syncDt == null) return displayNotification('Payer Table is null', 'danger')
				const data = getTableRowData(syncDt, target) as any
				data.ps_payerid != null ? expandPayerSync(data) : expandRateSync(data)
			})

			$('#psManToggle').off('change').on('change', () => $('#psManStructure').toggle())

			type handlePayerType = {
				type: SyncType,
				targetElement: HTMLElement,
				programId: number,
			}

			const handlePayerSync = ({ target }) => {
				const postData: handlePayerType = { // Define postData based on clicked element
					type: target.id === 'psAutoStart' ? SYNCT_AUTO : SYNCT_MANUAL,
					targetElement: target,
					programId: progId,
				}
				startPayerSync(postData)
			}

			$('#psAutoStart, #psManStart').off('click').on('click', handlePayerSync)

			const updateRemainingCustomers = (remaining, total) => {
				const $psAutoTotal = $('#psAutoTotal');
				const $psAutoTotalLoading = $('#psAutoTotalLoading');

				$psAutoTotalLoading.show();
				$psAutoTotal.html(`${remaining} Remaining Customers / ${total} Total Customers`).hide().fadeIn();
				$psAutoTotalLoading.hide();
			}

			const startPayerSync = async (syncData: handlePayerType) => {
				const { type, targetElement, programId } = syncData
				const $spinner = $(targetElement).closest('button').find('.spinner-border')

				let route: string | null = null
				type == SYNCT_AUTO ? route = 'sync_payer_auto' : route = 'sync_payer_manual'

				//we need the files if type is manual
				const formData = new FormData()
				if (type == SYNCT_MANUAL) {
					const files = $('#psManFile').prop('files')
					formData.append('file', files[0])
				}

				try {
					$spinner.show()
					const res = await fetchPost('/support/submit', { type: route, data: { program: programId }, files: formData })
					if (res.rc != 'OK') throw res.rc

					displayNotification('Sync Completed Successfully', 'success')
					reloadSync()
					updateRemainingCustomers(res.remaining_count, res.total)

				} catch (error) {
					console.log('error', error)
					displayNotification('Sync Failed To Run', 'danger')
				} finally {
					$spinner.hide()
				}
			}

			$('#rsAutoStart').off('click').on('click', async (e) => {
				const $spinner = $(e.currentTarget).closest('button').find('.spinner-border').show()
				const programId = $('#rateSyncProgram').val()
				try {
					const res = await fetchPost('/support/submit', { type: 'sync_rate_auto', data: { program: programId } })
					if (res.rc != 'OK') throw res.rc

					displayNotification('Rate Sync Complete', 'success')
					reloadSync()
				} catch (error) {
					console.log('error', error)
					displayNotification('Rate Sync Failed To Run', 'danger')
				} finally {
					$spinner.hide()
				}
			})

			type syncData = {
				ps_id: number,
				prog_name: string,
				payer_name: string,
				rate_name: string,
				ps_datepretty: string,
				ps_status: number,
				ps_statuspretty: string,
				payerp_custid: string,
				ps_payerid: string,
				ps_progid: number,
			}

			const clearPayerSync = () => {
				$('#psEditName').text('null')
				$('#psEditProg').text('null')
				$('#psEditDate').text('null')
				$('#psEditStatus').text(`Status: null`)
				$('#psEditCustomerID').val(" ")
			}

			const expandPayerSync = (payer: syncData) => {
				if (!payer) return displayNotification('Payer not found', 'danger')
				console.log('showing payer', payer)

				//show loading
				const $loading = $('#psEditLoading').show()
				const $content = $('#psEditContent').hide()

				//open canvas
				const offcanvasRight = $('#offCanvasRight') as any
				const sideMenu = new Offcanvas(offcanvasRight)
				sideMenu.show()

				//map data
				$('#psEditName').text(payer.payer_name)
				$('#psEditProg').text(payer.prog_name)
				$('#psEditDate').text(payer.ps_datepretty)
				$('#psEditStatus').text(`Status: ${payer.ps_statuspretty}`)
				$('#psEditCustomerID').val(payer.payerp_custid)

				if (payer.ps_status == SYNC_COMPLETE) {
					$('#psEditStatus').addClass('text-success').removeClass('text-danger')
					$('#psEditStatusIcon').removeClass(`fa-circle-xmark text-danger`).addClass(`fa-circle-check text-success`)
				} else {
					$('#psEditStatus').addClass('text-danger').removeClass('text-success')
					$('#psEditStatusIcon').removeClass(`fa-circle-check text-success`).addClass(`fa-circle-xmark text-danger`)
				}

				//show content
				$loading.hide()
				$content.show()

				//look at this later
				$('#closePayerSync').off('click').on('click', () => clearPayerSync())

				$('#psEditSave').off('click').on('click', async () => {
					const $spinner = $('#psEditSaveSpinner').show()
					const postData = {
						sync_id: payer.ps_id,
						payer_id: payer.ps_payerid,
						prog_id: payer.ps_progid,
						cust_id: $('#psEditCustomerID').val(),
					}
					try {
						const res = await fetchPost('/support/submit', { type: 'sync_update_payer', data: postData })
						if (res.rc != "OK") throw res.rc

						displayNotification('Payer Successfully Updated', 'success')
						reloadSync()
					} catch (error) {
						console.log(error)
						displayNotification('Error updating payer', 'danger')
					} finally {
						$spinner.hide()
						sideMenu.hide() // close edit menu
					}
				})
			}

			const expandRateSync = (rate: syncData) => {
				//show loading
				const $loading = $('#rsEditLoading').show()
				const $content = $('#rsEditContent').hide()

				//open canvas
				const offcanvasRight = $('#expandRateSync') as any
				const sideMenu = new Offcanvas(offcanvasRight)
				sideMenu.show()

				//map data
				$('#rsEditName').text(rate.rate_name)
				$('#rsEditProg').text(rate.prog_name)
				$('#rsEditDate').text(rate.ps_datepretty)
				$('#rsEditStatus').text(`Status: ${rate.ps_statuspretty}`)
				if (rate.ps_status == SYNC_COMPLETE) {
					$('#rsEditStatus').addClass('text-success').removeClass('text-danger')
					$('#rsEditStatusIcon').removeClass(`fa-circle-xmark text-danger`).addClass(`fa-circle-check text-success`)
					$('#rsEditFailHelp').toggle(false)
				} else {
					$('#rsEditStatus').addClass('text-danger').removeClass('text-success')
					$('#rsEditStatusIcon').removeClass(`fa-circle-check text-success`).addClass(`fa-circle-xmark text-danger`)
					$('#rsEditFailHelp').toggle(true) //show help if we failed connection
				}

				//show content
				$loading.hide()
				$content.show()
			}

			const handleDropZone = () => {
				console.log('handleDropZone')
				// File input box hidden, shown when label clicked
				$("label[for='customFile']").click(function () {
					$("#customFile").trigger('click');
				})

				// Files selected in file input box
				// $("#customFile").change(function () {
				// 	$.each(this.files as any, function (i, file) {
				// 		console.log(file.name)
				// 	})
				// })

				// File drop zone
				const dropZone = $('.dropZone');
				['dragenter', 'dragover', 'dragleave', 'drop'].forEach(function (eventName) {
					dropZone.on(eventName, preventDefaults)
				})

				const preventDefaults = (e) => {
					e.preventDefault()
					e.stopPropagation()
				}

				// Handle dropped files
				dropZone.on('drop', handleDrop);
				function handleDrop(e) {
					var dt = e.originalEvent.dataTransfer
					var files = dt.files
						([...files]).forEach(uploadFile)
				}

				const uploadFile = (file) => {
					console.log(file)
				}
			}

			handleDropZone()
		}

		initSync()
	}
	if ($('#quickbook-tab-pane').length) qbOverview()

	const initComparisons = async () => {
		type payerId = number | null
		type visibility = 'show' | 'hide'

		// need to start on the hidden input stuff here
		const payerId: payerId = $('#psHiddenPayer').val() != null ? Number($('#psHiddenPayer').val()) : null
		const progId: number | null = $('#psHiddenProg').val() != null ? Number($('#psHiddenProg').val()) : null
		if (payerId != null) $('#payerSyncMainSelect').val(payerId)

		$('body').on('change', '#payerSyncMainSelect', () => initPayerConnections(Number($('#payerSyncMainSelect').val()), Number($('#payerSyncProgSelect').val())))
		$('#payerSyncProgSelect').on('change', () => renderPayerSelect(Number($('#payerSyncProgSelect').val())))
		$('#payerSyncProgSelect').on('change', () => toggleFullSync(Number($('#payerSyncProgSelect').val())))

		$('#payerSyncUnlock').on('change', ({ target }) => {
			const action: visibility = $(target).prop('checked') ? 'hide' : 'show'
			toggleQuickbooks(action, 'payers')
		})

		$('#payerSyncAll').off('click').on('click', () => {
			const program = Number($('#payerSyncProgSelect').val())
			try {
				confirmDialog({
					dialogTitle: 'Sync All Payer Attributes',
					bodyText: '<p> Are you sure you want to sync all payer attributes? This will overwrite all customer data within the current quickbooks file with the current data saved in the KidsVax environment. </p>',
					confirmText: 'Sync',
					confirmStyle: 'primary',
					showCancelButton: false,
					confirmFunction: () => syncAllPayerAttributes(program)
				})
			} catch (err) {
				console.log('error syncing attributes', err)
			}
		})

		const syncAllPayerAttributes = async (program: number) => {
			showLoadingScreen()
			try {
				const res = await fetchPost('/support/submit', { type: 'sync_all_payer_attributes', program_id: program })
				if (res.rc != 'OK') throw res.rc

				displayNotification('Success syncing all payer attributes', 'success')
			} catch (error) {
				displayNotification('Error syncing all payer attributes', 'danger')
				console.log('error syncing all payer attributes', error)
			} finally {
				hideLoadingScreen()
			}
		}

		setCollapse({ $btn: $('#psHelpCollapse'), $target: $('#psHelp') })

		type toggleOptionsParams = {
			toggles: $[],
			content: {
				$container: $,
				$blankContainer: $,
			},
			error: {
				$text: $,
				$icon: $,
			},
			spinner: $,
		}

		//determine whether this program is connected for a full sync
		const toggleFullSync = async (program_id: number) => {
			try {
				const { rc, status } = await fetchPost('/support/submit', { type: 'sync_get_data', data: { prog_id: program_id } })
				if (rc != 'OK') throw rc

				const isConnected = Number(status) === QB_CONNECT_ACTIVE
				$('#payerSyncAll').prop('disabled', !isConnected)
			} catch (error) {
				console.log(error)
				$('#payerSyncAll').prop('disabled', true)
			}
		}

		const toggleContent = (params: toggleOptionsParams, action: visibility, isError: boolean = false, isLoading: boolean = false) => {
			const isShowAction = action === 'show'
			const toggles = params.toggles

			if (isLoading) {
				params.spinner.show()
				params.content.$container.hide()
				params.content.$blankContainer.hide()
			} else {
				//toggle error/no-result screen and content
				params.spinner.hide()
				params.content.$container.toggle(isShowAction)
				params.content.$blankContainer.toggle(!isShowAction)

				//toggle switch disable
				toggleSwitch(toggles, action)
				if (isError) {
					params.error.$text.text('Error Occured...')
					params.error.$icon.removeClass('fa-magnifying-glass-minus').addClass('fa-triangle-exclamation text-danger')
				} else {
					params.error.$text.text('No Results Found...')
					params.error.$icon.removeClass('fa-magnifying-glass-minus').addClass('fa-triangle-exclamation text-danger')
				}
			}
		}

		//toggle switches between disabled / enabled
		const toggleSwitch = (element: $ | $[], action: visibility) => {
			if (Array.isArray(element)) return element.forEach((el) => toggleSwitch(el, action))

			const isDisabled: boolean = action === 'show' ? false : true
			$(element).prop('disabled', isDisabled)
		}

		//toggle quickbooks inputs between disabled / enabled
		const toggleQuickbooks = (action: visibility, type: 'payers' | 'rate') => {
			const isDisabled: boolean = action === 'hide' ? false : true
			let className = ''
			type === 'payers' ? className = '.qbField' : className = '.qbRateField'
			$(className).each((_, element): any => $(element).prop('disabled', isDisabled))
		}

		const renderPayerSelect = async (progId: number | null = null) => {
			const loadingParams: loadingParams = {
				loading: $('#payerSyncFilterLoading'),
				content: $('#payerSyncFilters')
			}
			toggleLoading(loadingParams, 'show')

			try {
				type payer = {
					payer_id: number,
					payer_name: string,
					payerp_programid: number,
				}

				type program = {
					prog_id: number,
					prog_name: string,
				}

				type ResponseType = {
					programs: program[],
					payers: payer[],
				}

				$('#payerSyncMainSelect').prop('disabled', true).trigger('chosen:updated')
				let { programs, payers }: ResponseType = await fetchPost('/support/submit', { type: 'get_payer_filter', data: progId })

				let $payerFilter: any
				if (payers && payers.length > 0) {
					$payerFilter = $(
						<div>
							<label>Payers</label>
							<select id='payerSyncMainSelect' className="form-control chosen">
								<option value="" selected>No filter</option>
								{programs.map((program: program) => (
									<optgroup key={program.prog_id} label={program.prog_name}>
										{payers
											.filter((payer: payer) => payer.payerp_programid === program.prog_id)
											.map((payer: payer) => (
												<option key={payer.payer_id} value={payer.payer_id}>
													{`${payer.payer_name}`}
												</option>
											))}
									</optgroup>
								))}
							</select>
						</div>
					)
				}
				$('#payerSyncProgSelectContainer').html($payerFilter as any).trigger('chosen:updated')
				$('#payerSyncMainSelect').chosen()

			} catch (error) {
				console.error(error)
			} finally {
				toggleLoading(loadingParams, 'hide')
			}
		}

		const showScreen = (screen: string) => {
			const toggles = [$('#payerSyncShowConnections'), $('#payerSyncUnlock')]
			switch (screen) {
				case 'NO_RESULTS':
					toggleSwitch(toggles, 'hide')
					$('#payerSyncContentContainer').hide()
					$('#leadingLineBlankContainer').show()
					$('#payerSyncMainSpinner').hide()
					$('#leadingLineError').text('No Results Found...')
					break;
				case 'RESULTS':
					toggleSwitch(toggles, 'show')
					$('#payerSyncContentContainer').show()
					$('#leadingLineBlankContainer').hide()
					$('#payerSyncMainSpinner').hide()
					break;
				case 'SPINNER':
					toggleSwitch(toggles, 'show')
					$('#payerSyncContentContainer').hide()
					$('#leadingLineBlankContainer').hide()
					$('#payerSyncMainSpinner').show()
					break;
				case 'ERROR':
					showError(toggles)
					break;
				default:
					showError(toggles)
			}
		}

		const showError = (toggles) => {
			toggleSwitch(toggles, 'hide')
			$('#payerSyncContentContainer').hide()
			$('#leadingLineBlankContainer').show()
			$('#payerSyncMainSpinner').hide()
			$('#leadingLineError').text('Error Occured...')
		}

		const clearPayerConnections = () => {
			$('#payerOrigName').val('')
			$('#payerOrigFein').val('')
			$('#payerOrigAddress1').val('')
			$('#payerOrigAddress2').val('')
			$('#payerOrigCity').val('')
			$('#payerOrigState').val('')
			$('#payerOrigZip').val('')
			$('#payerOrigEmail').val('')
			$('#payerQbDisplayName').val('')
			$('#payerQbFirstName').val('')
			$('#payerQbMiddleName').val('')
			$('#payerQbLastName').val('')
			$('#payerQbNumber').val('')
			$('#payerQbCity').val('')
			$('#payerQbState').val('')
			$('#payerQbZip').val('')
			$('#payerQbBillAddress').val('')
			$('#payerQbShipAddress').val('')
			$('#payerQbEmail').val('')
		}

		const initPayerConnections = async (payerId: payerId, program_id: number) => {
			clearPayerConnections()
			showScreen('SPINNER')
			try {
				if (payerId == null || payerId == 0) return showScreen('NO_RESULTS')

				const res = await fetchPost('/support/submit', { type: 'get_payers', data: { payer_id: payerId, program_id: program_id } })
				if (res.rc != 'OK') throw res.rc
				const data = res.data
				console.log('data', data)

				//show blank screen if we do not have data
				if (data == null || data.kv_payer == null) return showScreen('NO_RESULTS')

				//kv payer - internal info 
				$('#payerOrigName').val(data.kv_payer.payer_name)
				$('#payerOrigFein').val(data.kv_payer.payer_fein)
				$('#payerOrigAddress1').val(data.kv_payer.payer_address1)
				$('#payerOrigAddress2').val(data.kv_payer.payer_address2)
				$('#payerOrigCity').val(data.kv_payer.payer_city)
				$('#payerOrigState').val(data.kv_payer.payer_state)
				$('#payerOrigZip').val(data.kv_payer.payer_zip)
				$('#payerOrigEmail').val(data.kv_payer.payer_email)

				//qb payer - external info	
				if (data.qb_payer != null) {
					$('#payerQbDisplayName').val(data.qb_payer.display_name)
					$('#payerQbFirstName').val(data.qb_payer.first_name)
					$('#payerQbMiddleName').val(data.qb_payer.middle_name)
					$('#payerQbLastName').val(data.qb_payer.last_name)
					$('#payerQbNumber').val(data.qb_payer.phone)
					$('#payerQbCity').val(data.qb_payer.city)
					$('#payerQbState').val(data.qb_payer.state)
					$('#payerQbZip').val(data.qb_payer.zip)
					$('#payerQbBillAddress').val(data.qb_payer.address)
					$('#payerQbShipAddress').val(data.qb_payer.address)
					$('#payerQbEmail').val(data.qb_payer.email)
				}

				const linePairs = [
					{
						kv: '#payerOrigName',
						qb: '#payerQbDisplayName'
					},
					{
						kv: '#payerOrigName',
						qb: '#payerQbFirstName'
					},
					{
						kv: '#payerOrigName',
						qb: '#payerQbMiddleName'
					},
					{
						kv: '#payerOrigName',
						qb: '#payerQbLastName'
					},
					{
						kv: '#payerOrigFein',
						qb: '#payerQbFirstName'
					},
					{
						kv: '#payerOrigAddress1',
						qb: '#payerQbBillAddress'
					},
					{
						kv: '#payerOrigAddress1',
						qb: '#payerQbShipAddress'
					},
					{
						kv: '#payerOrigCity',
						qb: '#payerQbCity'
					},
					{
						kv: '#payerOrigState',
						qb: '#payerQbState'
					},
					{
						kv: '#payerOrigZip',
						qb: '#payerQbZip'
					},
					{
						kv: '#payerOrigEmail',
						qb: '#payerQbEmail'
					},
				]

				showScreen('RESULTS')
				const addFocusBlurEvents = (element, line) => {
					element.addEventListener('focus', function () {
						line.show('draw')
					})
					element.addEventListener('blur', function () {
						line.hide()
					})
				}

				let lines = [];
				linePairs.forEach((pair) => {
					try {
						const kvElement = document.querySelector(pair.kv)
						const qbElement = document.querySelector(pair.qb)

						// Check if the elements exist and are visible before attempting to draw lines
						if (kvElement && qbElement && $(kvElement).is(':visible') && $(qbElement).is(':visible')) {

							let color = 'orange'
							if ($(pair.kv).val() === $(pair.qb).val()) color = 'green'

							const lineSettings = { color: color, size: 2, hide: true }

							const line = new LeaderLine(
								kvElement,
								qbElement,
								{ ...lineSettings }
							)

							const oppositeLine = new LeaderLine(
								qbElement,
								kvElement,
								{ ...lineSettings }
							)

							line.position()
							lines.push(line)
							addFocusBlurEvents(qbElement, line)
							addFocusBlurEvents(kvElement, oppositeLine) //maybe change this, have to test

							// Update the line position on window resize
							$(window).on('resize', () => {
								line.position()
								oppositeLine.position()
							})

							// Add scroll event listener to the container
							const $body = document.querySelector('body')
							$body.addEventListener('scroll', AnimEvent.add(() => {
								line.position()
								oppositeLine.position()
							}), false)

						} else {
							console.log(`Element(s) not found or not visible: ${pair.kv}, ${pair.qb}`)
						}

					} catch (err) {
						console.log('error drawing lines', err)
						return;
					}
				})

				$('#payerSyncShowConnections').on('change', function () {
					if ($('#payerSyncContentContainer').is(':hidden')) { //check if the inputs are visible
						displayNotification('Please Select a Payer To View Connections', 'danger')
						return
					}
					$(this).is(":checked") ? lines.forEach(line => line.show("draw")) : lines.forEach(line => line.hide())
				})

				//save kidsvax data
				$('#payerSyncKvData').off('click').on('click', async () => {
					showLoadingScreen()
					const postData = {
						payer_id: payerId,
						program_id: program_id,
						payer_name: $('#payerOrigName').val(),
						payer_fein: $('#payerOrigFein').val(),
						payer_address1: $('#payerOrigAddress1').val(),
						payer_address2: $('#payerOrigAddress2').val(),
						payer_city: $('#payerOrigCity').val(),
						payer_state: $('#payerOrigState').val(),
						payer_zip: $('#payerOrigZip').val(),
						admin_email: $('#payerOrigEmail').val(),
					}
					try {
						const res = await fetchPost('/support/submit', { type: 'sync_save_kvpayer', data: postData })
						if (res.rc !== "OK") throw res.rc

						displayNotification('KidsVax Data Saved Successfully', 'success')
					} catch (error) {
						console.log(error)
						displayNotification('Error Saving KidsVax Data', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				//save quickbooks data
				$('#payerSyncQbData').off('click').on('click', async () => {
					showLoadingScreen()
					const postData = {
						payer_id: payerId,
						program_id: program_id,
						payer_name: $('#payerQbDisplayName').val(),
						payer_firstname: $('#payerQbFirstName').val(),
						payer_middlename: $('#payerQbMiddleName').val(),
						payer_lastname: $('#payerQbLastName').val(),
						payer_phone: $('#payerQbNumber').val(),
						payer_billaddress: $('#payerQbBillAddress').val(),
						payer_shipaddress: $('#payerQbShipAddress').val(),
						payer_city: $('#payerQbCity').val(),
						payer_state: $('#payerQbState').val(),
						payer_zip: $('#payerQbZip').val(),
						payer_email: $('#payerQbEmail').val(),
					}
					try {
						const res = await fetchPost('/support/submit', { type: 'sync_save_qbpayer', data: postData })
						if (res.rc !== "OK") throw res.rc

						displayNotification('QuickBooks Data Saved Successfully', 'success')
					} catch (error) {
						console.log(error)
						displayNotification('Error Saving Quickbooks Data', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				//sync kidvax to quickbooks
				$('#payerSyncAllData').off('click').on('click', async () => {
					const postData = {
						payer_id: payerId,
						program_id: program_id,
						payer_name: $('#payerOrigName').val(),
						payer_fein: $('#payerOrigFein').val(),
						payer_address1: $('#payerOrigAddress1').val(),
						payer_address2: $('#payerOrigAddress2').val(),
						payer_city: $('#payerOrigCity').val(),
						payer_state: $('#payerOrigState').val(),
						payer_zip: $('#payerOrigZip').val(),
						admin_email: $('#payerOrigEmail').val(),
					}

					showLoadingScreen()
					try {
						const res = await fetchPost('/support/submit', { type: 'sync_payer_attributes', data: postData })
						if (res.rc !== "OK") throw res.rc

						updateQbPayer(res.payer)
						displayNotification('All Atrributes Synced Successfully', 'success')
					} catch (error) {
						console.log(error)
						displayNotification('Error Syncing Attributes', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				const updateQbPayer = (data) => {
					$('#payerQbDisplayName').val(data.display_name)
					$('#payerQbFirstName').val(data.first_name)
					$('#payerQbMiddleName').val(data.middle_name)
					$('#payerQbLastName').val(data.last_name)
					$('#payerQbBillAddress').val(data.address)
					$('#payerQbShipAddress').val(data.address)
					$('#payerQbCity').val(data.city)
					$('#payerQbState').val(data.state)
					$('#payerQbZip').val(data.zip)
					$('#payerQbEmail').val(data.email)
				}

			} catch (err) {
				console.log(err)
				displayNotification('Error Getting Payer Info', 'danger')
				showScreen('ERROR')
			}
		}

		initPayerConnections(payerId, progId)
		renderPayerSelect(Number($('#payerSyncProgSelect').val()))

		//RATES
		const rateParams: renderRateParams = { //rate bindings
			progId: Number($('#psHiddenProg').val()),
			rateId: Number($('#psHiddenPayer').val()),
		}

		$('body').on('change', '#rateCompFilterRate', () => initRateConnections(Number($('#rateCompFilterRate').val()), Number($('#rateCompFilterProg').val())))
		$('#rateCompFilterProg').on('change', () => renderRateSelect(Number($('#rateCompFilterProg').val()))) //render rate filter on program change

		type renderRateParams = {
			progId: number,
			rateId: number,
		}

		const renderRateCosts = async (data: renderRateParams = null) => {
			try {
				const { ages, costs } = await fetchPost('/support/submit', { type: 'get_rate_info', data: { prog_id: data.progId, rate_id: data.rateId } })

				$('#rateCompAgeRow').empty()
				const costsForm = ages.map(({ age_id, age_name }) => {
					const $group = getTemplates().find('#rateCompAmountGroup').clone()
					const $label = $group.find('#rateCompAmountLabel')
					const $input = $group.find('#rateCompAmount')
					const htmlId = `#rateCompAmount${age_id}`
					$group.prop('id', `#rateCompAmountGroup${age_id}`)
					$label.removeProp('id').prop('for', htmlId).text(age_name)
					$input.prop('id', htmlId)
					inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
					$('#rateCompAgeRow').append($group)

					return {
						$: $input,
						source: age_id,
					}
				})

				const costParsed = {}
				Object.entries(costs).forEach(([id, amount]) => costParsed[id] = amount as number / 100)
				setForm(costsForm, costParsed)

			} catch (err) {
				console.log(err)
			} finally {
				console.log('finished')
			}
		}

		const renderRateSelect = async (progId = null) => {
			const loadingParams: loadingParams = {
				loading: $('#rateCompFilterLoading'),
				content: $('#rateCompFilters')
			}
			toggleLoading(loadingParams, 'show')

			type rate = {
				rate_id: number;
				rate_progid: number;
				rate_name: string;
			}

			type program = {
				prog_id: number;
				prog_name: string;
			}

			try {
				$('#rateCompFilterRate').prop('disabled', true)
				let { programs, rates } = await fetchPost('/support/submit', { type: 'get_filter_rates', data: progId })

				let $ratesSelect: any
				if (rates && rates.length > 0) {
					$ratesSelect = $(
						<div>
							<label>Rate</label>
							<select id='rateCompFilterRate' className="form-control chosen">
								<option value="" selected>No filter</option>
								{programs.map((program: program) => (
									<optgroup key={program.prog_id} label={program.prog_name}>
										{rates
											.filter((rates: rate) => rates.rate_progid === program.prog_id)
											.map((rates: rate) => (
												<option key={rates.rate_id} value={rates.rate_id}>
													{rates.rate_name}
												</option>
											))}
									</optgroup>
								))}
							</select>
						</div>
					)
				}
				$('#rateCompFilterRateContainer').html($ratesSelect as any)
				$('#rateCompFilterRate').chosen()
			} catch (error) {
				console.error(error)
			} finally {
				$('#rateCompFilterRate').prop('disabled', false)
				toggleLoading(loadingParams, 'hide')
			}
		}
		renderRateSelect(Number($('#rateCompFilterProg').val()))
		// renderRateCosts()

		const showSpinner = (toggles: Array<$>) => {
			console.log('show spinner')
			toggleSwitch(toggles, 'hide')
			$('#rateCompMainSpinner').show()
			$('#rateCompContent').hide()
			$('#rateCompErrorContainer').hide()
		}

		//error screen
		const showRateError = (toggles: Array<$>, msg: string | null = null) => {
			console.log('show rate error')
			msg != null ? msg = msg : msg = 'Error Occured...'
			toggleSwitch(toggles, 'hide')
			$('#rateCompMainSpinner').hide()
			$('#rateCompContent').hide()
			$('#rateCompErrorContainer').show()
			$('#rateCompErrorText').text(msg)
		}

		//no results found
		const showNoResults = (toggles: Array<$>, msg: string | null = null) => {
			console.log('show no results')
			showRateError(toggles)
			$('#rateCompErrorText').text(msg)
		}

		//show results container
		const showResults = (toggles: Array<$>) => {
			toggleSwitch(toggles, 'show')

			$('#rateCompMainSpinner').hide()
			$('#rateCompContent').show()
			$('#rateCompErrorContainer').hide()
		}

		$('#rateSyncAll').off('click').on('click', () => {
			const program = Number($('#rateCompFilterProg').val())
			try {
				confirmDialog({
					dialogTitle: 'Sync All rate Attributes',
					bodyText: '<p> Are you sure you want to sync all rate attributes? This will overwrite all customer data within the current quickbooks file with the current data saved in the KidsVax environment. </p>',
					confirmText: 'Sync',
					confirmStyle: 'primary',
					showCancelButton: false,
					confirmFunction: () => syncAllRateAttributes(program)
				})
			} catch (err) {
				console.log('error syncing attributes', err)
			}
		})

		const syncAllRateAttributes = async (program: number) => {
			showLoadingScreen()
			try {
				const res = await fetchPost('/support/submit', { type: 'sync_all_rate_attributes', data: program })
				if (res.rc != 'OK') throw res.rc

				displayNotification('Success syncing all rate attributes', 'success')
			} catch (error) {
				displayNotification('Error syncing all rate attributes', 'danger')
				console.log('error syncing all rate attributes', error)
			} finally {
				hideLoadingScreen()
			}
		}

		const initRateConnections = async (rateId: number, program_id: number) => {
			const rateToggles = [$('#rateCompShowConnections'), $('#rateCompUnlock')]
			showSpinner(rateToggles)

			try {
				if (rateId == null || rateId == 0) showNoResults(rateToggles, 'No Rate Found...')

				const res = await fetchPost('/support/submit', { type: 'get_rates', data: { rate_id: rateId, program_id: program_id } })
				if (res.rc != 'OK') throw res.rc
				const data = res.data
				const is_condensing = res.condense
				const operation_fee = res.operation_fee

				//show blank screen if we do not have data
				if (data == null || data.kv_rate == null) {
					console.log('hitting here')
					return showNoResults(rateToggles, 'No Data Found...')
				}

				//kv rate - internal info 
				$('#rateOrigName').val(data.kv_rate.rate_name)
				$('#rateOrigPeriod').val(data.kv_rate.rate_period)

				$('#rateKvName').val(data.kv_rate.rate_period)
				$('#rateKvDescription').val(data.kv_rate.rate_name)

				//we only have a single age
				if (Object.keys(data.costs).length == 1 || is_condensing) {
					$('#rateOrigCost').val(Number(Object.entries(data.costs)[0][1]) / 100)
				}

				type CustomInput = {
					$: $,
					source: string,
				}
				type CustomForm = CustomInput[]
				let qbForm: CustomForm | null = null; let kvForm: CustomForm | null = null

				//qb rate - external info
				const isMultiple = !is_condensing && Object.keys(data.costs).length > 1 ? true : false
				if (!isMultiple) {
					$('#rateQBSingle').show()
					$('#rateKvSingle').show()
					$('#rateQbMultiContainer').hide()
					$('#rateKvMultiContainer').hide()

					$('#rateQbNameSingle').val(data.qb_rate.display_name)
					$('#rateQbDescriptionSingle').val(data.qb_rate.description)
					$('#rateQbPrice').val(data.qb_rate.cost)

				} else {
					$('#rateQBSingle').hide()
					$('#rateKvSingle').hide()
					$('#rateQbMultiContainer').show()
					$('#rateKvMultiContainer').show()

					//make sure names are consistent for each of the services
					const names = {
						name: null,
						description: null
					}

					for (const [index, rate] of data.qb_rate.entries()) {
						let startIndx = operation_fee ? 1 : 0;
						let endIndx = operation_fee ? 3 : 2;

						if (index === 0) {
							names.name = rate.display_name.substring(startIndx, endIndx)
							names.description = rate.description.substring(0, rate.description.lastIndexOf(' -')).trim()
							if (names.description === '') names.description = rate.description
						} else {
							//if all names are not equal set to null
							if (rate.display_name.substring(startIndx, endIndx) !== names.name) {
								names.name = null
							}
						}
					}

					highlightInput('#rateQbName', names.name == null || names.name == '')
					highlightInput('#rateQbDescription', names.description == null || names.name == '')

					$('#rateQbName').val(names.name)
					$('#rateQbDescription').val(names.description)

					//we have multiple ages
					$('#rateQbAgeRow').empty()
					qbForm = data.qb_rate.map((rate, index) => {
						const $group = getTemplates().find('#rateQbAgeTemplate').clone()
						const $label = $group.find('#rateQbAgeLabel')
						const $input = $group.find('#rateQbAgeAmount')
						const htmlId = `rateQbAgeAmount${rate.age_id}` // Remove the # from the beginning
						$group.prop('id', htmlId) // Set the unique ID for the group
						$label.removeProp('id').prop('for', htmlId).text(`${rate.age_name} - Cost (${rate.display_name})`)
						$input.prop('id', htmlId)
						$input.val(rate.cost)
						inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
						$('#rateQbAgeRow').append($group) // Append the cloned group to the row

						return {
							$: $input,
							source: rate.age_id,
						}
					})

					$('#rateKvAgeRow').empty()
					kvForm = data.qb_rate.map((rate) => {
						const $group = getTemplates().find('#rateKvAgeTemplate').clone()
						const $label = $group.find('#rateKvAgeLabel')
						const $input = $group.find('#rateKvAgeAmount')
						const htmlId = `rateKvAgeAmount${rate.age_id}` // Remove the # from the beginning
						$group.prop('id', htmlId) // Set the unique ID for the group
						$label.removeProp('id').prop('for', htmlId).text(`${rate.age_name} - cost`)
						$input.prop('id', htmlId)
						$input.val(rate.cost)
						inputNum({ $input, min: 0, step: 0.01, decimals: 2 })
						$('#rateKvAgeRow').append($group) // Append the cloned group to the row				
						return {
							$: $input,
							source: rate.age_id,
						}
					})
				}

				$('#rateSyncShowConnections').on('change', function () {
					if ($('#rateSyncContentContainer').is(':hidden')) { //check if the inputs are visible
						displayNotification('Please Select a Rate To View Connections', 'danger')
						return
					}
					$(this).is(":checked") ? lines.forEach(line => line.show("draw")) : lines.forEach(line => line.hide())
				})

				//save kv data
				$('#rateCompSaveKvData').off('click').on('click', async () => {
					try {
						showLoadingScreen()
						const postData = {
							is_multiple: isMultiple,
							rate_id: rateId,
							program_id: program_id,
							rate_name: $('#rateOrigName').val(),
							rate_period: $('#rateOrigPeriod').val(),
							rate_cost: $('#rateOrigCost').val(),
						}

						if (isMultiple) postData['costs'] = getForm(kvForm)

						const res = await fetchPost('/support/submit', { type: 'sync_save_kvrate', data: postData })
						if (res.rc !== "OK") throw res.rc

						displayNotification('Success Saving KidsVax Data', 'success')
					} catch (error) {
						console.log(error)
						displayNotification('Error Saving KidsVax Data', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				//save quickbooks data
				$('#rateCompSaveQbData').off('click').on('click', async () => {
					try {
						showLoadingScreen()
						const postData = {
							is_multiple: isMultiple,
							rate_id: rateId,
							program_id: program_id,
							rate_name: $('#rateQbName').val(),
							rate_description: $('#rateQbDescription').val(),
							rate_cost: $('#rateQbPrice').val(),
						}

						if (isMultiple) postData['costs'] = getForm(qbForm)

						const res = await fetchPost('/support/submit', { type: 'sync_save_qbrate', data: postData })
						if (res.rc !== "OK") throw res.rc

						displayNotification('Success Savings Quickbooks Data', 'success')
					} catch (error) {
						console.log(error)
						displayNotification('Error Saving QuickBooks Data', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				//sync rate data from kv to qb
				$('#rateCompSyncAttr').off('click').on('click', async () => {
					showLoadingScreen()
					const postData = {
						is_multiple: isMultiple,
						rate_id: rateId,
						program_id: program_id,
						rate_name: $('#rateOrigName').val(),
						rate_period: $('#rateOrigPeriod').val(),
						rate_cost: $('#rateOrigCost').val(),
					}

					if (isMultiple) {
						postData['qb_costs'] = getForm(qbForm)
						postData['kv_costs'] = getForm(kvForm)
					}

					try {
						const res = await fetchPost('/support/submit', { type: 'sync_rate_attributes', data: postData })
						if (res.rc !== "OK") throw res.rc

						displayNotification('Success Syncing Attributes')
						updateQbRate(res.rate, isMultiple)
					} catch (error) {
						console.log(error)
						displayNotification('Error Syncing Attributes', 'danger')
					} finally {
						hideLoadingScreen()
					}
				})

				const updateQbRate = (rate, isMultiple) => {
					if (isMultiple) {
						$('#rateQbName').val(rate.display_name)
						$('#rateQbDescription').val(rate.description)
						setForm(qbForm, rate.costs)
					} else {
						$('#rateQbNameSingle').val(rate.display_name)
						$('#rateQbDescriptionSingle').val(rate.description)
						$('#rateQbPrice').val(rate.cost)
					}
				}
				showResults(rateToggles)

				let linePairs = [
					{
						kv: '#rateOrigPeriod',
						qb: '#rateQbNameSingle'
					},
					{
						kv: '#rateOrigName',
						qb: '#rateQbDescriptionSingle'
					},
					{
						kv: '#rateOrigCost',
						qb: '#rateQbPrice'
					},
				]

				if (isMultiple && !is_condensing) {
					linePairs = [
						{
							kv: '#rateKvName',
							qb: '#rateQbName'
						},
						{
							kv: '#rateKvDescription',
							qb: '#rateQbDescription'
						},
						// ...data.qb_rate.map((rate) => {
						// 	return {
						// 		kv: `#rateKvAgeAmount${rate.age_id}`,
						// 		qb: `#rateQbAgeAmount${rate.age_id}`
						// 	}
						// })
					]
				}

				console.log('linepairs', linePairs)
				const addFocusBlurEvents = (element, line) => {
					element.addEventListener('focus', function () {
						line.show('draw')
					})
					element.addEventListener('blur', function () {
						line.hide()
					})
				}

				let lines = [];
				linePairs.forEach((pair) => {
					try {
						const kvElement = document.querySelector(pair.kv)
						const qbElement = document.querySelector(pair.qb)

						setTimeout(() => {
							if (kvElement && qbElement && $(kvElement).is(':visible') && $(qbElement).is(':visible')) {
								try {
									let color = 'orange'
									if ($(pair.kv).val() === $(pair.qb).val()) color = 'green'

									const lineSettings = { color: color, size: 2, hide: true }
									const line = new LeaderLine(
										kvElement,
										qbElement,
										{ ...lineSettings }
									)

									const oppositeLine = new LeaderLine(
										qbElement,
										kvElement,
										{ ...lineSettings }
									)

									line.position()
									lines.push(line)
									addFocusBlurEvents(qbElement, line)
									addFocusBlurEvents(kvElement, line)

									// Update the line position on window resize
									$(window).on('resize', () => {
										line.position()
										oppositeLine.position()
									})

									// Add scroll event listener to the container
									const $body = document.querySelector('body')
									$body.addEventListener('scroll', AnimEvent.add(() => {
										line.position()
										oppositeLine.position()
									}), false)
								} catch (error) {
									console.log('error here', error)
								}
							} else {
								console.log(`Element(s) not found or not visible: ${pair.kv}, ${pair.qb}`)
							}
						}, 1000);

					} catch (err) {
						console.log('error drawing lines', err)
						return;
					}
				})


				//toggle quickbooks inputs
				$('#rateCompUnlock').on('change', ({ target }) => {
					const action: visibility = $(target).prop('checked') ? 'hide' : 'show'
					toggleQuickbooks(action, 'rate')
				})

				//toggle quickbooks inputs
				$('#rateCompShowConnections').on('change', ({ target }) => {
					if ($('#rateCompContent').is(':hidden')) { //check if the inputs are visible
						return displayNotification('Please select a rate to view comparisons', 'danger')
					}
					console.log('these are the lines', lines)
					$(target).is(":checked") ? lines.forEach(line => line.show("draw")) : lines.forEach(line => line.hide())
				})

			} catch (err) {
				showRateError(rateToggles)
				console.error('rate comparisons', err)
			}
		}

		initRateConnections(Number($('#rateCompFilterRate').val()), Number($('#rateCompFilterProg').val()))
	}
	if ($('#compare-tab-pane').length) initComparisons()

	const initQbSettings = async () => {

		$('#qbSetGeneral').off('change').on('change', ({ target }) => {
			const isEnabled = $(target).is(':checked')
			$('#qbSetFiling, #qbSetPayer').prop('disabled', !isEnabled)
		})

		//save finance settings
		$('#qbSetSave').off('click').on('click', async () => {
			const $spinner = $('#qbSettingsSpinner').show()
			try {
				const postData = {
					general: $('#qbSetGeneral').is(':checked') ? 1 : 0,
					filings: $('#qbSetFiling').is(':checked') ? 1 : 0,
					payer: $('#qbSetPayer').is(':checked') ? 1 : 0,
					rate: $('#qbSetRate').is(':checked') ? 1 : 0,
					time: $('#qbSetFilingTime').val(),
					notifications: $('#qbSetUserNotifs').val()
				}
				const res = await fetchPost('/support/submit', { type: 'finance_setting_save', data: postData })
				if (res.rc != 'OK') return displayNotification('Error Saving Settings', 'danger')
				displayNotification('Settings Saved', 'success')
			} catch (e) {
				displayNotification('Error Saving Settings', 'danger')
				console.log('error saving settings', e)
			} finally {
				$spinner.hide()
			}
		})
	}
	if ($('#settings-tab-pane').length) initQbSettings()
}

$(() => {
	$('[data-bs-toggle="tooltip"]').tooltip()
	$('.chosen').chosen({ disable_search_threshold: 10 })

	$(".nav-link").on("click", () => {
		setTimeout(() => {
			$(window).trigger('resize')
		}, 200)
	})

	initRegistration()
	initTest()
	getTemplates()
	initOnboard()
	initReporting()
	init_about()
	initSupport()
	initCalEdit()
	initDocuments()
	initUsers()
	initContent()
	initAccount()
	initUserSupport()
	initImpersonate()

	$(window).scroll(function () {
		var scroll = $(window).scrollTop();
		$(".custom-header").css({
			'top': scroll
		})
	})

	if ($('#statsContainer').length) initOnboardStats()
	if ($('#sign-in-container')) init_login()
	if ($('#mfa_form').length == 1) initMfa()
	if ($('#hist_container')) init_history()
	if ($('#payer_edit_container').length) initPayerAssessment()
	if ($('#newuser_container').length) newUserInit()
	if ($('#payerSelectContainer')) initMultiplePayer()
	if ($('#FinanceContainer').length) initFinance()


	const forms = document.querySelectorAll('.needs-validation')
	Array.from(forms).forEach((form: HTMLFormElement) => {
		form.addEventListener('submit', event => {
			if (!form.checkValidity()) {
				event.preventDefault()
				event.stopPropagation()
			}

			form.classList.add('was-validated')
		}, false)
	})

	$('.print_modal').off('click').on('click', (event) => {
		const $btn = $(event.target)
		const $tab = $btn.closest('.tab-pane')

		let config = {
			title: 'Print Event',
			callback: function () {
				let $tabClone = $tab.clone()
				$tabClone.find('.print_modal').remove()
				return $tabClone
			}
		}
		print_this(config)
	})
})